<template>
  <v-row class="ma-1 mb-n8">
    <v-col cols="4">
      <v-checkbox
        v-model="filter.showCompleted"
        label="Erledigte Aufgaben anzeigen"
        @change="getTasks"
      />
    </v-col>
  </v-row>
  <div class="ma-4">
    <v-data-table
      :headers="headers"
      :items="tasks"
      :items-per-page="50"
      :sort-by="sortBy"
      class="elevation-1"
    >
      <template v-slot:item.is_completed="{ item }">
        <v-chip
          variant="elevated"
          :color="item.is_completed ? 'success' : 'error'"
          text-color="white"
          size="small"
        >
          {{ item.is_completed ? "Ja" : "Nein" }}
        </v-chip>
      </template>
      <template v-slot:item.listeners="{ item }">
        <v-chip
          variant="elevated"
          class="ma-1"
          v-for="listener in item.listeners"
          size="x-small"
          >{{ listener }}
        </v-chip>
      </template>
      <template v-slot:item.entity_object="{ item }">
        <template v-if="item.entity_object">
          <v-btn
            :to="item.entity_object.link"
            size="small"
            rounded="xl"
            :color="item.entity_object.color"
          >
            <v-icon>{{ item.entity_object.icon }}</v-icon>
            {{ item.entity_object.name }}
          </v-btn></template
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tasks: [],
      users: {},
      headers: [
        { title: "Erledigt", value: "is_completed", key: "is_completed" },
        { title: "Titel", value: "title", key: "title" },
        { title: "Beschreibung", value: "note", key: "note" },
        { title: "Betreffende", value: "listeners", key: "listeners" },
        { title: "Fall", value: "entity_object", key: "entity_object" },
        { title: "Erstellt am", value: "created_at", key: "real_date" },
        {
          title: "Erstellt von",
          value: "owner.full_name",
          key: "owner.full_name",
        },
        { title: "Fällig am", value: "due_date", key: "due_date" },
      ],
      sortBy: [
        {
          key: "real_date",
          order: "desc",
        },
      ],
      filter: {
        showCompleted: false,
        onlyOwn: true,
      },
    };
  },
  methods: {
    async checkRights() {
      var queryObject = {
        select: "*,public_user_roles!inner(*)",
        table: "public_user_role_matching",
        where: [
          { type: "eq", key: "user_id", value: this.$store.state.userInfo.id },
          {
            type: "is",
            key: "public_user_roles.can_see_all_tasks",
            value: true,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      if (response.data.length > 0) {
        this.filter.onlyOwn = false;
      }
    },
    async getUsers() {
      var users = (
        await this.$store.dispatch("getSupabaseEntity", {
          select: "id,full_name",
          table: "public_users",
        })
      ).data;
      users.forEach((item) => {
        this.users[item.id] = item.full_name;
      });
    },
    async getTasks() {
      // Fetch tasks from the API
      var queryObject = {
        select:
          "*,hotels(id,name),employees(id,name),recruiting_candidates(id,first_name,last_name),owner:public_users!tasks_owner_id_fkey(id,full_name),todo_users:public_users!tasks_todo_owner_id_fkey(id,full_name)",
        table: "tasks",
        where: [],
      };
      if (!this.filter.showCompleted) {
        queryObject.where.push({
          type: "is",
          key: "is_completed",
          value: false,
        });
      }
      if (this.filter.onlyOwn) {
        queryObject.where.push({
          type: "or",
          value: `owner_id.eq.${this.$store.state.userInfo.id},listeners.cs.{${this.$store.state.userInfo.id}}`,
        });
      }
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((item) => {
        item.real_date = new Date(item.created_at).getTime();
        item.created_at = new Date(item.created_at)
          .toLocaleString()
          .split(",")[0];
        item.due_date = item.due_date
          ? new Date(item.due_date).toLocaleString().split(",")[0]
          : null;

        item.listeners = item.listeners
          ? item.listeners.map((listener) => this.users[listener])
          : null;
        if (item.hotel_id) {
          item.entity_object = {
            link: "/Hotels/" + item.hotel_id,
            name: item.hotels.name,
            icon: "mdi-hotel",
            color: "indigo",
          };
        }
        if (item.candidate_id) {
          item.entity_object = {
            link: "/Kandidaten/" + item.candidate_id,
            name:
              item.recruiting_candidates.first_name +
              " " +
              item.recruiting_candidates.last_name,
            icon: "mdi-account-search",
            color: "red-darken-4",
          };
        }
        if (item.employee_id) {
          item.entity_object = {
            link: "/Mitarbeiter/" + item.employee_id,
            name: item.employees.name,
            icon: "mdi-account-group",
            color: "teal",
          };
        }
      });
      this.tasks = response.data;
    },
  },
  async mounted() {
    await this.checkRights();
    await this.getUsers();
    this.getTasks();
  },
};
</script>

<style>
/* Add any custom styles for your table here */
</style>
