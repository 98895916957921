<template>
  <div>
    <v-btn
      @click="showPreview = !showPreview"
      :color="showPreview ? 'success' : null"
      >Vorschau</v-btn
    >
    <v-row>
      <v-col cols="12" v-if="!showPreview">
        <v-col cols="4" class="mt-2">
          <v-text-field
            variant="outlined"
            v-model="employeeCheck.min_correct_questions"
            prefix="Mindestens"
            suffix="Punkte zum bestehen"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            class="ma-4"
            @click="
              employeeCheck.content.push({
                title: null,
                category: null,
                is_failed: false,
              });
              createPreview();
            "
            >Add</v-btn
          >
          <v-btn @click="saveEmployeeCheck">Speichern</v-btn>
        </v-col>

        <v-row v-for="(item, index) in employeeCheck.content" class="mb-n8">
          <v-col cols="6">
            <v-text-field
              variant="outlined"
              v-model="item.title"
              label="Titel"
              @input="createPreview()"
              append-icon="mdi-delete"
              @click:append="deleteItem(index)"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              v-model="item.category"
              label="Kategorie"
              @input="createPreview()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="item.is_failed"
              label="Elementarer Fehler"
              @input="createPreview()"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-4" v-if="showPreview">
        <v-row v-for="(item, index) in preview" :key="index">
          <v-col cols="12">
            <v-card elevation="3">
              <v-card-title>{{ item.category }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="8">Titel</v-col>
                  <v-col cols="2" align="center">Erfüllt</v-col>
                  <v-col cols="2" align="center">Nicht erfüllt</v-col>
                </v-row>
                <v-row
                  v-for="(subItem, subIndex) in item.items"
                  :key="subIndex"
                >
                  <v-col cols="8">{{ subItem.title }}</v-col>
                  <v-col cols="2" align="center">
                    <v-icon
                      @click="subItem.check = true"
                      :icon="
                        subItem.check == true
                          ? 'mdi-checkbox-outline'
                          : 'mdi-checkbox-blank-outline'
                      "
                    ></v-icon>
                  </v-col>
                  <v-col cols="2" align="center">
                    <v-icon
                      @click="subItem.check = false"
                      :icon="
                        subItem.check == false
                          ? 'mdi-checkbox-outline'
                          : 'mdi-checkbox-blank-outline'
                      "
                    ></v-icon>
                    <v-icon v-if="subItem.is_failed" color="red"
                      >mdi-alert-circle</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script setup></script>

<script>
export default {
  data() {
    return {
      employeeCheck: {},
      preview: [],
      showPreview: false,
    };
  },
  methods: {
    async saveEmployeeCheck() {
      var response = await this.$store.dispatch("updateSupabaseEntity", {
        table: "employee_check_form",
        id: this.employeeCheck.id,
        payload: this.employeeCheck,
      });
    },
    async deleteItem(index) {
      this.employeeCheck.content.splice(index, 1);
      this.createPreview();
    },
    createPreview() {
      var groupCheck = {};
      this.preview = [];
      var index = -1;
      this.employeeCheck.content.forEach((item) => {
        if (!groupCheck[item.category]) {
          index++;
          groupCheck[item.category] = true;
          this.preview.push({
            index: index,
            category: item.category,
            items: [],
          });
        }
        item.check = null;
        this.preview[index].items.push(item);
      });
    },
  },
  async created() {
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "employee_check_form",
      where: [{ type: "is", key: "is_template", value: true }],
    });
    this.employeeCheck = response.data[0];
    this.createPreview();
  },
};
</script>
