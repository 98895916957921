<template>
  <v-row class="ma-6">
    <template v-for="section in sections">
      <v-col cols="4" align="center" v-if="checkSection(section)">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <router-link :to="section.link">
              <v-card
                v-bind="props"
                :elevation="isHovering ? '10' : '2'"
                rounded="xl"
              >
                <v-card-title>{{ section.title }}</v-card-title>
              </v-card>
            </router-link>
          </template>
        </v-hover>
      </v-col>
    </template>
  </v-row>
</template>

<script setup></script>
<script>
export default {
  data() {
    return {
      sections: [
        { title: "Admin", link: "/Settings/Admin", is_admin: true },
        {
          title: "Recruiting",
          link: "/Settings/Recruiting",
          is_recruiter: true,
        },
        { title: "Mitarbeiter", link: "/Settings/Employees", is_office: true },
        { title: "Hotels", link: "/Settings/Hotels", is_office: true },
        { title: "Allgemein", link: "/Settings/General", is_office: true },
      ],
      userRight: {},
    };
  },
  methods: {
    async checkSection(section) {
      var adminCheck = false;
      var recruiterCheck = false;
      var officeCheck = false;
      var hotelCheck = false;
      if (this.userRight) {
        if (
          !section.is_admin ||
          (section.is_admin && this.userRight.is_admin)
        ) {
          var adminCheck = true;
        }
        if (
          !section.is_recruiter ||
          (section.is_recruiter && this.userRight.is_recruiter)
        ) {
          var recruiterCheck = true;
        }
        if (
          !section.is_office ||
          (section.is_office && this.userRight.is_office)
        ) {
          var officeCheck = true;
        }
      }

      return adminCheck && recruiterCheck && officeCheck;
    },
  },
  async mounted() {
    var userRight = await this.$store.dispatch("getSupabaseEntity", {
      table: "public_user_rights",
    });
    this.userRight = userRight.data[0];
  },
};
</script>
