<template>
  <router-view />
</template>

<script setup>
//
</script>

<script>
export default {
  async mounted() {
    await this.$store.commit("initializeStore");
  },
};
//
</script>
