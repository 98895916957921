<template>
  <CalendarDialog
    :editEvent="editEvent"
    :entity="candidateInfo"
    v-if="$store.state.calendarDialog"
  ></CalendarDialog>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card
        v-bind="props"
        :elevation="isHovering ? '10' : '2'"
        rounded="xl"
        class="mb-3"
      >
        <v-card-title
          >Prozess
          <v-chip @click="changeJob.select = !changeJob.select" size="small">{{
            jobTitle
          }}</v-chip
          ><v-chip size="small" :color="jobStatus.color" variant="elevated">{{
            jobStatus.name
          }}</v-chip></v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col cols="5" class="mt-1">
              <v-tooltip
                :text="action.label"
                v-for="action in $store.state.applicationViews.actions"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    :color="
                      action.active ? 'indigo-lighten-1' : 'indigo-lighten-3'
                    "
                    class="mr-1"
                    rounded="lg"
                    v-bind="props"
                    size="small"
                    :disabled="action.disabled"
                    @click="
                      (action.active = !action.active), showComponent(action)
                    "
                  >
                    <v-icon>{{ action.icon }}</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </v-col>
            <v-col cols="2">
              <v-btn
                icon
                color="green"
                @click="openPhoneLink(candidateInfo.phone)"
              >
                <v-icon>mdi-phone</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="5" align="center">
              <v-rating
                length="4"
                size="small"
                v-model="newRating"
                @click="changeRating(newRating)"
              ></v-rating>
            </v-col>
          </v-row>

          <v-row v-if="$store.state.applicationViews.tutorial">
            <v-col>
              <v-card color="indigo-lighten-1">
                <v-card-text class="mx-4">
                  <div
                    v-html="application.recruiting_status.tutorial"
                    v-if="application.recruiting_status.tutorial"
                  ></div>
                  <div v-else>
                    Beschreibung muss noch in der
                    <a
                      href="/Settings/Recruiting"
                      style="color: white"
                      target="_blank"
                      >Statusliste hinzugefügt werden</a
                    >!
                  </div>
                </v-card-text></v-card
              >
            </v-col>
          </v-row>
          <v-row v-if="changeJob.select">
            <v-col cols="10">
              <v-autocomplete
                label="Jobs"
                :items="selections.jobList"
                item-title="title"
                item-value="id"
                v-model="changeJob.id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="mt-2" align="center">
              <v-btn
                icon
                color="indigo"
                size="small"
                @click="changeJobScript()"
                :loading="loading"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-list
            lines="2"
            v-if="duplicates.length > 0"
            class="bg-warning ma-1"
            rounded="xl"
          >
            <v-list-subheader>Mögliche Dubletten</v-list-subheader>
            <v-list-item
              class="pa-3"
              v-for="can in duplicates"
              target="_blank"
              :to="can.link"
              :title="can.name"
              :subtitle="can.status"
            >
              {{ can.denyReason ? can.denyReason : null }}
            </v-list-item>
          </v-list>
          <ApplicationActionView
            :emailObject="emailObject"
            :candidateInfo="candidateInfo"
            :application="application"
            v-if="application.id"
          ></ApplicationActionView>
          <template v-if="$store.state.applicationViews.others">
            <v-divider class="my-4"></v-divider>
            <v-row>
              <v-col cols="6" align="center">
                <v-btn color="error" @click="showDeny = !showDeny"
                  >Absagegrund</v-btn
                >
              </v-col>
              <v-row class="mx-2" v-if="showDeny">
                <v-col cols="12">
                  <v-autocomplete
                    :items="denyReasons"
                    item-title="reason"
                    item-value="id"
                    label="Absagegrund"
                    v-model="application.deny_reason_id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" align="center" class="mt-n6 mb-2">
                  <v-btn :loading="loading" @click="saveDeny()"
                    >Absage speichern</v-btn
                  >
                </v-col>
              </v-row>

              <v-col cols="6" align="center">
                <v-select
                  label="Manueller Status"
                  :items="selections.statusList"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  v-model="application.status_id"
                  @update:modelValue="moveToChosenStatus(application)"
                ></v-select>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
  <EmailDialog
    v-if="$store.state.emailDialog"
    :chosenEmail="emailObject.chosenEmail"
    :entityInfo="emailObject.entityInfo"
    :statusId="emailObject.statusId"
  ></EmailDialog>
</template>

<script setup>
import { mapState } from "vuex";
import ApplicationActionView from "./StatusProcess/ApplicationActionView.vue";
import EmailDialog from "@/components/CommonComponents/EmailComponent/EmailDialog.vue";
import CalendarDialog from "@/components/Recruiting/CalendarDialog.vue";
const props = defineProps({
  candidateInfo: Object,
});
</script>

<script>
export default {
  data() {
    return {
      application: {},
      loading: false,
      changeJob: {
        select: false,
        id: null,
      },
      denyReasons: [],
      showDeny: false,
      selections: {
        timeModel: [],
        experienceLevel: [],
        statusList: [],
        jobList: [],
      },
      newRating: null,
      duplicates: [],
      emailObject: {},
      jobTitle: null,
      jobStatus: {},
      editEvent: {
        id: null,
        summary: null,
        description: null,
        date: new Date(),
        startTime: null,
        endTime: null,
        start: null,
        end: null,
        attendees: [{ email: this.candidateInfo.email }],
      },
    };
  },
  computed: {
    ...mapState({
      cvDataObject: (state) => state.cvDataObject,
    }),
  },
  watch: {
    async cvDataObject(newValue, oldValue) {
      if (newValue) {
      }
    },
  },
  methods: {
    async getSelections() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_jobs_models",
      });
      this.selections.timeModel = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_experience_level",
      });
      this.selections.experienceLevel = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_status",
        where: [{ type: "is", key: "is_hidden", value: "false" }],
      });
      this.selections.statusList = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_jobs",
      });
      this.selections.jobList = response.data;
    },
    showComponent(action) {
      if (action.dialog == "calendarDialog") {
        this.editEvent.attendees = [{ email: this.candidateInfo.email }];
        this.$store.state.calendarDialog = true;
        action.active = false;
      } else {
        this.$store.state.applicationViews[action.view] =
          !this.$store.state.applicationViews[action.view];
      }
    },
    async changeRating(newRating) {
      this.application.rating = newRating;
      this.$store.dispatch("updateSupabaseEntity", {
        id: this.application.id,
        table: "recruiting_job_candidates",
        payload: { rating: newRating },
      });
      if (this.application.rating) {
        var queryObject = {
          table: "notes",
          payload: {
            job_candidate_id: this.application.id,
            action: `Rating wurde von ${this.application.rating} zu ${newRating} verändert!`,
            owner_id: this.$store.state.userInfo.id,
          },
        };
        await this.$store.dispatch("createSupabaseEntity", queryObject);
        this.$store.state.candidateChangeCheck = true;
      }
    },
    async getApplication() {
      this.duplicates = [];
      var jobCandidate = this.candidateInfo.recruiting_job_candidates[0];
      if (jobCandidate) {
        var jobCandidateId = jobCandidate.id;
        var queryObject = {
          select: "*,recruiting_status(*),recruiting_jobs(title)",
          table: "recruiting_job_candidates",
          where: [{ type: "eq", key: "id", value: jobCandidateId }],
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.application = response.data[0];
        if (this.application.recruiting_status.type != "meeting") {
          this.$store.state.applicationViews.actions[2].disabled = true;
        }
        this.jobTitle = this.application.recruiting_jobs.title;
        this.jobStatus = this.application.recruiting_status;
        delete this.application.recruiting_jobs;
        this.changeJob.id = this.application.job_id;
        this.duplicateCheck();
        this.newRating = this.application.rating;
      } else {
        this.application = {};
      }
    },
    async duplicateCheck() {
      var queryObject = {
        select:
          "*,recruiting_job_candidates(*,recruiting_deny_reasons(*),recruiting_status(*))",
        table: "recruiting_candidates",
        where: [
          {
            type: "ilike",
            key: "first_name",
            value: `${this.candidateInfo.first_name.split(" ")[0]}%`,
          },
          {
            type: "ilike",
            key: "last_name",
            value: `${this.candidateInfo.last_name}`,
          },
          { type: "neq", key: "id", value: this.candidateInfo.id },
          { type: "is", key: "soft_delete", value: null },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      if (response.data.length > 0) {
        for (var i = 0; i < response.data.length; i++) {
          var canObject = {
            link: "/Kandidaten/" + response.data[i].id,
            name:
              response.data[i].first_name + " " + response.data[i].last_name,
          };
          if (response.data[i].recruiting_job_candidates) {
            var application = response.data[i].recruiting_job_candidates[0];
            canObject.status = application.recruiting_status.name;
            if (application.recruiting_deny_reasons) {
              canObject.denyReason = application.recruiting_deny_reasons.reason;
            }
          }
          this.duplicates.push(canObject);
        }
      }
    },
    async changeJobScript() {
      this.loading = true;
      var queryObject = {
        table: "recruiting_job_candidates",
        payload: {
          job_id: this.changeJob.id,
        },
        id: this.application.id,
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.loading = false;
      this.getApplication();
    },
    openEmailDialog() {
      this.emailObject.chosenEmail = {
        type: "outgoing",
        from: "bewerbung@bellcaptain.team",
        to: this.candidateInfo.email,
        entity_id: this.candidateInfo.id,
        entity: "candidate",
        body: "<p></p>",
        date: new Date(),
      };
      this.emailObject.entityInfo = this.candidateInfo;
      this.emailObject.statusId = 10;
      this.$store.state.emailDialog = true;
    },
    async moveToChosenStatus(application) {
      var payload = JSON.parse(JSON.stringify(application));
      delete payload.recruiting_status;
      payload.modified_at = await this.$store.dispatch(
        "toISOLocal",
        new Date(),
      );
      payload.status_change_date = await this.$store.dispatch(
        "toISOLocal",
        new Date(),
      );
      this.$store.dispatch("upsertSupabaseEntity", {
        table: "recruiting_job_candidates",
        payload: payload,
      });
      var statusName = this.selections.statusList.find(
        (item) => item.id === payload.status_id,
      ).name;
      await this.$store.dispatch("createSupabaseEntity", {
        table: "notes",
        payload: {
          job_candidate_id: this.application.id,
          owner_id: this.$store.state.userInfo.id,
          action: `Status manuell angepasst zu: ${statusName}`,
        },
      });
      this.$store.state.candidateChangeCheck = true;
    },
    updateProfile() {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(this.candidateInfo));
      delete payload.recruiting_job_candidates;
      var queryObject = {
        table: "recruiting_candidates",
        id: this.candidateInfo.id,
        payload: payload,
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.loading = false;
    },
    openPhoneLink(phone) {
      const phoneLink = `tel:${phone}`;
      window.open(phoneLink);
    },
    openEmailLink(email) {
      const mailtoLink = `mailto:${email}`;
      window.open(mailtoLink);
    },
    saveDeny() {
      this.loading = true;
      var queryObject = {
        table: "recruiting_job_candidates",
        payload: {
          id: this.application.id,
          deny_reason_id: this.application.deny_reason_id,
          status_id: 10,
        },
      };

      this.$store.dispatch("upsertSupabaseEntity", queryObject);
      var denyReasonId = this.application.deny_reason_id;
      var denyReason = this.denyReasons.find(
        (item) => item.id === denyReasonId,
      ).reason;

      var queryObject = {
        table: "notes",
        payload: {
          job_candidate_id: this.application.id,
          action: "Absage",
          text: `Absage wegen ${denyReason}`,
          owner_id: this.$store.state.userInfo.id,
          activity_id: 10,
        },
      };
      this.$store.dispatch("createSupabaseEntity", queryObject);
      this.loading = false;
      this.showDeny = false;
      this.$store.state.candidateChangeCheck = true;
    },
  },
  async created() {
    this.$store.state.applicationViews = {
      availability: false,
      reminder: false,
      others: true,
      tutorial: false,
      actions: [
        {
          label: "Erreichbarkeit und Reminder",
          icon: "mdi-phone-alert",
          view: "reminder",
          active: false,
        },
        {
          label: "Absage senden oder Status manuell anpassen",
          icon: "mdi-alert-box",
          view: "others",
          active: true,
        },
        {
          label: "Termin eintragen",
          icon: "mdi-calendar",
          dialog: "calendarDialog",
          active: false,
        },
        {
          label: "Was bedeutet der Status und was ist zu tun?",
          icon: "mdi-head-question",
          view: "tutorial",
          active: false,
        },
      ],
    };
    this.getApplication();
    this.getSelections();
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "recruiting_deny_reasons",
    });
    response.data.sort((a, b) =>
      a.reason > b.reason ? 1 : b.reason > a.reason ? -1 : 0,
    );
    this.denyReasons = response.data;
  },
};
</script>
