<template>
  <h3 class="ma-1">Status</h3>
  <v-card elevation="3" v-for="element in statusList" class="ma-8">
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            variant="outlined"
            density="compact"
            v-model="element.name"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-chip>{{ element.count }}</v-chip>
        </v-col>
        <v-col cols="12">
          <div class="text-h5">Erklärung</div>
          <v-card class="quill-container">
            <QuillEditor
              toolbar="essential"
              v-model:content="element.tutorial"
              contentType="html"
              placeholder="Erklärung"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn
      :loading="loading"
      class="ma-5"
      color="green"
      @click="updateStatus(element)"
      >Speichern</v-btn
    >
  </v-card>
  <v-col align="center" v-if="false">
    <v-btn @click="addStatusElement()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn @click="saveStatus()" class="ml-2" :loading="loading">
      <v-icon>mdi-content-save</v-icon>
    </v-btn>
  </v-col>
</template>

<script setup>
import draggable from "vuedraggable";
import { QuillEditor } from "@vueup/vue-quill";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      tab: "statusList",
      statusList: [],
    };
  },
  methods: {
    addStatusElement() {
      var object = {
        id: null,
        name: null,
        order: this.statusList.length + 1,
        color: null,
        icon: null,
        count: 0,
      };
      this.statusList.push(object);
    },
    updateOrder() {
      this.statusList.forEach(function (item, index) {
        item.order = index + 1;
      });
    },
    async updateStatus(status) {
      this.loading = true;
      await this.$store.dispatch("updateSupabaseEntity", {
        table: "recruiting_status",
        id: status.id,
        payload: {
          tutorial: status.tutorial,
          name: status.name,
        },
      });
      this.loading = false;
    },
    async getStatusList() {
      var queryObject = {
        select: "*,recruiting_job_candidates(id)",
        table: "recruiting_status",
        order: { key: "order" },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach(function (item) {
        item.count = item.recruiting_job_candidates.length;
      });
      this.statusList = response.data;
    },
    async deleteStatus(element, index) {
      var queryObject = {
        id: element.id,
        table: "recruiting_status",
      };
      if (element.id) {
        await this.$store.dispatch("deleteSupabaseEntity", queryObject);
      }

      this.statusList.splice(index, 1);
      this.updateOrder();
    },
    async saveStatus() {
      this.saveArray("recruiting_status", this.statusList);
    },
    async saveArray(table, payload) {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        delete payload[i].count;
        delete payload[i].recruiting_job_candidates;
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      this.loading = false;
    },
  },
  async mounted() {
    this.getStatusList();
  },
};
</script>
