<template>
  <v-card
    class="ma-4 pa-4 elevation-4"
    flat
    color="grey-lighten-4"
    rounded="xl"
  >
    <v-card-title>Signatur</v-card-title>
    <v-col cols="12" class="my-n4">
      <v-chip v-for="logo in logos" @click="addLogoLink(logo)">{{
        logo.name
      }}</v-chip>
    </v-col>
    <v-card-text>
      <quill-editor
        toolbar="essential"
        v-model:content="user.signature"
        contentType="html"
      ></quill-editor>
      <v-btn
        class="mt-2"
        color="success"
        :loading="loading"
        @click="saveSignature()"
        >Speichern</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
const props = defineProps(["user"]);
</script>

<script>
export default {
  data() {
    return {
      logos: [],
      loading: false,
    };
  },
  async created() {
    this.getLogos();
  },
  methods: {
    async getLogos() {
      var response = await this.$store.dispatch("getStorageFolderFiles", {
        bucket: "logos",
        folder: "SignatureLogos",
      });
      response.data.shift();
      this.logos = response.data;
    },
    async addLogoLink(logo) {
      var publicLink = await this.$store.dispatch("getPublicStorageFileLink", {
        bucket: "logos",
        file: "SignatureLogos/" + logo.name,
      });
      var logoLink = `<img height="60px" src="${publicLink}"></img>`;
      this.user.signature = this.user.signature + logoLink;
    },
    async saveSignature() {
      this.loading = true;
      var queryObject = {
        id: this.user.id,
        table: "public_users",
        payload: {
          signature: this.user.signature,
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.loading = false;
    },
  },
};
</script>
