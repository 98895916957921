<template>
  <v-col cols="6" class="mb-n6">
    <v-text-field
      v-model="newCategory"
      label="Neue Kategorie"
      append-icon="mdi-plus"
      @click:append="addCategory(newCategory)"
    ></v-text-field>
  </v-col>

  <v-list>
    <v-list-item
      v-for="category in documentCategories"
      @click="
        dialog.category = category;
        dialog.show = true;
      "
    >
      <v-list-item-title>{{ category.name }}</v-list-item-title>
    </v-list-item>
  </v-list>
  <v-dialog v-model="dialog.show" width="300">
    <v-card>
      <v-card-title class="bg-indigo-darken-2"
        >Katgorie bearbeiten</v-card-title
      >
      <v-card-text>
        <v-text-field
          disabled
          variant="outlined"
          label="ID"
          v-model="dialog.category.id"
        ></v-text-field>
        <v-text-field
          variant="outlined"
          label="Name"
          v-model="dialog.category.name"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" icon @click="deleteCategory(dialog.category)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue" icon @click="updateCategory(dialog.category)">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      documentCategories: [],
      newCategory: null,
      dialog: {
        show: false,
        category: {},
      },
    };
  },
  methods: {
    async getDocumentCategories() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_document_categories",
      });
      this.documentCategories = await response.data;
    },
    async addCategory(category) {
      var queryObject = {
        table: "employee_document_categories",
        payload: {
          name: category,
        },
      };
      await this.$store.dispatch("createSupabaseEntity", queryObject);
      this.newCategory = null;
      this.getDocumentCategories();
    },
    async deleteCategory(category) {
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "employee_document_categories",
        id: category.id,
      });
      this.getDocumentCategories();
      this.dialog = {
        show: false,
        category: {},
      };
    },
    async updateCategory(category) {
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "employee_document_categories",
        payload: category,
      });
      this.getDocumentCategories();
      this.dialog = {
        show: false,
        category: {},
      };
    },
  },
  created() {
    this.getDocumentCategories();
  },
};
</script>
