<template>
  <h3 class="ma-2">Indeed Jobs</h3>
  <v-card>
    <v-card-text class="mt-3">
      <v-row v-for="(reason, index) in denyReasons" class="my-n10">
        <v-col cols="12">
          <v-text-field
            variant="outlined"
            density="compact"
            append-icon="mdi-delete"
            @click:append="deleteReason(reason, index)"
            v-model="reason.reason"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-col align="center">
      <v-btn @click="denyReasons.push({ reason: null })">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn @click="saveReasons()" class="ml-2" :loading="loading">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-col>
  </v-card>
</template>

<script setup>
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      denyReasons: [],
    };
  },
  methods: {
    async getDenyReasons() {
      var queryObject = {
        table: "recruiting_deny_reasons",
        select: "*",
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.sort((a, b) =>
        a.reason > b.reason ? 1 : b.reason > a.reason ? -1 : 0,
      );
      this.denyReasons = response.data;
    },
    async deleteReason(job, index) {
      if (job.id) {
        await this.$store.dispatch("deleteSupabaseEntity", {
          id: job.id,
          table: "recruiting_deny_reasons",
        });
      }
      this.denyReasons.splice(index, 1);
    },
    async saveReasons() {
      var table = "recruiting_deny_reasons";
      var payload = this.denyReasons;
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      await this.getDenyReasons();
      this.loading = false;
    },
  },
  async mounted() {
    this.getDenyReasons();
  },
};
</script>
