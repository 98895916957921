<template>
  <v-row>
    <v-col cols="3" align="center">
      <v-list height="1000px">
        <v-list-item
          @click="chosenSection = section"
          v-for="section in sections"
          >{{ section.name }}</v-list-item
        >
      </v-list>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="9">
      <v-row class="mt-4">
        <v-col
          cols="12"
          v-for="(key, index) in chosenSection.employee_data_keys"
        >
          <v-row class="my-n6">
            <v-col xl="7" md="6">
              <v-text-field
                label="Label"
                v-model="key.key_name_ger"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                label="Typ"
                :items="evaluationInputs.types"
                v-model="key.type"
                :disabled="key.id && !key.editable"
              ></v-select>
            </v-col>
            <v-col cols="1" align="center">
              <v-btn
                color="red"
                :disabled="key.id && !key.editable"
                icon
                @click="deleteKey(key, index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              align="center"
              class="mt-n8 mb-6"
              v-if="key.type == 'selection'"
            >
              <h4>Dropdown Elemente</h4>
              <v-btn
                size="x-small"
                closable
                variant="outlined"
                rounded="xl"
                append-icon="mdi-close"
                v-for="(dropdownElement, index) in key.selection"
                >{{ dropdownElement
                }}<template v-slot:append>
                  <v-icon @click="key.selection.splice(index, 1)"
                    >mdi-close</v-icon
                  >
                </template></v-btn
              >
              <v-col cols="6">
                <v-text-field
                  append-inner-icon="mdi-plus"
                  density="compact"
                  v-model="dropdownElementAdd"
                  @click:append-inner="
                    key.selection.push(dropdownElementAdd);
                    dropdownElementAdd = null;
                  "
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-2" align="center">
          <v-btn @click="addElement()">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-bottom-navigation>
    <v-spacer></v-spacer>
    <v-btn
      class="bg-green"
      v-if="chosenSection.id > 0"
      @click="updateFields()"
      :loading="loading"
      >Aktualisiseren</v-btn
    >
  </v-bottom-navigation>
</template>

<script setup>
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      sections: [],
      loading: false,
      chosenSection: {},
      dropdownElementAdd: null,
      evaluationInputs: {
        types: ["text", "id", "selection", "number", "date", "boolean"],
      },
    };
  },
  methods: {
    async addElement() {
      var queryObject = {
        table: "employee_data_keys",
        payload: {
          section_id: this.chosenSection.id,
        },
      };
      var id = await this.$store.dispatch("createSupabaseEntity", queryObject);
      var keyLabel = "key_" + id;
      var queryObject = {
        table: "employee_data_keys",
        id: id,
        payload: {
          key_label: keyLabel,
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.chosenSection.employee_data_keys.push({
        id: id,
        section_id: this.chosenSection.id,
        key_label: keyLabel,
        editable: true,
      });
    },
    async deleteKey(key, index) {
      this.loading = true;
      console.log(index);
      if (key.id) {
        var queryObject = {
          table: "employee_data_keys",
          id: key.id,
        };
        await this.$store.dispatch("deleteSupabaseEntity", queryObject);
      }
      this.chosenSection.employee_data_keys.splice(index, 1);
      this.loading = false;
    },
    async getSections() {
      var queryObject = {
        select: "*,employee_data_keys(*)",
        table: "employee_data_sections",
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach(function (section) {
        section.employee_data_keys.forEach(function (key) {
          if (!key.selection) {
            key.selection = [];
          }
        });
      });
      this.sections = response.data;
      console.log(this.sections);

      this.chosenSection = this.sections[0];
    },
    async updateFields() {
      this.loading = true;
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "employee_data_keys",
        payload: this.chosenSection.employee_data_keys,
      });
      this.loading = false;
    },
  },
  async created() {
    this.getSections();
  },
};
</script>
