<template>
  <v-row class="ma-1" justify="center">
    <v-col cols="8" align="center" fill-height>
      <iframe
        width="100%"
        height="900"
        src="https://lookerstudio.google.com/embed/reporting/5643838e-f80c-49f7-85f1-1f1f774a23c1/page/F6npD"
        frameborder="0"
        style="border: 0"
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </v-col>
  </v-row>
</template>
