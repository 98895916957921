<template>
  <div>
    <!-- Your component HTML goes here -->
    <v-data-table :headers="headers" :items="templates" item-key="id">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Dateivorlagen</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createTemplate">Neue Vorlage</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <v-btn
          @click="openFile(item)"
          variant="text"
          class="text-decoration-underline"
          >{{ item.name }}</v-btn
        >
        <v-chip color="blue-darken-3" size="small" variant="elevated">{{
          item.is_public ? "Für alle" : "Nur Büro"
        }}</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon @click="(dialog.visible = true), (dialog.item = item)"
          >mdi-pencil</v-icon
        >
        <v-icon class="ml-8" @click="deleteTemplate(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toLocaleString("de-DE").split(",")[0] }}
      </template>
    </v-data-table>
  </div>
  <v-dialog v-model="dialog.visible" width="600">
    <v-card>
      <v-card-title>{{ dialog.item.name }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="dialog.item.name"
              label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="dialog.item.category_id"
              :items="categories"
              item-title="name"
              item-value="id"
              label="Kategorie"
            ></v-select>
          </v-col>
          <v-col cols="4" align="center">
            <v-btn @click="openFile(dialog.item)">Download Datei</v-btn>
          </v-col>
          <v-col cols="8">
            <v-file-input
              v-model="dialog.item.file"
              label="Datei hinzufügen"
              accept="application/pdf"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <div>
              <Quill-Editor
                toolbar="essential"
                v-model:content="dialog.item.description"
                contentType="html"
                placeholder="Notes"
              />
            </div>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="dialog.item.is_public"
              label="Für alle sichtbar"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-btn
              v-if="dialog.item.id"
              :loading="dialog.loading"
              @click="saveTemplate(dialog.item, 'update')"
              >Aktualisieren</v-btn
            >
            <v-btn
              :loading="dialog.loading"
              v-else
              @click="saveTemplate(dialog.item, 'create')"
              >Erstellen</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
</script>
<script>
export default {
  name: "YourComponentName",
  data() {
    return {
      headers: [
        { title: "Name", value: "name" },
        { title: "Kategorie", value: "employee_document_categories.name" },
        { title: "Erstellt von", value: "public_users.full_name" },
        { title: "Erstellt am", value: "created_at" },
        { title: "Aktionen", value: "actions", sortable: false },
      ],
      templates: [],
      categories: [],
      dialog: {
        visible: false,
        loading: false,
        item: {
          category_id: null,
          description: null,
        },
      },
    };
  },
  methods: {
    async getCategories() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        select: "*",
        table: "employee_document_categories",
      });
      this.categories = response.data;
    },
    async getTemplates() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        select: "*,employee_document_categories(*),public_users(*)",
        table: "employee_file_templates",
      });
      this.templates = response.data;
    },
    async createTemplate() {
      this.dialog.visible = true;
      this.dialog.item = {
        category_id: null,
        description: null,
        is_public: false,
      };
    },
    async saveTemplate(item, type) {
      this.dialog.loading = true;
      var payload = JSON.parse(JSON.stringify(item));
      payload.created_by = this.$store.state.userInfo.id;
      delete payload.employee_document_categories;
      delete payload.public_users;
      delete payload.file;
      if (type == "update") {
        var response = await this.$store.dispatch("updateSupabaseEntity", {
          table: "employee_file_templates",
          id: payload.id,
          payload: payload,
        });
      } else {
        var response = await this.$store.dispatch("createSupabaseEntity", {
          table: "employee_file_templates",
          payload: payload,
        });
      }
      if (item.file) {
        var queryObject = {
          bucket: "templates",
          file: item.file[0],
          filePath: `${item.category_id}/${item.name}`,
        };
        var response = await this.$store.dispatch(
          "uploadStorageFile",
          queryObject,
        );
        console.log(response);
      }
      this.dialog.loading = false;
      this.dialog.visible = false;
      this.getTemplates();
    },
    async deleteTemplate(item) {
      console.log(item);
      var response = await this.$store.dispatch("deleteSupabaseEntity", {
        table: "employee_file_templates",
        id: item.id,
      });
      this.getTemplates();
    },
    async openFile(item) {
      var file = item;

      var queryObject = {
        bucket: "templates",
        file: `${file.category_id}/${file.name}`,
      };
      var url = await this.$store.dispatch("getStorageFileLink", queryObject);
      window.open(url, "_blank");
    },

    // Your component methods
  },
  created() {
    this.getTemplates();
    this.getCategories();
  },
};
</script>

<style scoped>
/* Your component CSS goes here */
</style>
