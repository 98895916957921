<template>
  <v-navigation-drawer
    v-model="$store.state.recruitingView.candidateView.drawer"
    temporary
    location="right"
    :width="1400"
    class="pa-4"
    color="grey-lighten-4"
  >
    <Candidate
      v-if="$store.state.candidateView.id"
      :candidateId="$store.state.candidateView.id"
      type="drawer"
    ></Candidate>
  </v-navigation-drawer>
  <v-row class="ma-8">
    <v-col cols="12" align="center">
      <v-row>
        <v-col v-for="(status, index) in statusList">
          <v-card rounded="lg" elevation="4" :color="status.color">
            <v-card-text>
              <v-icon :icon="status.icon"></v-icon>
              {{ status.name }}
              <div v-if="index == 0">
                <v-btn
                  :color="status.color"
                  icon
                  size="x-small"
                  @click="newCandidate.dialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <v-dialog
            v-model="newCandidate.dialog"
            transition="dialog-bottom-transition"
            width="1000px"
          >
            <v-card>
              <v-card-title>Neue Bewerbung anlegen</v-card-title>
              <v-card-text>
                <NewCandidate :jobId="job"></NewCandidate>
              </v-card-text>
            </v-card>
          </v-dialog>
          <draggable
            v-model="status.recruiting_job_candidates"
            group="name"
            item-key="id"
            class="pt-8"
            @change="changeStatus"
            style="cursor: grab"
          >
            <template #item="{ element, index }">
              <v-card class="ma-1" rounded="lg" elevation="4">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-rating
                        readonly
                        size="x-small"
                        :color="element.rating == 3 ? 'success' : 'indigo'"
                        v-model="element.rating"
                        length="3"
                      ></v-rating>
                    </v-col>
                    <v-col cols="12" class="mt-n7">
                      <span
                        @click="openCandidateView(element)"
                        class="text-decoration-underline"
                        style="cursor: pointer"
                        >{{
                          element.recruiting_candidates.first_name +
                          " " +
                          element.recruiting_candidates.last_name
                        }}</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </draggable>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import draggable from "vuedraggable";
import Candidate from "@/components/Candidates/View.vue";
import NewCandidate from "@/components/Jobs/NewCandidate.vue";
</script>
<script>
export default {
  data() {
    return {
      drawer: false,
      job: null,
      statusList: [],
      isClicking: false,
      newCandidate: {
        dialog: false,
      },
    };
  },
  methods: {
    changeStatus(evt) {
      if (evt.added) {
        var jobCandidateId = evt.added.element.id;
        for (var i = 0; i < this.statusList.length; i++) {
          for (
            var j = 0;
            j < this.statusList[i].recruiting_job_candidates.length;
            j++
          ) {
            var match = this.statusList[i].recruiting_job_candidates[j];
            if (match.id == jobCandidateId) {
              var newStatusId = this.statusList[i].id;
              var queryObject = {
                table: "recruiting_job_candidates",
                id: match.id,
                payload: {
                  status_id: newStatusId,
                  modified_at: new Date().toISOString(),
                },
              };
              this.$store.dispatch("updateSupabaseEntity", queryObject);
              var queryObject = {
                table: "notes",
                payload: {
                  action: this.statusList[i].name,
                  owner_id: this.$store.state.userInfo.id,
                  job_candidate_id: match.id,
                },
              };
              this.$store.dispatch("createSupabaseEntity", queryObject);
              break;
            }
          }
        }
      }
    },
    async getJobCandidates(chosenJob) {
      var queryObject = {
        select: "*,recruiting_job_candidates(*,recruiting_candidates(*))",
        table: "recruiting_status",
        where: [
          {
            type: "eq",
            key: "recruiting_job_candidates.job_id",
            value: chosenJob,
          },
        ],
        order: { key: "order" },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.statusList = response.data;
    },
    async openCandidateView(data) {
      this.$store.state.candidateView.id = null;

      this.$store.state.recruitingView.candidateView.drawer = true;
      await this.sleep();
      this.$store.state.candidateView.id = data.candidate_id;
    },
    async sleep() {
      return new Promise((resolve) => setTimeout(resolve, 2));
    },
  },
  async mounted() {
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "public_users",
      where: [{ type: "eq", key: "email", value: this.$store.state.username }],
    });
    this.$store.state.userInfo = response.data[0];
    this.job = this.$route.params.id;
    this.getJobCandidates(this.$route.params.id);
  },
};
</script>
