<template>
  <h3 class="ma-2">Indeed Jobs</h3>
  <v-card>
    <v-card-text class="mt-3">
      <v-row v-for="(job, index) in indeedJobs" class="my-n10">
        <v-col cols="1">
          <v-checkbox readonly v-model="job.chosen"></v-checkbox>
        </v-col>
        <v-col cols="11">
          <v-text-field
            variant="outlined"
            density="compact"
            append-icon="mdi-delete"
            @click:append="deleteJob(job, index)"
            v-model="job.title"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-col align="center">
      <v-btn @click="indeedJobs.push({ title: null })">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn @click="saveJobs()" class="ml-2" :loading="loading">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-col>
  </v-card>
</template>

<script setup>
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      indeedJobs: [],
    };
  },
  methods: {
    async getIndeedJobs() {
      var queryObject = {
        table: "recruiting_indeed_jobs",
        select: "*",
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.indeedJobs = response.data;
    },
    async deleteJob(job, index) {
      if (job.id) {
        await this.$store.dispatch("deleteSupabaseEntity", {
          id: job.id,
          table: "recruiting_indeed_jobs",
        });
      }
      this.indeedJobs.splice(index, 1);
    },
    async saveJobs() {
      var table = "recruiting_indeed_jobs";
      var payload = this.indeedJobs;
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      this.loading = false;
    },
  },
  async mounted() {
    this.getIndeedJobs();
  },
};
</script>
