<template>
  <calendar-dialog
    :editEvent="editEvent"
    v-if="$store.state.calendarDialog"
    :entity="entity"
    :entityId="entityId"
  ></calendar-dialog>
  <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
  <v-card rounded="xl">
    <v-card-text>
      <v-row>
        <v-col v-for="event in events" cols="12" :sm="entityId ? '12' : '3'">
          <v-card rounded="xl">
            <v-card-title class="bg-indigo">
              <v-row>
                <v-col align="left">{{ event.summary }}</v-col>
                <v-col align="right">
                  <v-btn flat color="indigo" @click="editEventObject(event)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row></v-card-title
            >
            <v-card-subtitle class="mt-1 mb-n2">{{
              event.showDateStart +
              " - " +
              event.showDateEnd.split(" ")[1] +
              " (" +
              event.day +
              ")"
            }}</v-card-subtitle>
            <v-card-text class="mx-2">
              <router-link
                :to="'/Kandidaten/' + attendee.id"
                v-for="attendee in event.attendees"
              >
                <v-chip
                  @click=""
                  size="x-small"
                  variant="elevated"
                  :color="statusColor(attendee.responseStatus)"
                >
                  <v-icon v-if="attendee.responseStatus == 'accepted'"
                    >mdi-check</v-icon
                  >
                  <v-icon v-if="attendee.responseStatus == 'declined'"
                    >mdi-alert</v-icon
                  >
                  {{ attendee.first_name + " " + attendee.last_name }}
                </v-chip>
              </router-link>
              <p v-if="event.hangoutLink" class="mt-2">
                <a :href="event.hangoutLink" target="_blank">{{
                  event.hangoutLink
                }}</a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import axios from "axios";
import { mapState } from "vuex";
import CalendarDialog from "@/components/Recruiting/CalendarDialog.vue";
const props = defineProps(["entityId", "entity"]);
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      calendar: {},
      today: null,
      events: [],
      editEvent: {},
    };
  },
  computed: {
    ...mapState({
      calendarDialog: (state) => state.calendarDialog,
    }),
  },
  watch: {
    async calendarDialog(newValue, oldValue) {
      if (newValue == false) {
        this.checkCalendar();
      }
    },
  },
  methods: {
    async editEventObject(event) {
      this.editEvent.id = event.id;
      this.editEvent.summary = event.summary;
      this.editEvent.date = new Date(event.start.dateTime);
      this.editEvent.startTime =
        event.showDateStart.split(" ")[1].split(":")[0] +
        ":" +
        event.showDateStart.split(" ")[1].split(":")[1];
      this.editEvent.endTime =
        event.showDateEnd.split(" ")[1].split(":")[0] +
        ":" +
        event.showDateEnd.split(" ")[1].split(":")[1];
      this.$store.state.calendarDialog = true;
    },
    statusColor(responseStatus) {
      switch (responseStatus) {
        case "accepted":
          return "success";
        case "declined":
          return "error";
        default:
          return "grey";
      }
    },
    async checkCalendar() {
      this.loading = true;
      this.events = await this.$store.dispatch("checkCalendar", {
        entity: this.entity,
      });
      this.events.sort((a, b) =>
        new Date(a.start.dateTime) > new Date(b.start.dateTime)
          ? 1
          : new Date(b.start.dateTime) > new Date(a.start.dateTime)
          ? -1
          : 0,
      );
      this.loading = false;
    },
  },
  async created() {
    this.checkCalendar();
  },
};
</script>
