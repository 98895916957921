<template>
  <v-row class="mx-4">
    <template v-for="(key, index) in $store.state.employeeFilter.chosenKeys">
      <v-col
        cols="12"
        sm="4"
        xl="2"
        lg="4"
        class="my-n2"
        v-if="key.value != 'hotels'"
      >
        <v-switch
          v-if="key.type == 'boolean'"
          :label="key.key_name_ger"
          v-model="key.value"
          :append-inner-icon="settings ? 'mdi-minus-circle' : null"
          @click:append-inner="removeKey(index)"
        ></v-switch>
        <v-autocomplete
          v-if="key.type == 'selection'"
          :label="key.key_name_ger"
          :items="key.selection"
          v-model="key.key_value"
          :append-inner-icon="settings ? 'mdi-minus-circle' : null"
          @click:append-inner="removeKey(index)"
          @update:modelValue="updateKeys()"
          clearable
          multiple
          chips
        ></v-autocomplete>
        <v-select
          v-if="key.type == 'id' && key.id != 5"
          :label="key.key_name_ger"
          :items="key.selection"
          item-title="name"
          item-value="name"
          v-model="key.key_value"
          :append-inner-icon="settings ? 'mdi-minus-circle' : null"
          @update:modelValue="updateKeys()"
          @click:append-inner="
            $store.state.employeeFilter.chosenKeys.splice(index, 1)
          "
          clearable
          multiple
          chips
        ></v-select>
        <v-select
          v-if="key.type == 'id' && key.id == 5"
          :label="key.key_name_ger"
          :items="key.selection"
          item-title="city"
          item-value="city"
          v-model="key.key_value"
          :append-inner-icon="settings ? 'mdi-minus-circle' : null"
          @update:modelValue="updateKeys()"
          @click:append-inner="
            $store.state.employeeFilter.chosenKeys.splice(index, 1)
          "
          clearable
          multiple
          chips
        ></v-select>
      </v-col>
    </template>

    <v-tooltip bottom>
      <template v-slot:activator="{ props }">
        <v-btn
          size="small"
          class="mt-3"
          color="blue-grey-lighten-2"
          @click="settings = !settings"
          v-bind="props"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span>Spalten und Filter einstellen</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ props }">
        <v-btn
          size="small"
          class="mt-3 ml-2"
          color="success"
          @click="$store.state.employeeView.newDialog = true"
          v-bind="props"
        >
          <v-icon>mdi-account</v-icon>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neuen Mitarbeiter erstellen</span>
    </v-tooltip>
  </v-row>
  <v-row class="ma-4" v-if="settings">
    <v-col cols="12" sm="6">
      <v-autocomplete
        @update:modelValue="addKey"
        variant="outlined"
        label="Attribute"
        :items="keys"
        item-title="key_name_ger"
        return-object
      ></v-autocomplete>
    </v-col>
  </v-row>
  <v-dialog
    v-model="$store.state.employeeView.newDialog"
    temporary
    location="right"
    width="1000"
    class="pa-4"
    color="grey-lighten-3"
  >
    <v-card>
      <v-card-title class="bg-indigo-darken-2"
        >Neuen Mitarbeiter erstellen</v-card-title
      >
      <v-card-text>
        <v-text-field
          label="Vorname"
          v-model="newEmployee.first_name"
        ></v-text-field>
        <v-text-field
          label="Nachname"
          v-model="newEmployee.last_name"
        ></v-text-field>
        <v-text-field label="Email" v-model="newEmployee.email"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          @click="createNewEmployee()"
          :disabled="
            !newEmployee.first_name ||
            !newEmployee.last_name ||
            !newEmployee.email
          "
          >Mitarbeiter erstellen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup></script>
<script>
export default {
  data() {
    return {
      keys: [],
      keyObject: {},
      positions: [],
      settings: false,
      newEmployee: {
        first_name: null,
        last_name: null,
        email: null,
      },
      loading: false,
    };
  },
  methods: {
    async getKeys() {
      if (this.$store.state.employeeFilter.employee_data_keys.length == 0) {
        var response = await this.$store.dispatch("getSupabaseEntity", {
          table: "employee_data_keys",
        });
        this.keys = response.data;
        this.$store.state.employeeFilter.employee_data_keys = this.keys;
      } else {
        this.keys = this.$store.state.employeeFilter.employee_data_keys;
      }

      this.keys.forEach((item) => {
        this.keyObject[item.id] = item;
      });
    },
    async constructKeys() {
      if (this.$store.state.employeeFilter.chosenKeys.length <= 2) {
        if (!this.$store.state.userInfo.id) {
          await this.$store.dispatch("getUserInfo");
        }
        var queryObject = {
          select: "employee_keys",
          table: "public_users",
          where: [
            { type: "eq", key: "id", value: this.$store.state.userInfo.id },
          ],
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        if (response.data[0]?.employee_keys) {
          var chosenKeys = response.data[0].employee_keys;
        } else {
          var chosenKeys = [
            {
              id: 1,
            },
            {
              id: 6,
            },
            {
              id: 7,
            },
            {
              id: 5,
            },
          ];
        }
        chosenKeys.forEach((item) => {
          var key = this.keyObject[item.id];
          if (key) {
            key.key_value = item.key_value;
            this.addKey(key, "start");
          }
        });
      }
    },
    async addKey(key, start) {
      key.title = key.key_name_ger;
      key.value = key.key_label;
      key.key = key.key_label;
      if (key.type == "id") {
        var response = await this.$store.dispatch("getSupabaseEntity", {
          table: key.selection_table,
          order: {
            key: key.item_name,
            ascending: true,
          },
        });
        key.selection = response.data;
      }
      if (key.type == "text" || key.type == "number" || key.type == "date") {
        var matching = {
          text: "value_text",
          number: "value_number",
          date: "value_date",
        };
        var valueKey = matching[key.type];
        var queryObject = {
          select: valueKey,
          table: "employee_data_matching",
          where: [{ type: "eq", key: "key_id", value: key.id }],
          order: {
            key: valueKey,
            ascending: true,
          },
        };
        var keyResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        key.selection = [
          ...new Set(keyResponse.data.map((item) => item[valueKey])),
        ];
        key.type = "selection";
      }
      this.$store.state.employeeFilter.chosenKeys.push(key);
      if (!start) {
        this.updateKeys();
      }
    },
    removeKey(index) {
      this.$store.state.employeeFilter.chosenKeys.splice(index, 1);
      this.updateKeys();
    },
    updateKeys() {
      var keyIds = [];
      this.$store.state.employeeFilter.chosenKeys.forEach(function (item) {
        keyIds.push({ id: item.id, key_value: item.key_value });
      });
      this.$store.dispatch("updateSupabaseEntity", {
        table: "public_users",
        id: this.$store.state.userInfo.id,
        payload: { employee_keys: keyIds },
      });
    },
    async createNewEmployee() {
      this.newEmployee.name =
        this.newEmployee.first_name + " " + this.newEmployee.last_name;
      this.newEmployee.status = "Aktiv";
      this.loading = true;

      var publicUserObject = {
        first_name: this.newEmployee.first_name,
        last_name: this.newEmployee.last_name,
        email: this.newEmployee.email,
        full_name: this.newEmployee.name,
        active: true,
      };

      var publicUserId = await this.$store.dispatch(
        "createUserInSystem",
        publicUserObject,
      );
      var queryObject = {
        table: "employees",
        payload: this.newEmployee,
      };
      this.newEmployee.user_id = publicUserId;
      var id = await this.$store.dispatch("createSupabaseEntity", queryObject);
      var employeeKeyMatching = {
        table: "employee_data_matching",
        payload: [
          { employee_id: id, key_id: 1, value_text: this.newEmployee.name },
          {
            employee_id: id,
            key_id: 2,
            value_text: this.newEmployee.first_name,
          },
          {
            employee_id: id,
            key_id: 3,
            value_text: this.newEmployee.last_name,
          },
          { employee_id: id, key_id: 17, value_text: this.newEmployee.email },
        ],
      };
      await this.$store.dispatch("createSupabaseEntity", employeeKeyMatching);
      this.$store.state.employeeView.newDialog = false;
      this.$router.push("/Mitarbeiter/" + id);
      this.loading = false;
    },
  },
  async created() {
    await this.getKeys();
    this.constructKeys();
  },
};
</script>

<script setup></script>
