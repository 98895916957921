<template>
  <v-card class="pa-4 ma-4 full-height-card" variant="outlined">
    <v-card-title align="center">
      <v-row>
        <v-col cols="1">
          <v-btn size="small" color="success" @click="openNewMail()">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10" class="mt-1">Emails</v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-container class="ma-2 mb-4" v-if="entity != 'recruiting_candidates'">
        <template v-for="group in emailFilter">
          <v-btn
            :value="group.id"
            rounded="xl"
            :variant="group.activated ? 'elevated' : 'outlined'"
            color="blue-darken-2"
            @click="group.activated = !group.activated"
            >{{ group.topic }}</v-btn
          >
        </template>
      </v-container>
      <v-row v-for="email in emails">
        <v-col
          cols="12"
          sm="6"
          v-if="
            emailFilter.find((filter) => filter.id == email.topic_id)
              ? emailFilter.find((filter) => filter.id == email.topic_id)
                  .activated
              : false
          "
        >
          <v-card
            v-if="email.type == 'sent' || email.type == 'outgoing'"
            variant="tonal"
            class="rounded-xl"
            color="blue-darken-1"
          >
            <v-card-title> </v-card-title>
            <v-card-text>
              <div>
                <v-row>
                  <v-col
                    cols="12"
                    align="center"
                    v-if="email.showBody"
                    @click="email.showBody = false"
                  >
                    <v-btn icon flat align="center" size="small"
                      ><v-icon>mdi-arrow-collapse-up</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    align="center"
                    v-if="email.email_groups.use_frontend"
                  >
                    <v-chip size="small" variant="elevated" color="green">{{
                      email.email_groups.topic
                    }}</v-chip>
                  </v-col>
                  <v-col cols="7">
                    {{ email.subject }}
                  </v-col>
                  <v-col cols="4" align="right">
                    {{ email.showDate }}
                  </v-col>
                  <v-col cols="12" v-if="email.showBody" v-html="email.body">
                  </v-col>
                  <v-col
                    v-else
                    align="center"
                    @click="email.showBody = true"
                    class="my-n4"
                  >
                    <v-btn icon flat size="small"
                      ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                    ></v-col
                  >
                  <v-col
                    cols="12"
                    align="center"
                    v-if="email.showBody"
                    @click="email.showBody = false"
                  >
                    <v-btn icon flat align="center" size="small"
                      ><v-icon>mdi-arrow-collapse-up</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          v-if="
            emailFilter.find((filter) => filter.id == email.topic_id)
              ? emailFilter.find((filter) => filter.id == email.topic_id)
                  .activated
              : false
          "
        >
          <v-card
            v-if="email.type == 'received'"
            variant="tonal"
            class="rounded-xl"
            color="red-darken-3"
          >
            <v-card-title>
              <v-row>
                <v-col align="left">
                  <v-checkbox
                    label="Email verarbeitet"
                    v-model="email.processed"
                    @change="saveProcessStatus(email)"
                  ></v-checkbox>
                </v-col>
                <v-col align="center" v-if="email.email_groups.use_frontend">
                  <v-chip size="small" variant="elevated" color="green">{{
                    email.email_groups.topic
                  }}</v-chip>
                </v-col>
                <v-col align="right">
                  <v-btn flat @click="replyEmail(email)">
                    <v-icon>mdi-reply</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <div>
                <v-row>
                  <v-col cols="7">
                    {{ email.subject }}
                  </v-col>
                  <v-col cols="4" align="right">
                    {{ email.showDate }}
                  </v-col>
                  <v-col cols="12" v-if="email.showBody" v-html="email.body">
                  </v-col>
                  <v-col
                    cols="12"
                    align="center"
                    v-if="email.showBody"
                    @click="email.showBody = false"
                  >
                    <v-btn icon flat align="center" size="small"
                      ><v-icon>mdi-arrow-collapse-up</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    v-else
                    align="center"
                    @click="email.showBody = true"
                    class="my-n6"
                  >
                    <v-btn icon flat size="small"
                      ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                    ></v-col
                  >
                  <v-col cols="12">
                    <v-col v-for="attachment in email.attachments" cols="6">
                      <v-card rounded="xl" variant="tonal">
                        <v-card-text class="ma-2">
                          <v-icon
                            color="red"
                            v-if="attachment.mimeType == 'application/pdf'"
                            >mdi-file-pdf-box</v-icon
                          >
                          <a
                            :href="attachment.downloadUrl"
                            @click.prevent="downloadAttachment(attachment)"
                            >Download {{ attachment.filename }}</a
                          >
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <EmailDialog
    :chosenEmail="chosenEmail"
    :entityInfo="entityInfo"
    :entity="entity"
    :statusId="statusId"
    :portalEmails="portalEmails"
    v-if="chosenEmail.entity_id && entityInfo && $store.state.emailDialog"
  ></EmailDialog>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import EmailDialog from "@/components/CommonComponents/EmailComponent/EmailDialog.vue";
</script>
<script>
export default {
  props: {
    users: Array,
    entityInfo: Object,
    entity: String,
    toAddress: String,
    portalEmails: Array,
  },
  data() {
    return {
      emails: [],
      dataObject: {
        emails: [],
      },
      emailFilter: [],
      portalEmails: [],
      chosenEmail: {},
      userImages: {},
      userInfo: {},
      toItems: [],
      loadingSendMails: false,
      templates: [],
      templateChosen: { name: null },
    };
  },
  methods: {
    async getEmails() {
      this.emails = [];
      var queryObject = {
        select: "*,email_groups(*)",
        table: "emails",
        where: [
          { type: "eq", key: "entity_id", value: this.entityInfo.id },
          { type: "eq", key: "entity", value: this.entity },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.sort((a, b) =>
        a.date > b.date ? -1 : b.date > a.date ? 1 : 0,
      );
      response.data.forEach((item) => {
        var realDate = new Date(item.date);
        item.showDate = realDate.toLocaleString("de");
        if (this.entity == "recruiting_candidates") {
          item.topic_id = 6;
          item.email_groups = {
            use_frontend: false,
            topic: "Bewerbungen",
            activated: true,
          };
        }
      });
      this.emails = response.data;
      this.getFiles();
    },
    async getFiles() {
      if (this.entity == "employees") {
        var userId = "portal@bellcaptain.team";
      } else {
        var userId = "bewerbung@bellcaptain.team";
      }
      for (var i = 0; i < this.emails.length; i++) {
        var email = this.emails[i];
        var gmailId = email.gmail_id;
        if (email.type == "received") {
          email.attachments = await this.$store.dispatch(
            "getEmailAttachments",
            { gmailId: gmailId, userId: userId },
          );
        }
      }
    },
    async downloadAttachment(attachment) {
      if (this.entity == "employees") {
        var userId = "portal@bellcaptain.team";
      } else {
        var userId = "bewerbung@bellcaptain.team";
      }
      await this.$store.dispatch("downloadAttachment", {
        attachment: attachment,
        userId: userId,
      });
    },
    replyEmail(email) {
      this.chosenEmail = {
        thread_id: email.thread_id,
        type: "outgoing",
        subject: email.subject,
        from: email.to.replace('["', "").replace('"]', ""),
        to: [this.toAddress],
        entity_id: this.dataObject.id,
        entity: this.entity,
        body: "<p></p>",
        date: new Date(),
        oldSubject: email.subject,
        oldBody: email.body,
      };
      if (this.entity == "recruiting_candidates") {
        this.statusId = this.entityInfo.recruiting_job_candidates[0].status_id;
      } else {
        this.chosenEmail.portal = true;
      }

      this.$store.state.emailDialog = true;
    },
    saveProcessStatus(email) {
      var queryObject = {
        table: "emails",
        id: email.id,
        payload: {
          processed: email.processed,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
    },
    openNewMail() {
      this.chosenEmail = {
        type: "outgoing",
        to: [this.toAddress],
        entity_id: this.dataObject.id,
        entity: this.entity,
        body: "<p></p>",
        date: new Date(),
      };
      if (this.entity == "recruiting_candidates") {
        this.chosenEmail.from = "bewerbung@bellcaptain.team";
        this.statusId = this.entityInfo.recruiting_job_candidates[0].status_id;
      } else if (this.entity == "employees") {
        this.chosenEmail.from = "portal@bellcaptain.team";
        this.chosenEmail.friendlyName = "Bellcaptain Team";
        this.chosenEmail.portal = true;
      }
      this.$store.state.emailDialog = true;
    },
  },
  async created() {
    this.portalEmails = (
      await this.$store.dispatch("getSupabaseEntity", {
        table: "email_groups",
        where: [{ type: "is", key: "use_frontend", value: true }],
      })
    ).data;
    this.emailFilter = this.portalEmails;
    if (this.entity == "recruiting_candidates") {
      this.emailFilter.push({
        id: 6,
        use_frontend: false,
        topic: "Bewerbungen",
        activated: true,
      });
    }
    this.$store.dispatch("getUsers");
    this.userInfo = this.$store.state.userInfo;
    this.dataObject = this.entityInfo;
    this.toItems = [this.entityInfo.email];
    this.userImages = this.$store.state.userImages;
    this.getEmails();
  },
  components: { QuillEditor },
};
</script>
<style>
.full-height-card {
  height: 90vh;
  overflow: auto;
  overflow-y: auto;
}
.fixed-element {
  position: fixed;
}
</style>
