<template>
  <v-card flat v-if="report.dbObject.id">
    <v-dialog v-model="tutorial.dialog" max-width="600px">
      <v-card>
        <v-card-title
          class="bg-primary text-white d-flex justify-space-between align-center"
        >
          <span>{{ tutorial.task.task }}</span>
          <v-icon @click="tutorial.dialog = false" class="cursor-pointer"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text
          class="pa-4 px-8"
          v-html="tutorial.task.explanation"
        ></v-card-text>
      </v-card>
    </v-dialog>

    <v-card class="mx-2 my-4 pa-4" elevation="4" rounded="lg">
      <v-row class="text-h5 mb-4" align="center">
        <v-col cols="8" class="font-weight-bold text-primary">
          Leistungsnachweis - {{ shift.hotels.name }}
        </v-col>
        <v-col cols="4" class="text-right font-weight-medium">
          Datum: {{ date }}
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="my-4">
        <v-col cols="12">
          <v-list-item-group>
            <v-list-item
              v-for="task in report.dbObject.json"
              :key="task.id"
              class="mb-1"
              elevation="2"
              variant="outlined"
              :class="task.checked ? 'bg-green lighten-4' : null"
              hover
              rounded="lg"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="d-flex align-center justify-space-between"
                >
                  <div class="d-flex align-center">
                    <v-icon
                      class="mr-2 cursor-pointer"
                      :color="task.checked ? 'white' : 'primary'"
                      @click="() => openTutorial(task)"
                    >
                      mdi-help-circle
                    </v-icon>
                    <span class="font-weight-medium">{{ task.task }}</span>
                  </div>
                  <v-fade-transition mode="out-in">
                    <v-icon
                      v-if="task.checked"
                      @click="completeTask(task)"
                      size="x-large"
                      icon="mdi-checkbox-marked-circle-outline"
                      class="cursor-pointer icon-animation"
                      :color="task.checked ? 'white' : 'primary'"
                    ></v-icon>
                    <v-icon
                      v-else
                      @click="completeTask(task)"
                      size="x-large"
                      icon="mdi-checkbox-blank-circle-outline"
                      class="cursor-pointer icon-animation"
                      :color="task.checked ? 'green darken-2' : 'primary'"
                    ></v-icon>
                  </v-fade-transition>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="task.checked"
                  class="mt-2 text--secondary"
                >
                  {{ task.employee }} am {{ transformDate(task.date) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="my-4">
        <v-col cols="12" class="mb-4">
          <v-card class="pa-4" elevation="2" rounded="lg">
            <div class="font-weight-bold mb-2">
              Dienstleistung erbracht durch Bellcaptain Mitarbeiter
            </div>
            <div class="mb-4 text-dark">
              Leistung erbracht durch {{ shift.employees.name }}
            </div>
            <div class="mb-4 font-weight-medium text-dark">Unterschrift</div>
            <v-card
              variant="outlined"
              height="200px"
              class="d-flex align-center justify-center"
            >
              <v-img
                v-if="report.dbObject.is_signed"
                :src="report.dbObject.signature_employee"
                class="ma-5"
                height="100"
              ></v-img>
              <ClientOnly v-else>
                <Vue3Signature
                  ref="signature1"
                  :sigOption="state1.option"
                  :w="'1280px'"
                  :h="'200px'"
                  :disabled="state1.disabled"
                ></Vue3Signature>
              </ClientOnly>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="my-4">
        <v-col cols="12">
          <v-card class="pa-4" elevation="2" rounded="lg">
            <div class="font-weight-bold mb-2">
              Abnahme erfolgt durch Mitarbeiter des Kunden
            </div>
            <v-text-field
              v-model="hotelCheck.name"
              label="Name des Abnehmers"
              variant="outlined"
              :disabled="report.dbObject.is_signed"
            ></v-text-field>
            <v-card class="ma-4" flat>
              <v-list-item-group>
                <v-list-item
                  v-for="task in hotelCheck.tasks"
                  :key="task.id"
                  class="mb-1"
                  elevation="3"
                  :class="task.checked ? 'bg-green lighten-4' : null"
                  hover
                  rounded="lg"
                  variant="outlined"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="d-flex align-center justify-space-between"
                    >
                      <div class="d-flex align-center">
                        <span class="font-weight-medium">{{ task.task }}</span>
                      </div>
                      <v-fade-transition mode="out-in">
                        <v-icon
                          v-if="task.checked"
                          @click="setPartnerTask(task)"
                          size="x-large"
                          icon="mdi-checkbox-marked-circle-outline"
                          class="cursor-pointer icon-animation"
                          :color="task.checked ? 'white' : 'primary'"
                        ></v-icon>
                        <v-icon
                          v-else
                          @click="setPartnerTask(task)"
                          size="x-large"
                          icon="mdi-checkbox-blank-circle-outline"
                          class="cursor-pointer icon-animation"
                          :color="task.checked ? 'green darken-2' : 'primary'"
                        ></v-icon>
                      </v-fade-transition>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-card>
            <v-textarea
              label="Bemerkungen"
              :disabled="report.dbObject.is_signed"
              v-model="hotelCheck.comment"
            ></v-textarea>
            <div class="mb-4 font-weight-medium text-dark">Unterschrift</div>
            <v-card
              variant="outlined"
              height="200px"
              class="d-flex align-center justify-center"
            >
              <v-img
                v-if="report.dbObject.is_signed"
                :src="report.dbObject.signature_hotel"
                class="ma-5"
                height="100"
              ></v-img>
              <ClientOnly v-else>
                <Vue3Signature
                  ref="signature2"
                  :sigOption="state2.option"
                  :w="'1280px'"
                  :h="'200px'"
                  :disabled="state2.disabled"
                ></Vue3Signature>
              </ClientOnly>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mb-4 text-center" cols="12">
          <v-btn
            @click="saveReport"
            color="success"
            large
            disabled
            elevation="2"
            :loading="loading"
            v-if="!report.dbObject.is_signed"
          >
            Leistungsnachweis abschließen
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    reportId: {
      type: Number,
      required: true,
    },
    shift: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employeeInfo: {},
      signature1: null,
      signature2: null,
      loading: false,
      state1: {
        count: 0,
        option: {
          penColor: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255,255,255)",
        },
        disabled: false,
      },
      state2: {
        count: 0,
        option: {
          penColor: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255,255,255)",
        },
        disabled: false,
      },
      report: { dbObject: {} },
      hotel: {},
      date: "",
      tutorial: {},
      hotelCheck: {
        tasks: [
          { task: "Leistung erbracht" },
          { task: "Alle relevanten Schlüssel übergeben" },
          { task: "Kasse(n) ohne Differenz übergeben" },
        ],
        name: null,
      },
    };
  },
  methods: {
    async getReport() {
      let response = await this.$store.dispatch("getSupabaseEntity", {
        table: "hotel_performance_records",
        select: "*",
        where: [{ type: "eq", key: "id", value: this.reportId }],
      });
      this.report.dbObject = response.data[0];
      this.hotel = response.data[0].hotels;
      if (this.report.dbObject.hotel_person_info) {
        this.hotelCheck = this.report.dbObject.hotel_person_info;
      }
    },
    setPartnerTask(task) {
      if (!this.report.dbObject.is_signed) {
        task.checked = !task.checked;
      }
    },
    async completeTask(task) {
      if (!this.report.dbObject.is_signed) {
        task.checked = !task.checked;
        task.date = new Date().toISOString();
        task.employee = this.employeeInfo.name;
        let payload = {
          modified_at: new Date().toISOString(),
          json: this.report.dbObject.json,
          id: this.report.dbObject.id,
        };
        var response = await this.supabase
          .from("hotel_performance_records")
          .update(payload)
          .eq("id", this.report.dbObject.id);
      }
    },
    openTutorial(task) {
      this.tutorial.task = task;
      this.tutorial.dialog = true;
    },
    transformDate(date) {
      var onlyDate = date.split("T")[0];
      var time = date.split("T")[1].split(":");
      time = parseInt(time[0], 10) + 2 + ":" + time[1];
      date =
        onlyDate.split("-")[2] +
        "." +
        onlyDate.split("-")[1] +
        "." +
        onlyDate.split("-")[0] +
        " " +
        time;
      return date;
    },
    async saveReport() {
      this.loading = true;
      let payload = {
        modified_at: new Date().toISOString(),
        json: this.report.dbObject.json,
        signature_employee: this.$refs.signature1.save("image/jpeg"),
        signature_hotel: this.$refs.signature2.save("image/jpeg"),
        hotel_person_info: this.hotelCheck,
        is_signed: true,
      };
      var response = await this.supabase
        .from("hotel_performance_records")
        .update(payload)
        .eq("id", this.report.dbObject.id);
      this.loading = false;
      this.getReport();
    },
  },
  async mounted() {
    this.getReport();
    let dateParts = this.shift.date.split("-");
    this.date = dateParts[2] + "." + dateParts[1] + "." + dateParts[0];
  },
};
</script>

<style scoped>
.icon-animation-enter-active,
.icon-animation-leave-active {
  transition: transform 0.3s ease;
}
.icon-animation-enter, .icon-animation-leave-to /* .icon-animation-leave-active in <2.1.8 */ {
  transform: rotate(360deg);
}
</style>
