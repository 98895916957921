<template>
  <v-row class="mx-4 my-4">
    <v-col cols="12" align="left">
      <v-btn
        class="my-1"
        @click="
          panels = null;
          startEvaluation();
        "
      >
        Neue Evaluation erstellen
      </v-btn>
    </v-col>
    <v-expansion-panels v-model="panels">
      <v-expansion-panel
        @click="loadEvaluation(evaluation)"
        v-for="evaluation in evaluations"
        :bg-color="evaluationClass(evaluation)"
      >
        <v-expansion-panel-title>
          <v-card-text>
            <v-row>
              <v-col align="left">
                <strong>{{ evaluation.recruiting_evaluations.name }}</strong>
              </v-col>
              <v-col align="right">
                <v-chip prepend-icon="mdi-account-circle" size="small">
                  {{ evaluation.public_users.full_name }}
                </v-chip>
                <v-chip prepend-icon="mdi-calendar" size="small">
                  {{ evaluation.showDate }}
                </v-chip>
                <v-icon
                  class="ml-2"
                  v-if="evaluation.is_positive != null"
                  :icon="
                    evaluation.is_positive ? 'mdi-thumb-up' : 'mdi-thumb-down'
                  "
                ></v-icon>
              </v-col>
              <v-col cols="12">
                <template v-for="element in evaluation.content">
                  <div v-if="element.is_in_candidate_profile">
                    <span>{{ element.question + ": " }}</span>
                    <v-icon
                      v-for="icon in element.value"
                      v-if="element.type == 'Bewertung'"
                      >mdi-star</v-icon
                    >
                    <span
                      v-if="
                        element.type == 'Dropdown' || element.type == 'Zeile'
                      "
                      >{{ element.value }}</span
                    >
                    <v-icon
                      v-if="element.type == 'Ja/Nein'"
                      :icon="
                        element.value
                          ? 'mdi-checkbox-marked'
                          : 'mdi-checkbox-blank-outline'
                      "
                    ></v-icon>
                  </div>
                </template>
              </v-col>
              <v-col cols="12">
                {{ evaluation.summary }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-expansion-panel-title>
        <v-expansion-panel-text v-if="activeEvaluation.activated">
          <v-card class="py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" v-if="!activeEvaluation.id">
                  <v-autocomplete
                    :items="evaluationTemplates"
                    label="Vorlage"
                    item-title="name"
                    return-object
                    v-model="activeEvaluation.template"
                    @update:modelValue="loadTemplate()"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  v-for="element in activeEvaluation.content_json"
                  class="my-n3"
                >
                  <h3
                    class="text-h5 mt-2 mb-1"
                    v-if="element.type == 'Überschrift'"
                  >
                    <strong>{{ element.question }}</strong>
                  </h3>
                  <v-text-field
                    :label="element.question"
                    variant="outlined"
                    density="compact"
                    v-if="element.type == 'Zeile'"
                    v-model="element.value"
                  ></v-text-field>
                  <v-textarea
                    :label="element.question"
                    variant="outlined"
                    density="compact"
                    v-if="element.type == 'Text'"
                    v-model="element.value"
                  ></v-textarea>
                  <v-row v-if="element.type == 'Ja/Nein'">
                    <v-col cols="12">
                      <v-checkbox
                        variant="outlined"
                        density="compact"
                        :label="element.question"
                        v-if="element.type == 'Ja/Nein'"
                        v-model="element.value"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="element.type == 'Bewertung'">
                    <v-col cols="6">{{ element.question }}</v-col>
                    <v-col cols="6">
                      <v-rating
                        class="mb-4"
                        variant="outlined"
                        density="compact"
                        v-if="element.type == 'Bewertung'"
                        v-model="element.value"
                      ></v-rating>
                    </v-col>
                  </v-row>
                  <v-select
                    v-if="element.type == 'Dropdown'"
                    :label="element.question"
                    variant="outlined"
                    density="compact"
                    :items="element.items"
                    item-title="content"
                    item-value="content"
                    v-model="element.value"
                  ></v-select>
                </v-col>
                <v-col cols="5"></v-col>
                <v-col cols="4" align="center" class="my-n4">
                  <v-row class="align-center">
                    <v-col cols="auto" class="mb-4">
                      <v-icon color="red">mdi-thumb-down</v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <v-switch
                        :indeterminate="activeEvaluation.is_positive == null"
                        v-model="activeEvaluation.is_positive"
                      ></v-switch>
                    </v-col>
                    <v-col cols="auto" class="mb-4">
                      <v-icon color="success">mdi-thumb-up</v-icon>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5"></v-col>

                <v-col cols="12" align="center">
                  <v-btn
                    :loading="loading"
                    v-if="activeEvaluation.id"
                    class="mr-1"
                    color="success"
                    @click="updateEvaluation()"
                  >
                    Speichern
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    v-else
                    class="mr-1"
                    color="success"
                    @click="createEvaluation()"
                  >
                    Erstellen
                  </v-btn>
                  <v-btn
                    color="error"
                    @click="activeEvaluation.activated = false"
                    >Abbrechen</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row v-if="activeEvaluation.activated && createNew" class="mt-4">
      <v-col cols="12" v-if="!activeEvaluation.id">
        <v-autocomplete
          :items="evaluationTemplates"
          label="Vorlage"
          item-title="name"
          return-object
          v-model="activeEvaluation.template"
          @update:modelValue="loadTemplate()"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        v-for="element in activeEvaluation.content_json"
        class="my-n3"
      >
        <h3 class="text-h5 mt-2 mb-1" v-if="element.type == 'Überschrift'">
          <strong>{{ element.question }}</strong>
        </h3>
        <v-text-field
          :label="element.question"
          variant="outlined"
          density="compact"
          v-if="element.type == 'Zeile'"
          v-model="element.value"
        ></v-text-field>
        <v-textarea
          :label="element.question"
          variant="outlined"
          density="compact"
          v-if="element.type == 'Text'"
          v-model="element.value"
        ></v-textarea>
        <v-row v-if="element.type == 'Ja/Nein'">
          <v-col cols="12">
            <v-checkbox
              variant="outlined"
              density="compact"
              :label="element.question"
              v-if="element.type == 'Ja/Nein'"
              v-model="element.value"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="element.type == 'Bewertung'">
          <v-col cols="6">{{ element.question }}</v-col>
          <v-col cols="6">
            <v-rating
              class="mb-4"
              variant="outlined"
              density="compact"
              v-if="element.type == 'Bewertung'"
              v-model="element.value"
            ></v-rating>
          </v-col>
        </v-row>
        <v-select
          v-if="element.type == 'Dropdown'"
          :label="element.question"
          variant="outlined"
          density="compact"
          :items="element.items"
          item-title="content"
          item-value="content"
          v-model="element.value"
        ></v-select>
      </v-col>
      <v-col cols="5"></v-col>
      <v-col cols="4" align="center" class="my-n4">
        <v-row class="align-center">
          <v-col cols="auto" class="mb-4">
            <v-icon color="red">mdi-thumb-down</v-icon>
          </v-col>
          <v-col cols="auto">
            <v-switch
              :indeterminate="activeEvaluation.is_positive == null"
              v-model="activeEvaluation.is_positive"
            ></v-switch>
          </v-col>
          <v-col cols="auto" class="mb-4">
            <v-icon color="success">mdi-thumb-up</v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5"></v-col>
      <v-col cols="12" align="center">
        <v-btn
          :loading="loading"
          v-if="activeEvaluation.id"
          class="mr-1"
          color="success"
          @click="updateEvaluation()"
        >
          Speichern
        </v-btn>
        <v-btn
          :loading="loading"
          v-else
          class="mr-1"
          color="success"
          @click="createEvaluation()"
        >
          Erstellen
        </v-btn>
        <v-btn color="error" @click="activeEvaluation.activated = false"
          >Abbrechen</v-btn
        >
      </v-col>
    </v-row>
  </v-row>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
const props = defineProps({
  candidateInfo: Object,
  users: Array,
});
</script>

<script>
export default {
  data() {
    return {
      evaluations: [],
      activeEvaluation: {
        activated: false,
        template: null,
        content_json: [],
        is_positive: null,
      },
      panels: [],
      createNew: false,
      evaluationTemplates: [],
      loading: false,
    };
  },
  methods: {
    evaluationClass(evaluation) {
      return evaluation.is_positive === null
        ? null
        : evaluation.is_positive
        ? "green-lighten-5"
        : "red-lighten-5";
    },
    async getEvaluations() {
      var queryObject = {
        select: "*,recruiting_evaluations(name),public_users(*)",
        table: "recruiting_candidate_evaluations",
        where: [
          { type: "eq", key: "candidate_id", value: this.candidateInfo.id },
        ],
        order: {
          key: "created_at",
          ascending: false,
        },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      for (var i = 0; i < response.data.length; i++) {
        response.data[i].showDate = await this.$store.dispatch(
          "formatDate",
          new Date(response.data[i].created_at),
        );
      }
      response.data.forEach(function (item) {
        item.content.forEach(function (question) {
          if (question.question == "Zusammenfassung") {
            item.summary = question.value;
          }
        });
      });
      this.evaluations = response.data;
    },
    async getEvaluationTemplates() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_evaluations",
      });
      this.evaluationTemplates = response.data;
    },
    startEvaluation() {
      this.activeEvaluation.id = null;
      this.activeEvaluation.activated = true;
      this.createNew = true;
      this.activeEvaluation.content_json = [];
    },
    checkStatus() {
      var statusId = this.candidateInfo.statusId;
      if (statusId == 9) {
        this.startEvaluation();
        var evaluation = this.evaluationTemplates.find(
          (item) => item.status_id === statusId,
        );
        this.activeEvaluation.template = evaluation;
        this.loadTemplate();
      }
    },
    loadEvaluation(evaluation) {
      this.activeEvaluation = {
        id: null,
        template: {},
        content_json: [],
        is_positive: null,
      };
      this.activeEvaluation.id = evaluation.id;
      this.activeEvaluation.activated = true;
      this.activeEvaluation.content_json = evaluation.content;
      this.activeEvaluation.is_positive = evaluation.is_positive;
    },
    loadTemplate() {
      this.activeEvaluation.id = null;
      this.activeEvaluation.activated = true;
      this.activeEvaluation.content_json =
        this.activeEvaluation.template.content_json;
    },
    async createEvaluation() {
      this.loading = true;
      var queryObject = {
        table: "recruiting_candidate_evaluations",
        payload: {
          evaluation_id: this.activeEvaluation.template.id,
          candidate_id: this.candidateInfo.id,
          content: this.activeEvaluation.content_json,
          owner_id: this.$store.state.userInfo.id,
          is_positive: this.activeEvaluation.is_positive,
        },
      };
      this.activeEvaluation.id = await this.$store.dispatch(
        "createSupabaseEntity",
        queryObject,
      );
      this.getEvaluations();
      this.activeEvaluation.activated = false;
      this.createNew = false;
      this.loading = false;
    },
    async updateEvaluation() {
      this.loading = true;
      var queryObject = {
        table: "recruiting_candidate_evaluations",
        payload: {
          evaluation_id: this.activeEvaluation.template.id,
          candidate_id: this.candidateInfo.id,
          content: this.activeEvaluation.content_json,
          is_positive: this.activeEvaluation.is_positive,
        },
        id: this.activeEvaluation.id,
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.getEvaluations();
      this.activeEvaluation.activated = false;
      this.createNew = false;

      this.loading = false;
    },
  },
  async created() {
    await this.getEvaluations();
    await this.getEvaluationTemplates();
    this.checkStatus();
  },
};
</script>

<style>
.noteHeight {
  overflow: auto;
  overflow-y: auto;
}
</style>
