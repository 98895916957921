<template>
  <v-col
    align="center"
    class="mt-n2"
    v-if="!editMode && tasks.length == 0"
    cols="12"
  >
    <v-btn @click="addEditItem()" color="success">
      <v-icon>mdi-plus</v-icon>Add Task
    </v-btn>
  </v-col>
  <v-card v-bind="props" rounded="xl" v-else>
    <v-card-title>Tasks</v-card-title>
    <v-row class="pa-10">
      <v-fab-transition v-for="task in tasks" class="my-n3">
        <v-col cols="12" :sm="entity ? '12' : '4'" v-show="!task.is_completed">
          <v-hover
            v-slot="{ isHovering, props }"
            open-delay="300"
            close-delay="100"
          >
            <v-card
              :color="
                task.owner_id == $store.state.userInfo.id
                  ? null
                  : 'green-accent-1'
              "
              v-bind="props"
              rounded="xl"
              class="ma-1"
              variant="elevated"
              elevation="4"
              :elevation="isHovering ? '10' : '0'"
            >
              <v-card-text style="color: black">
                <v-row>
                  <v-col
                    cols="12"
                    sm="7"
                    :align="$vuetify.display.mobile ? 'center' : 'left'"
                    class="mb-n4"
                    v-if="!entity"
                  >
                    <div>
                      <v-btn
                        size="x-small"
                        icon
                        :color="task.color"
                        class="mr-2"
                      >
                        <v-icon :icon="task.icon"></v-icon>
                      </v-btn>
                      <router-link
                        v-if="task.employee_id && !entity"
                        :to="'/Mitarbeiter/' + task.employee_id"
                        ><strong>{{ task.employees.name }}</strong></router-link
                      >
                      <router-link
                        v-else-if="task.candidate_id && !entity"
                        :to="'/Kandidaten/' + task.candidate_id"
                        ><strong>{{
                          task.recruiting_candidates.first_name +
                          " " +
                          task.recruiting_candidates.last_name
                        }}</strong></router-link
                      >
                      <strong v-else>General</strong>
                    </div>
                  </v-col>
                  <v-col
                    :sm="entity ? '12' : '5'"
                    cols="12"
                    :align="$vuetify.display.mobile ? 'center' : 'right'"
                  >
                    <div>{{ task.owner }}</div>
                    <div v-if="task.due_date">
                      <v-icon color="green-darken-3" class="mr-1"
                        >mdi-calendar</v-icon
                      >
                      <span class="text-caption">{{ task.showDueDate }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-chip size="x-small" v-for="listener in task.listeners">{{
                      userObject[listener].full_name
                    }}</v-chip>
                  </v-col>
                  <v-col
                    align="left"
                    cols="2"
                    v-if="!task.edit"
                    class="mb-n4 mt-n3"
                    style="padding-top: 14px"
                  >
                    <v-checkbox-btn
                      v-model="task.is_completed"
                      @click="
                        task.is_completed = true;
                        completeTask(task);
                      "
                    ></v-checkbox-btn>
                  </v-col>
                  <v-col
                    :cols="task.edit ? '12' : '10'"
                    :class="isHovering ? 'mb-n4' : 'mb-n2'"
                    align="left"
                  >
                    <template v-if="!task.edit">
                      <div>{{ task.title }}</div>
                      <div>
                        <v-expand-transition>
                          <v-row v-show="isHovering" class="my-1">
                            <v-col cols="12">
                              <div v-if="task.note">
                                <v-icon color="green-darken-3" class="mr-1"
                                  >mdi-note</v-icon
                                >
                                <span class="text-caption">{{
                                  task.note
                                }}</span>
                              </div>
                              <v-col>
                                <v-btn
                                  size="small"
                                  @click="
                                    editMode = true;
                                    task.edit = true;
                                  "
                                  color="indigo-darken-1"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </v-col>
                            </v-col>
                          </v-row>
                        </v-expand-transition>
                      </div>
                    </template>
                    <template v-else>
                      <v-text-field
                        label="Title"
                        density="compact"
                        v-model="task.title"
                      ></v-text-field>
                      <v-textarea
                        label="Note"
                        density="compact"
                        v-model="task.note"
                      ></v-textarea>
                      <v-text-field
                        label="Fällig am"
                        type="datetime-local"
                        density="compact"
                        v-model="task.due_date"
                      ></v-text-field>
                      <v-autocomplete
                        label="Bearbeiter"
                        :items="activeUsers"
                        item-title="full_name"
                        item-value="id"
                        v-model="task.listeners"
                        multiple
                        chips
                      ></v-autocomplete>
                    </template>
                  </v-col>
                  <v-col cols="12" align="center" v-if="task.due">
                    <v-icon color="red">mdi-alert</v-icon>
                  </v-col>
                  <v-row>
                    <v-col v-if="task.edit">
                      <v-btn
                        icon
                        v-if="task.edit && task.title"
                        class="mb-1"
                        flat
                        @click="
                          saveTask(task);
                          closeEditMode(task);
                        "
                      >
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        class="mb-1"
                        flat
                        @click="closeEditMode(task)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-row>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-fab-transition>
      <v-col align="center" class="mt-n2" v-if="!editMode" cols="12">
        <v-btn @click="addEditItem()" color="success">
          <v-icon>mdi-plus</v-icon>Add Task
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
const props = defineProps(["entityId", "entity", "entityInfo"]);
</script>
<script>
export default {
  data() {
    return {
      tasks: [],
      userId: null,
      ownerId: null,
      users: [],
      editMode: false,
      mobile: false,
      activeUsers: [],
      userObject: {},
    };
  },
  methods: {
    async addEditItem() {
      var now = new Date();
      now.setHours(now.getHours() + 3);
      this.editMode = true;
      var task = {
        title: null,
        note: null,
        owner_id: this.ownerId,
        todo_owner_id: this.userId,
        due_date: now.toISOString().slice(0, 16),
        edit: true,
      };
      this.tasks.push(task);
    },
    async completeTask(task) {
      var queryObject = {
        table: "tasks",
        id: task.id,
        payload: {
          is_completed: true,
          finished_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      if (this.entity == "candidate_id") {
        var queryObject = {
          table: "recruiting_job_candidates",
          where: [
            { type: "eq", key: "candidate_id", value: task.candidate_id },
          ],
        };
        var application = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        var jobCandidateId = application.data[0].id;
        var queryObject = {
          table: "notes",
          payload: {
            job_candidate_id: jobCandidateId,
            owner_id: this.$store.state.userInfo.id,
            action: `Task "${task.title}" wurde abgehakt!`,
            text: task.note,
          },
        };
        await this.$store.dispatch("createSupabaseEntity", queryObject);
        this.$store.state.candidateChangeCheck = true;
      }
      if (this.entity == "employee_id") {
        var queryObject = {
          table: "notes",
          payload: {
            employee_id: this.entityInfo.id,
            owner_id: this.$store.state.userInfo.id,
            action: `Task "${task.title}" wurde abgehakt!`,
            text: task.note,
          },
        };
        await this.$store.dispatch("createSupabaseEntity", queryObject);
        this.$store.state.employeeChangeCheck = true;
      }
    },
    async getTasks() {
      var checkDate = new Date();
      checkDate.setDate(checkDate.getDate() + 7);
      var today = new Date();
      today.setHours(1);
      var queryObject = {
        select:
          "*,recruiting_candidates(first_name,last_name),recruiting_jobs(title),employees(name),public_users!tasks_todo_owner_id_fkey(full_name)",
        table: "tasks",
        where: [
          {
            type: "or",
            key: "owner_id",
            value: `owner_id.eq.${this.userId},listeners.cs.{${this.userId}}`,
          },
          { type: "is", key: "is_completed", value: false },
        ],
        order: { key: "due_date" },
      };
      if (this.entity) {
        queryObject.where.push({
          type: "eq",
          key: this.entity,
          value: this.entityId,
        });
      } else {
        queryObject.where.push({
          type: "lte",
          key: "due_date",
          value: checkDate.toISOString(),
        });
      }
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      var users = await this.$store.dispatch("getUsers");
      for (var i = 0; i < response.data?.length; i++) {
        var item = response.data[i];
        var date = response.data[i].due_date;
        response.data[i].owner = users.find(
          (userItem) => userItem.id == item.owner_id,
        ).full_name;
        response.data[i].due =
          new Date(date).getTime() < new Date(today).getTime();
        if (date) {
          response.data[i].showDueDate = new Date(date).toLocaleString(
            "de-DE",
            { timeZone: "UTC" },
          );
          response.data[i].due_date = response.data[i].due_date.split("+")[0];
        }
        if (item.candidate_id) {
          item.entity = "Candidate";
          item.icon = "mdi-account-group";
          item.color = "indigo-darken-3";
        } else if (item.company_id) {
          item.entity = "Company";
          item.icon = "mdi-domain";
          item.color = "orange-darken-3";
        } else if (item.partner_id) {
          item.entity = "Partner";
          item.icon = "mdi-account-tie";
          item.color = "green-darken-4";
        } else if (item.job_id) {
          item.entity = "Job";
          item.icon = "mdi-briefcase";
          item.color = "red-darken-3";
        } else if (item.pool_id) {
          item.entity = "Pool";
          item.icon = "mdi-account-search";
          item.color = "purple-darken-3";
        } else {
          item.entity = "General";
          item.icon = "mdi-clipboard";
          item.color = "blue-grey-darken-2";
        }
      }
      this.tasks = response.data;
    },
    async saveTask(task) {
      var taskDuplicate = JSON.parse(JSON.stringify(task));
      if (this.entity) {
        taskDuplicate[this.entity] = this.entityId;
      }
      var payload = JSON.parse(JSON.stringify(taskDuplicate));
      delete payload.edit;
      delete payload.color;
      delete payload.due;
      delete payload.entity;
      delete payload.icon;
      delete payload.public_users;
      delete payload.recruiting_candidates;
      delete payload.recruiting_jobs;
      delete payload.showDueDate;
      delete payload.owner;
      delete payload.employees;
      if (taskDuplicate.id) {
        var queryObject = {
          table: "tasks",
          id: taskDuplicate.id,
          payload: payload,
        };
        payload.modified_at = new Date().toISOString();
        await this.$store.dispatch("updateSupabaseEntity", queryObject);
      } else {
        var queryObject = {
          table: "tasks",
          payload: payload,
        };
        var id = await this.$store.dispatch(
          "createSupabaseEntity",
          queryObject,
        );
        if (this.entityInfo) {
          var queryObject = {
            table: "notes",
            payload: {
              owner_id: this.$store.state.userInfo.id,
              action: `Task "${payload.title}" erstellt`,
              text: payload.note,
            },
          };
          if (this.entityInfo.recruiting_job_candidates) {
            queryObject.payload.job_candidate_id =
              this.entityInfo.recruiting_job_candidates[0].id;
            await this.$store.dispatch("createSupabaseEntity", queryObject);
            this.$store.state.candidateChangeCheck = true;
          } else if (this.entity == "employee_id") {
            queryObject.payload.employee_id = this.entityInfo.id;
            await this.$store.dispatch("createSupabaseEntity", queryObject);
            this.$store.state.employeeChangeCheck = true;
          }
        }
        task.id = id;
      }
      this.getTasks();
    },
    async closeEditMode(task) {
      task.edit = false;
      this.editMode = false;
      if (!task.id) {
        this.tasks.pop();
      }
    },
  },
  async created() {
    var users = await this.$store.dispatch("getUsers");
    var userObject = {};
    users.forEach(function (item) {
      userObject[item.id] = item;
    });
    this.userObject = userObject;
    this.users = users;
    this.activeUsers = this.users.filter((item) => item.active);
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "get_office_users",
    });
    this.activeUsers = response.data;
    for (var i = 0; i < users.length; i++) {
      if (users[i].full_name == this.$store.state.chosenUser) {
        this.userId = users[i].id;
      }
      if (users[i].email == this.$store.state.username) {
        this.ownerId = users[i].id;
      }
    }
    this.userId = this.$store.state.userInfo.id;
    this.ownerId = this.$store.state.userInfo.id;

    this.getTasks();
  },
};
</script>
