<template>
  <v-btn @click="view = 'senior'" :color="view == 'senior' ? 'green' : null"
    >Vorlage Senior</v-btn
  >
  <v-btn @click="view = 'clerk'" :color="view == 'clerk' ? 'green' : null"
    >Vorlage Clerk</v-btn
  >
  <template v-if="view == 'senior'">
    <draggable
      v-model="hotelEditObject.performance_record_template_senior"
      item-key="id"
      class="ma-4"
    >
      <template #item="{ element, index }">
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="1" align="center">
                <v-icon size="x-large" @click="">mdi-drag</v-icon>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  label="Aufgabe"
                  v-model="element.task"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-expansion-panels>
                  <v-expansion-panel title="Erklärung">
                    <v-expansion-panel-text>
                      <v-card class="quill-container">
                        <QuillEditor
                          toolbar="essential"
                          v-model:content="element.explanation"
                          contentType="html"
                        />
                      </v-card>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="1" align="center">
                <v-btn color="red" @click="removeElement(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </draggable>
  </template>
  <template v-if="view == 'clerk'">
    <draggable
      v-model="hotelEditObject.performance_record_template_clerk"
      item-key="id"
      class="ma-4"
    >
      <template #item="{ element, index }">
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="1" align="center">
                <v-icon size="x-large" @click="">mdi-drag</v-icon>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  label="Aufgabe"
                  v-model="element.task"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-expansion-panels>
                  <v-expansion-panel title="Erklärung">
                    <v-expansion-panel-text>
                      <v-card class="quill-container">
                        <QuillEditor
                          toolbar="essential"
                          v-model:content="element.explanation"
                          contentType="html"
                        />
                      </v-card>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="1" align="center">
                <v-btn color="red" @click="removeElement(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </draggable>
  </template>
  <v-bottom-navigation>
    <v-btn color="green" @click="addElement()">
      <v-icon>mdi-plus</v-icon><span>Hinzufügen</span>
    </v-btn>

    <v-btn @click="savePerformanceRecord()" color="blue" :loading="loading">
      <v-icon>mdi-content-save</v-icon><span>Speichern</span></v-btn
    >
  </v-bottom-navigation>
</template>

<script setup>
import draggable from "vuedraggable";
import { QuillEditor } from "@vueup/vue-quill";
const props = defineProps({
  hotelObject: Object,
});
</script>
<script>
export default {
  data() {
    return {
      hotelId: null,
      hotelEditObject: {},
      tab: null,
      loading: false,
      view: "senior",
    };
  },
  methods: {
    addElement() {
      if (this.view == "senior") {
        this.hotelEditObject.performance_record_template_senior.push({
          task: null,
          explanation: null,
        });
      } else if (this.view == "clerk") {
        this.hotelEditObject.performance_record_template_clerk.push({
          task: null,
          explanation: null,
        });
      }

      window.scroll({ top: 10000000000000, behavior: "smooth" });
    },
    removeElement(index) {
      if (this.view == "senior") {
        this.hotelEditObject.performance_record_template_senior.splice(
          index,
          1,
        );
      } else if (this.view == "clerk") {
        this.hotelEditObject.performance_record_template_clerk.splice(index, 1);
      }
    },
    async savePerformanceRecord() {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(this.hotelEditObject));
      delete payload.cities;
      delete payload.employees_hotels;
      if (payload.id) {
        var queryObject = {
          table: "hotels",
          payload: payload,
          id: payload.id,
        };
        await this.$store.dispatch("updateSupabaseEntity", queryObject);
      } else {
        var queryObject = {
          table: "hotels",
          payload: payload,
        };
        await this.$store.dispatch("createSupabaseEntity", queryObject);
      }
      this.$store.state.hotelView.drawer = false;
      this.loading = false;
    },
  },
  async created() {
    this.hotelEditObject = this.hotelObject;
  },
};
</script>
