<template>
  <View :candidateId="$route.params.id"></View>
</template>

<script setup>
import View from "@/components/Candidates/View.vue";
</script>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  async created() {},
};
</script>
