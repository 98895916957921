<template>
  <h3 class="ma-2">Quellen</h3>
  <v-card>
    <v-card-text class="mt-3">
      <v-row v-for="(source, index) in sources" class="my-n10">
        <v-col cols="1" class="mt-1">
          <v-chip>{{ source.count }}</v-chip>
        </v-col>
        <v-col cols="11">
          <v-text-field
            variant="outlined"
            density="compact"
            :append-icon="source.count == 0 ? 'mdi-delete' : null"
            @click:append="deleteSource(source, index)"
            v-model="source.name"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-col align="center">
      <v-btn @click="sources.push({ name: null, count: 0 })">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn @click="saveSources()" class="ml-2" :loading="loading">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-col>
  </v-card>
</template>

<script setup>
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      sources: [],
    };
  },
  methods: {
    async getSources() {
      var queryObject = {
        table: "recruiting_sources",
        select: "*,recruiting_job_candidates(id)",
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach(function (item) {
        item.count = item.recruiting_job_candidates.length;
      });
      this.sources = response.data;
    },
    async deleteSource(source, index) {
      if (source.id) {
        await this.$store.dispatch("deleteSupabaseEntity", {
          id: source.id,
          table: "recruiting_sources",
        });
      }
      this.sources.splice(index, 1);
    },
    async saveSources() {
      var table = "recruiting_sources";
      var payload = this.sources;
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        delete payload[i].count;
        delete payload[i].recruiting_job_candidates;
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      this.loading = false;
    },
  },
  async mounted() {
    this.getSources();
  },
};
</script>
