<template>
  <input
    type="file"
    ref="fileInput"
    style="display: none"
    @change="handleFileChange"
  />
  <v-btn @click="parseFile" :loading="loading">Upload CV</v-btn>
</template>

<script setup>
const props = defineProps({
  candidateInfo: Object,
});
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      parsedData: {},
    };
  },
  methods: {
    async parseCVSuperParser(file) {
      const apiKey = "VOPJCENvVJ3IwRXAXb9BOaaot7YqseHd1Q3DePJ0";

      const formData = new FormData();
      formData.append("file_name", file);

      const headers = {
        "X-API-Key": apiKey,
      };

      try {
        const response = await fetch("https://api.superparser.com/parse", {
          method: "POST",
          headers: headers,
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          var candidateData = await this.transformData(data.data);
          // Handle the parsed resume data here
        } else {
          // Handle error response
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        // Handle network errors
        console.error("Network error:", error);
      }
      this.uploadDocument(file, candidateData);
    },
    async parseCV(file) {
      var apiKey = "";
      const formData = new FormData();
      formData.append("file", file);

      const options = {
        method: "POST",
        headers: {
          "X-BLOBR-KEY": apiKey,
          "Content-Type": "application/octet-stream",
        },
        body: file,
      };

      try {
        const response = await fetch(
          "https://api.cvparser.ai/parse/from_file",
          options,
        );
        const data = await response.json();
        this.transformDataOldParser(data.data);
        this.loading = false;
        // Handle the response as needed
      } catch (err) {
        console.error(err);
        this.loading = false;
        // Handle error if fetch fails
      }
      this.uploadDocument(file);
    },

    async transformData(data) {
      var candidateData = {};
      if (data.name) {
        candidateData.first_name = data.name.first_name;
        candidateData.last_name = data.name.last_name;
      }
      candidateData.email = data.email[0].email;
      candidateData.phone = data.phone[0] ? data.phone[0].phone : null;
      var experiences = data.employer;
      if (experiences.length > 0) {
        candidateData.current_position = experiences[0].role;
        candidateData.current_company = experiences[0].company_name;
      }
      return candidateData;
    },

    async transformDataOldParser(data) {
      var candidateData = {};
      var profile = data.profile;
      candidateData.gender = profile.basics.gender == "male" ? true : false;
      candidateData.first_name = profile.basics.first_name;
      candidateData.last_name = profile.basics.last_name;
      candidateData.email = profile.basics.emails[0];
      candidateData.phone = profile.basics.phone_numbers[0];
      var dateOfBirth = profile.basics.date_of_birth;
      if (dateOfBirth.year) {
        if (dateOfBirth.month < 10) {
          dateOfBirth.month = "0" + dateOfBirth.month;
        }
        if (dateOfBirth.day < 10) {
          dateOfBirth.day = "0" + dateOfBirth.day;
        }
        candidateData.birth_date =
          dateOfBirth.year + "-" + dateOfBirth.month + "-" + dateOfBirth.day;
      }

      var address = profile.basics.address;
      if (address) {
        var city =
          "%" + address.split(" ")[address.split(" ").length - 1] + "%";
        var queryObject = {
          table: "all_german_cities",
          where: [{ type: "ilike", key: "name", value: city }],
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        if (response.data[0]) {
          candidateData.city = response.data[0].name;
        }
      }
      var experiences = profile.professional_experiences;
      var currentJob = experiences.find((item) => item.is_current);
      if (currentJob) {
        candidateData.current_position = currentJob.title;
        candidateData.current_company = currentJob.company;
      }
      var languages = profile.languages;
      for (var i = 0; i < languages.length; i++) {
        var item = languages[i];
        if (item.name == "Deutsch" || item.name == "German") {
          candidateData.german_language_level = item.fluency;
        }
        if (item.name == "Englisch" || item.name == "English") {
          candidateData.english_language_level = item.fluency;
        }
      }
      this.$store.state.cvDataObject = candidateData;
    },

    async parseFile() {
      const fileInput = this.$refs.fileInput;
      // Trigger the file input to select a file
      fileInput.click();
    },

    async handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.loading = true;
        //await this.parseCV(file);
        this.uploadDocument(file, this.candidateInfo);
      }
    },
    async uploadDocument(file) {
      var queryObject = {
        bucket: "candidates",
        filePath: this.candidateInfo.id + "/" + file.name,
        file: file,
      };
      await this.$store.dispatch("uploadStorageFile", queryObject);
      this.$store.state.cvDataObject = this.candidateInfo;
      this.loading = false;
    },
  },
};
</script>
