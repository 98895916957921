<template>
  <v-row>
    <v-col cols="12" v-for="document in chosenRole.documents">
      <v-card elevation="4" variant="outlined">
        <v-card-title>{{ document.name }}</v-card-title>
        <v-card-text>
          <v-row class="mb-n8">
            <v-col cols="4">
              <div class="text-h6">Anzeigen</div>
              <v-checkbox
                color="blue-darken-1"
                class="my-n3"
                v-for="check in document.rights.show"
                :label="check.label"
                v-model="check.value"
                @change="updateRights(document, check)"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <div class="text-h6">Vorschlagen</div>
              <v-checkbox
                color="blue-darken-1"
                class="my-n3"
                v-for="check in document.rights.suggest"
                :label="check.label"
                v-model="check.value"
                @change="updateRights(document, check)"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <div class="text-h6">Bearbeiten</div>
              <v-checkbox
                color="blue-darken-1"
                class="my-n3"
                v-for="check in document.rights.edit"
                :label="check.label"
                v-model="check.value"
                @change="updateRights(document, check)"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
const props = defineProps({
  chosenRole: Object,
  index: Number,
});
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      roles: [],
      users: [],
    };
  },
  methods: {
    async updateRights(document, check) {
      var payload = {
        role_id: this.chosenRole.id,
        document_category_id: document.id,
      };
      payload[check.key] = check.value;
      this.$store.dispatch("upsertSupabaseEntity", {
        table: "public_user_role_document_matching",
        payload,
      });
    },
  },
  async created() {},
};
</script>
