<template>
  <v-card class="ma-2 px-2" elevation="5" variant="outlined">
    <v-card-title>Erreicht</v-card-title>
    <v-card-text>
      <v-col align="center">
        <v-btn color="success" @click="sendAvailability(true)">Erreicht</v-btn>
        <v-btn color="error" @click="sendAvailability(false)"
          >Nicht erreicht</v-btn
        >
      </v-col></v-card-text
    >
  </v-card>
</template>

<script setup>
const props = defineProps({
  candidateInfo: Object,
  emailObject: Object,
  application: Object,
});
</script>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    async sendAvailability(available) {
      if (available) {
        var payload = {
          action: `Kandidat wurde erreicht`,
          activity_id: 16,
        };
      } else {
        var payload = {
          action: `Kandidat wurde nicht erreicht`,
          activity_id: 17,
        };
      }
      payload.job_candidate_id = this.application.id;
      payload.owner_id = this.$store.state.userInfo.id;
      var queryObject = {
        table: "notes",
        payload: payload,
      };
      await this.$store.dispatch("createSupabaseEntity", queryObject);
      this.$store.state.candidateChangeCheck = true;
    },
  },
};
</script>
