// Utilities
import { supabase } from "../supabase";

export default {
  actions: {
    async checkAndPerformHourlySpeedTest(context) {
      const lastTestTime = localStorage.getItem("lastSpeedTestTime");
      const currentTime = Date.now();
      // Check if an hour has passed since the last speed test
      if (!lastTestTime || currentTime - lastTestTime >= 300000) {
        // 3600000 ms = 1 hour
        context.dispatch("speedTestBackground");
        localStorage.setItem("lastSpeedTestTime", currentTime);
      }
    },
    async speedTestBackground(context) {
      var payload = {};
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      const user = context.rootState.userInfo;

      payload.id = await context.dispatch("createSupabaseEntity", {
        table: "speed_test",
        payload: {
          user_id: user.id,
          ip_address: data.ip,
        },
      });
      var tests = [
        {
          key: "frontend",
          duration: 0,
        },
        {
          key: "backend",
          duration: 0,
        },
        {
          key: "sql",
          duration: 0,
        },
      ];

      for (var i = 0; i < tests.length; i++) {
        var test = tests[i];
        context.dispatch("getSpeed", { test: test, payload: payload });
      }
    },
    async getSpeed(context, queryObject) {
      var test = queryObject.test;
      var payload = queryObject.payload;
      var start = new Date().getTime();
      if (test.key === "frontend") {
        var response = await supabase.from("all_german_cities").select("*");
        var end = new Date().getTime();
        test.duration = (end - start) / 1000;
        var queryObject = {
          id: payload.id,
          payload: {
            frontend_duration: Math.floor(test.duration * 1000),
          },
          table: "speed_test",
        };
      } else if (test.key === "backend") {
        var response = await context.dispatch("speedTest");
        var end = new Date().getTime();
        test.duration = (end - start) / 1000;
        var queryObject = {
          id: payload.id,
          payload: {
            backend_duration: Math.floor(test.duration * 1000),
          },
          table: "speed_test",
        };
      } else if (test.key === "sql") {
        var response = await context.dispatch("speedTest", { sql: true });
        test.response = response.data.response.length + " Zeilen geladen!";
        var end = new Date().getTime();
        test.duration = (end - start) / 1000;
        var queryObject = {
          id: payload.id,
          payload: {
            sql_duration: Math.floor(test.duration * 1000),
          },
          table: "speed_test",
        };
      }
      context.dispatch("updateSupabaseEntity", queryObject);
      test.loading = false;
    },
  },
};
