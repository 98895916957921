<template>
  <h3 class="ma-1">Checklist</h3>
  <draggable v-model="checkList" item-key="id" @change="updateOrder">
    <template #item="{ element, index }">
      <v-card elevation="3" class="mx-2">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                variant="outlined"
                density="compact"
                v-model="element.name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-btn
                class="ml-2"
                size="small"
                @click="deleteCheckListItem(element, index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </draggable>
  <v-col align="center">
    <v-btn @click="addCheckListItem()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn @click="saveCheckList()" class="ml-2" :loading="loading">
      <v-icon>mdi-content-save</v-icon>
    </v-btn>
  </v-col>
</template>

<script setup>
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      checkList: [],
      loading: false,
    };
  },
  methods: {
    async getCheckList() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_checklist",
      });
      this.checkList = response.data;
    },
    async addCheckListItem() {
      var object = {
        id: null,
        name: null,
        order: this.checkList.length + 1,
      };
      this.checkList.push(object);
    },
    async saveCheckList() {
      this.saveArray("recruiting_checklist", this.checkList);
    },
    async saveArray(table, payload) {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      this.loading = false;
    },
    async deleteCheckListItem(element, index) {
      var queryObject = {
        id: element.id,
        table: "recruiting_checklist",
      };
      if (element.id) {
        await this.$store.dispatch("deleteSupabaseEntity", queryObject);
      }

      this.statusList.splice(index, 1);
      this.updateOrder();
    },
    updateOrder() {
      this.checkList.forEach(function (item, index) {
        item.order = index + 1;
      });
    },
  },
  async created() {
    this.getCheckList();
  },
};
</script>
