<template>
  <v-checkbox
    v-model="chosenRole.can_hide_employees"
    label="Kann Mitarbeiter verstecken"
    @input="updateRole()"
  ></v-checkbox>
  <v-checkbox
    v-model="chosenRole.can_end_contract"
    label="Kann Arbeitsverhältnis beenden"
    @input="updateRole()"
  ></v-checkbox>
  <v-checkbox
    v-model="chosenRole.can_change_user"
    label="Kann sich als anderer Nutzer anmelden"
    @input="updateRole()"
  ></v-checkbox>
  <v-checkbox
    v-model="chosenRole.can_edit_performance_records"
    label="Kann Leistungsnachweise ansehen und bearbeiten"
    @input="updateRole()"
  ></v-checkbox>
  <v-checkbox
    v-model="chosenRole.can_see_all_tasks"
    label="Kann alle Aufgaben/Tasks sehen"
    @input="updateRole()"
  ></v-checkbox>
</template>

<script setup>
const props = defineProps({
  chosenRole: Object,
  index: Number,
});
</script>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    updateRole() {
      var queryObject = {
        table: "public_user_roles",
        id: this.chosenRole.id,
        payload: {
          can_hide_employees: this.chosenRole.can_hide_employees,
          can_end_contract: this.chosenRole.can_end_contract,
          can_change_user: this.chosenRole.can_change_user,
          can_edit_performance_records:
            this.chosenRole.can_edit_performance_records,
          can_see_all_tasks: this.chosenRole.can_see_all_tasks,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
    },
  },
  created() {
    console.log(this.chosenRole);
  },
};
</script>
