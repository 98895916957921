<template>
  <v-row class="ma-2">
    <v-col cols="12" sm="6">
      <v-btn-toggle
        multiple
        v-model="$store.state.jobView.cities"
        color="deep-purple-accent-3"
      >
        <v-btn v-for="btn in cities" :value="btn.city" size="small">
          {{ btn.city }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col cols="12" sm="6">
      <v-select
        :items="categories"
        item-title="name"
        item-value="id"
        multiple
        v-model="$store.state.jobView.categories"
        chips
        label="Kategorie"
        clearable
      ></v-select>
    </v-col>
  </v-row>
  <v-row class="ma-2">
    <v-col cols="12" align="center">
      <router-link to="/Jobs/new">
        <v-btn color="success" size="large">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </router-link>
    </v-col>
    <template v-for="job in jobs">
      <v-col cols="12" v-if="checkFilter(job)">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              v-bind="props"
              :elevation="isHovering ? '12' : '2'"
              rounded="xl"
              @click="goToPath(job)"
            >
              <v-card-title class="bg-indigo-lighten-1 mb-2">{{
                job.title
              }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-icon class="ma-1">mdi-folder-open</v-icon>
                    {{ job.recruiting_jobs_categories.name }}
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-icon class="ma-1">mdi-map-marker</v-icon>
                    {{ job.cities.city }}
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-icon class="ma-1">mdi-clock</v-icon>
                    {{ job.recruiting_jobs_models.name }}
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-icon class="ma-1">mdi-account</v-icon>
                    {{ job.public_users.full_name }}
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6" sm="2" v-for="status in job.statusList">
                        <v-hover>
                          <template v-slot:default="{ isHovering, props }">
                            <v-card
                              align="center"
                              class="pa-2"
                              v-bind="props"
                              :color="isHovering ? 'green-darken-1' : null"
                              :variant="isHovering ? 'elevated' : 'outlined'"
                            >
                              <v-chip
                                size="x-small"
                                variant="elevated"
                                color="indigo"
                                class="ma-1"
                                >{{ status.name }}</v-chip
                              >
                              <div>
                                <v-chip size="small">{{
                                  status.recruiting_job_candidates.length
                                }}</v-chip>
                              </div>
                            </v-card>
                          </template>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </template>
  </v-row>
</template>

<script setup></script>
<script>
export default {
  data() {
    return {
      categories: [],
      jobs: [],
      cities: [],
    };
  },
  methods: {
    async getCities() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_jobs_categories",
      });
      this.categories = response.data;
    },
    async getCategories() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "cities",
      });
      this.cities = response.data;
    },
    async getJobs() {
      var queryObject = {
        select:
          "*,cities(*),recruiting_jobs_categories(*),recruiting_jobs_models(*),public_users(full_name)",
        table: "recruiting_jobs",
        where: [{ type: "is", key: "active", value: true }],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.jobs = response.data;
      for (var i = 0; i < this.jobs.length; i++) {
        await this.getJobCandidates(this.jobs[i]);
      }
    },
    async getJobCandidates(chosenJob) {
      var queryObject = {
        select: "*,recruiting_job_candidates(*,recruiting_candidates(*))",
        table: "recruiting_status",
        where: [
          {
            type: "eq",
            key: "recruiting_job_candidates.job_id",
            value: chosenJob.id,
          },
        ],
        order: { key: "order" },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      chosenJob.statusList = response.data;
    },
    goToPath(job) {
      this.$router.push({ path: `/Jobs/${job.id}` });
    },
    checkFilter(job) {
      var locations = this.$store.state.jobView.cities.filter(
        (item) => job.cities.city == item,
      );
      if (this.$store.state.jobView.cities.length == 0) {
        var locationFilter = true;
      } else if (locations.length > 0) {
        var locationFilter = true;
      } else {
        var locationFilter = false;
      }
      var categories = this.$store.state.jobView.categories.filter(
        (item) => job.recruiting_jobs_categories.id == item,
      );
      if (this.$store.state.jobView.categories.length == 0) {
        var categoryFilter = true;
      } else if (categories.length > 0) {
        var categoryFilter = true;
      } else {
        var categoryFilter = false;
      }
      return locationFilter && categoryFilter;
    },
  },
  async mounted() {
    await this.getCategories();
    await this.getCities();
    await this.getJobs();
  },
};
</script>
