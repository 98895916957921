<template>
  <calendar-dialog
    :editEvent="editEvent"
    v-if="$store.state.calendarDialog"
    :entity="entity"
    :entityId="entityId"
  ></calendar-dialog>
  <v-row class="ma-4">
    <v-col cols="12" align="center">
      <v-btn color="success" @click="createEventObject()"
        >Termin einstellen</v-btn
      >
    </v-col>
    <v-col v-for="event in events" cols="12" :sm="entityId ? '12' : '4'">
      <v-card rounded="xl">
        <v-card-title class="bg-indigo">
          <v-row>
            <v-col align="left">{{ event.summary }}</v-col>
            <v-col align="right" v-if="event.group">
              <v-btn flat color="indigo" @click="editEventObject(event)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-subtitle class="ma-4 text-h6"
          >{{ event.showDateStart.split(" ")[0] }}
          {{ event.showDateStart.split(" ")[1] }} -
          {{ event.showDateEnd.split(" ")[1] }}</v-card-subtitle
        >
        <v-card-text class="mx-2">
          <router-link
            :to="'/Kandidaten/' + attendee.id"
            v-for="attendee in event.attendees"
          >
            <v-chip
              @click=""
              size="x-small"
              variant="elevated"
              :color="statusColor(attendee.responseStatus)"
            >
              <v-icon v-if="attendee.responseStatus == 'accepted'"
                >mdi-check</v-icon
              >
              <v-icon v-if="attendee.responseStatus == 'declined'"
                >mdi-alert</v-icon
              >
              {{ attendee.first_name + " " + attendee.last_name }}
            </v-chip>
          </router-link>
          <p v-if="event.hangoutLink" class="mt-2">
            <a :href="event.hangoutLink" target="_blank">{{
              event.hangoutLink
            }}</a>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { mapState } from "vuex";
import CalendarDialog from "./CalendarDialog.vue";
const props = defineProps(["entityId", "entity"]);
</script>
<script>
export default {
  data() {
    return {
      editEvent: {
        id: null,
        summary: "Hiring Hour",
        description: "Das ist die Beschreibung für den Termin",
        date: new Date(),
        startTime: null,
        endTime: null,
        start: null,
        end: null,
        attendees: [{ email: this.$store.state.userInfo.email }],
      },
      editEvent: {},
      loading: false,
      eventTypesAll: [
        { summary: "Hiring Hour", description: "", group: true, online: true },
        {
          summary: "Vorstellungsgespräch",
          description: "",
          online: true,
          withSpecificPerson: true,
        },
        {
          summary: "Vorstellungsgespräch - Vor Ort",
          description: "",
          online: false,
          withSpecificPerson: true,
        },
        {
          summary: "Schnuppernacht",
          description: "",
          online: false,
        },
        {
          summary: "Basis Seminar - 2 Tage - Online",
          description: "",
          group: true,
          online: true,
          owner: "training@bellcaptain.team",
        },
        {
          summary: "Basis Seminar 1 Tag - Online",
          description: "",
          group: true,
          online: true,
          owner: "training@bellcaptain.team",
        },
        {
          summary: "Basis Seminar Prüfung - Vor Ort",
          description: "",
          online: false,
        },
        {
          summary: "Basis Seminar Prüfung - Online",
          description: "",
          online: true,
        },
        {
          summary: "Vertragsgespräch - Vor Ort",
          description: "",
          online: false,
          withSpecificPerson: true,
        },
        {
          summary: "Vertragsgespräch - Online",
          description: "",
          online: true,
          withSpecificPerson: true,
        },
      ],
      calendar: {},
      eventTypes: [
        { summary: "Hiring Hour", description: "" },
        { summary: "Basis Seminar Theorie", description: "" },
      ],
      today: null,
      dialog: false,
      events: [],
    };
  },
  computed: {
    ...mapState({
      calendarDialog: (state) => state.calendarDialog,
    }),
  },
  watch: {
    async calendarDialog(newValue, oldValue) {
      if (newValue == false) {
        this.checkCalendar();
      }
    },
  },
  methods: {
    statusColor(responseStatus) {
      switch (responseStatus) {
        case "accepted":
          return "success";
        case "declined":
          return "error";
        default:
          return "grey";
      }
    },
    async checkCalendar() {
      this.events = await this.$store.dispatch("checkCalendar", {
        entity: this.entity,
      });
      this.events.forEach((event) => {
        event.group = this.eventTypesAll.find(
          (type) => type.summary == event.summary,
        )?.group;
      });
      this.events.sort((a, b) =>
        new Date(a.start.dateTime) > new Date(b.start.dateTime)
          ? 1
          : new Date(b.start.dateTime) > new Date(a.start.dateTime)
          ? -1
          : 0,
      );
    },
    async createEventObject() {
      this.editEvent.id = null;
      this.editEvent.date = new Date();
      var date = new Date();
      this.editEvent.startTime = date.getHours() + 1 + ":00";
      this.editEvent.endTime = date.getHours() + 2 + ":00";
      this.$store.state.calendarDialog = true;
    },
    async editEventObject(event) {
      this.editEvent.id = event.id;
      this.editEvent.summary = event.summary;
      this.editEvent.date = new Date(event.start.dateTime);
      this.editEvent.startTime =
        event.showDateStart.split(" ")[1].split(":")[0] +
        ":" +
        event.showDateStart.split(" ")[1].split(":")[1];
      this.editEvent.endTime =
        event.showDateEnd.split(" ")[1].split(":")[0] +
        ":" +
        event.showDateEnd.split(" ")[1].split(":")[1];
      this.$store.state.calendarDialog = true;
    },
  },
  async created() {
    this.checkCalendar();
  },
};
</script>
