<template>
  <v-col v-if="!chosenProcess">
    <v-btn
      v-for="process in onboardings"
      @click="
        chosenProcess = process;
        saveOnboarding();
      "
      >{{ process.name }}</v-btn
    >
  </v-col>

  <v-card v-else>
    <v-row class="ma-4">
      <v-col cols="12" align="right">
        <v-btn color="red" @click="deleteOnboarding()">Löschen</v-btn>
      </v-col>
      <v-col xxl="3" xl="3" lg="5" md="12" sm="12">
        <v-list>
          <v-list-item
            :class="
              step.employee_onboarding_steps.processed ? 'bg-success' : null
            "
            @click="tab = step.id"
            v-for="step in chosenProcess.employee_onboarding_process_step_matching"
          >
            <v-icon class="mx-2" v-if="step.employee_onboarding_steps.processed"
              >mdi-check-circle</v-icon
            >
            <v-icon class="mx-2" v-else
              >mdi-checkbox-blank-circle-outline</v-icon
            >

            {{ step.employee_onboarding_steps.name }}
          </v-list-item>
        </v-list>
      </v-col>
      <v-col xxl="9" xl="9" lg="7" md="12" sm="12">
        <v-window v-model="tab">
          <v-window-item
            v-for="step in chosenProcess.employee_onboarding_process_step_matching"
            :value="step.id"
          >
            <v-container fluid>
              <v-card class="mb-8" variant="tonal" color="green-darken-3">
                <v-card-title>{{
                  step.employee_onboarding_steps.name
                }}</v-card-title>
                <v-card-text>
                  <v-checkbox
                    color="success"
                    @input="saveOnboarding()"
                    v-model="step.employee_onboarding_steps.processed"
                    label="Prozessschritt erledigt"
                  ></v-checkbox>
                </v-card-text>
              </v-card>
              <v-row v-if="step.employee_onboarding_steps.email">
                <v-col cols="12">
                  <v-card>
                    <v-card-text>
                      <v-text-field
                        v-model="step.employee_onboarding_steps.email_subject"
                        label="Betreff"
                      ></v-text-field>
                      <QuillEditor
                        theme="snow"
                        toolbar="essential"
                        v-model:content="
                          step.employee_onboarding_steps.email_body
                        "
                        contentType="html"
                        placeholder="Body"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  cols="12"
                  v-for="task in step.employee_onboarding_steps
                    .employee_onboarding_step_components"
                >
                  <v-checkbox
                    color="success"
                    @input="saveOnboarding()"
                    v-if="task.type == 'checkbox'"
                    :label="task.content"
                    class="my-n4"
                    v-model="task.processed"
                  ></v-checkbox>
                  <v-card v-else-if="task.type == 'text'" variant="outlined">
                    <v-card-text class="ma-3" v-html="task.content">
                    </v-card-text>
                  </v-card>
                  <template v-else-if="task.type == 'key_value'">
                    <v-text-field
                      variant="outlined"
                      density="compact"
                      class="shrink"
                      v-if="keyObject[task.key_id].type == 'text'"
                      :label="keyObject[task.key_id].key_name_ger"
                      v-model="task.value"
                    ></v-text-field>

                    <v-text-field
                      variant="outlined"
                      density="compact"
                      class="shrink"
                      type="date"
                      v-if="keyObject[task.key_id].type == 'date'"
                      :label="keyObject[task.key_id].key_name_ger"
                      v-model="task.value"
                    ></v-text-field>

                    <v-text-field
                      variant="outlined"
                      density="compact"
                      class="shrink"
                      type="number"
                      v-if="keyObject[task.key_id].type == 'number'"
                      :label="keyObject[task.key_id].key_name_ger"
                      :suffix="
                        keyObject[task.key_id].suffix_ger
                          ? keyObject[task.key_id].suffix_ger
                          : null
                      "
                      v-model="task.value"
                    ></v-text-field>

                    <v-select
                      variant="outlined"
                      density="compact"
                      class="shrink"
                      v-if="keyObject[task.key_id].type == 'selection'"
                      :label="keyObject[task.key_id].key_name_ger"
                      :items="keyObject[task.key_id].selection"
                      v-model="task.value"
                    ></v-select>

                    <v-select
                      variant="outlined"
                      density="compact"
                      class="shrink"
                      v-if="keyObject[task.key_id].type == 'id'"
                      :label="keyObject[task.key_id].key_name_ger"
                      :item-title="keyObject[task.key_id].item_name"
                      item-value="id"
                      :items="
                        selections[keyObject[task.key_id].selection_table]
                      "
                      v-model="task.value"
                    ></v-select>

                    <v-switch
                      color="success"
                      v-if="keyObject[task.key_id].type == 'boolean'"
                      :label="keyObject[task.key_id].key_name_ger"
                      v-model="task.value"
                    ></v-switch>
                  </template>
                  <v-text-field
                    v-else-if="task.type == 'key_value'"
                    :label="task.content"
                    v-model="task.value"
                  ></v-text-field>
                  <template v-else-if="task.type == 'document_upload'">
                    <v-row>
                      <v-col cols="4" class="mt-2" align="center">
                        <v-checkbox
                          color="success"
                          @input="saveOnboarding()"
                          label="Hochgeladen"
                          class="my-n4"
                          v-model="task.processed"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="8">
                        <v-file-input
                          density="compact"
                          :label="task.content"
                          @update:focused="documentInfo = task"
                          @change="uploadDocument"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </template>

                  <div v-else>{{ task }}</div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="indigo-darken-3"
                    :loading="loading"
                    @click="saveContent(step)"
                    >Speichern</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
const props = defineProps({
  employeeInfo: Object,
  role: Object,
});
</script>

<script>
export default {
  data() {
    return {
      onboardings: [],
      chosenProcess: {},
      processExists: false,
      tab: null,
      documentInfo: {},
      keys: [],
      keyObject: {},
      documentCategories: [],
      selections: [],
      dataPoints: [],
      keyMatching: {
        text: "value_text",
        date: "value_date",
        number: "value_number",
        selection: "value_text",
        id: "value_id",
        boolean: "value_bool",
      },
    };
  },
  methods: {
    async getSelections() {
      this.loading = true;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "departments",
      });
      this.selections.departments = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employment_types",
      });
      this.selections.employment_types = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "organisations",
      });
      this.selections.organisations = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_teams",
      });
      this.selections.employee_teams = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "cities",
      });
      this.selections.cities = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employees",
        where: [{ type: "eq", key: "status", value: "Aktiv" }],
      });
      this.selections.employees = response.data;

      var queryObject = {
        table: "employee_data_matching",
        where: [
          { type: "eq", key: "employee_id", value: this.employeeInfo.id },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((item) => {
        this.dataPoints[item.key_id] = item;
      });
      this.loading = false;
    },
    async getKeys() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_data_keys",
      });
      this.keys = response.data;
      this.keys.forEach((item) => {
        this.keyObject[item.id] = item;
      });
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_document_categories",
      });
      this.documentCategories = response.data;
    },
    async getEmployeeOnboarding() {
      var queryObject = {
        table: "employees",
        where: [{ type: "eq", key: "id", value: this.employeeInfo.id }],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.chosenProcess = response.data[0].onboarding;
      if (this.chosenProcess) {
        this.chosenProcess.employee_onboarding_process_step_matching.forEach(
          (item) => {
            item.employee_onboarding_steps.employee_onboarding_step_components.sort(
              (a, b) => a.order - b.order,
            );
          },
        );
        this.chosenProcess.employee_onboarding_process_step_matching.sort(
          (a, b) => a.order - b.order,
        );
      }

      this.chosenProcess?.employee_onboarding_process_step_matching.forEach(
        (item1) => {
          item1.employee_onboarding_steps.employee_onboarding_step_components.forEach(
            (item2) => {
              if (item2.type == "key_value") {
                var valuePoint = this.dataPoints[item2.key_id];
                var keyInfo = this.keyObject[item2.key_id];
                if (valuePoint) {
                  item2.value = valuePoint[this.keyMatching[keyInfo.type]];
                } else {
                  item2.value = null;
                }
              }
            },
          );
        },
      );
    },
    async uploadDocument(event) {
      var file = event.target.files[0];
      var queryObject = {
        bucket: "employees",
        filePath:
          this.employeeInfo.id +
          "/" +
          this.documentInfo.employee_document_category_id +
          "/" +
          file.name,
        file: file,
      };
      var response = await this.$store.dispatch(
        "uploadStorageFile",
        queryObject,
      );

      var name = response.data.path.split("/")[2];
      var payload = {
        name: name,
        category_id: this.documentInfo.employee_document_category_id,
        employee_id: this.employeeInfo.id,
        created_by: this.$store.state.userInfo.id,
      };
      var queryObject = {
        table: "employee_documents",
        payload: payload,
      };
      await this.$store.dispatch("createSupabaseEntity", queryObject);
    },
    async saveContent(step) {
      this.loading = true;
      var upsertArray = [];
      var elements =
        step.employee_onboarding_steps.employee_onboarding_step_components;
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        var keyInfo = this.keyObject[element.key_id];

        if (keyInfo) {
          var valueType = this.keyMatching[keyInfo.type];
          var object = {
            employee_id: this.employeeInfo.id,
            key_id: element.key_id,
          };
          object[valueType] = element.value;
          if (object[valueType]) {
            upsertArray.push(object);
          }
        }
      }
      var queryObject = {
        table: "employee_data_matching",
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      this.loading = false;
    },
    async getOnboarding() {
      var queryObject = {
        select:
          "*,employee_onboarding_process_step_matching(*,employee_onboarding_steps(*,employee_onboarding_step_components(*)))",
        table: "employee_onboarding_process",
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((item) => {
        item.employee_onboarding_process_step_matching.forEach((item) => {
          item.employee_onboarding_steps.employee_onboarding_step_components.sort(
            (a, b) => a.order - b.order,
          );
        });
        item.employee_onboarding_process_step_matching.sort(
          (a, b) => a.order - b.order,
        );
      });
      this.onboardings = response.data;
    },
    async deleteOnboarding() {
      var queryObject = {
        id: this.employeeInfo.id,
        table: "employees",
        payload: {
          onboarding: null,
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.chosenProcess = null;
    },
    async saveOnboarding() {
      var queryObject = {
        id: this.employeeInfo.id,
        table: "employees",
        payload: {
          onboarding: this.chosenProcess,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
      var onboardingClear = true;
      this.chosenProcess.employee_onboarding_process_step_matching.forEach(
        (item) => {
          if (!item.employee_onboarding_steps.processed) {
            onboardingClear = false;
          }
        },
      );
      if (onboardingClear) {
        var queryObject = {
          table: "employee_data_matching",
          payload: {
            key_id: 69,
            employee_id: this.employeeInfo.id,
            value_text: "Abgeschlossen",
          },
        };
        await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      } else {
        var queryObject = {
          table: "employee_data_matching",
          payload: {
            key_id: 69,
            employee_id: this.employeeInfo.id,
            value_text: "Ja",
          },
        };
        await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      }
      var queryObject = {
        table: "employee_data_matching",
        where: [
          { type: "eq", key: "employee_id", value: this.employeeInfo.id },
          { type: "eq", key: "key_id", value: 69 },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
    },
  },
  async created() {
    await this.getSelections();
    await this.getKeys();
    await this.getEmployeeOnboarding();
    await this.getOnboarding();
  },
};
</script>
