<template>
  <v-card flat class="ma-8" style="height: 500px">
    <v-card-title>Google Triggers</v-card-title>
    <iframe
      width="80%"
      height="100%"
      src="https://script.google.com/a/macros/bellcaptain.team/s/AKfycbycGbbn5DOfkR7YEcmNmzBnVbECkYREp6-pcvIHf80j5aVebKwypaukftndoAiZXy37gA/exec"
    ></iframe>
  </v-card>
</template>
