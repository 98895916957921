<template>
  <v-card class="ma-2 px-2" elevation="5" variant="outlined">
    <v-card-title>Reminder</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="mb-n4">
          <v-text-field
            label="Reminderdate"
            variant="outlined"
            type="datetime-local"
            v-model="application.reminder_date"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            label="Zeiteinheit"
            :items="reminderTimes.timeUnits"
            item-title="label"
            item-value="value"
            v-model="reminderTimes.unit"
            variant="outlined"
            @update:modelValue="changeReminderdate()"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Zahl"
            v-model="reminderTimes.count"
            :suffix="reminderTimes.unit"
            type="number"
            variant="outlined"
            @input="changeReminderdate()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="my-n8">
      <v-row>
        <v-col cols="6" align="center">
          <v-switch
            color="green"
            v-model="sendEmail"
            label="Sende Email"
            :disabled="candidateInfo.gender == null"
          ></v-switch>
        </v-col>
        <v-col cols="6" align="center">
          <v-btn @click="saveReminder()" icon size="large" :loading="loading">
            <v-icon>mdi-content-save</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
    <v-col cols="6" v-if="candidateInfo.gender == null" class="mt-n2">
      Geschlecht fehlt!
    </v-col>
  </v-card>
</template>

<script setup>
import { now } from "@vueuse/core";

const props = defineProps({
  candidateInfo: Object,
  emailObject: Object,
  application: Object,
});
</script>
<script>
export default {
  data() {
    return {
      reminderTimes: {},
      sendEmail: false,
      loading: false,
    };
  },
  methods: {
    changeReminderdate() {
      var reminderDate = new Date();
      this.reminderTimes.count = parseInt(this.reminderTimes.count, 10);
      if (this.reminderTimes.unit == "days") {
        reminderDate.setDate(reminderDate.getDate() + this.reminderTimes.count);
      } else if (this.reminderTimes.unit == "hours") {
        reminderDate.setHours(
          reminderDate.getHours() + this.reminderTimes.count,
        );
      } else if (this.reminderTimes.unit == "weeks") {
        reminderDate.setDate(
          reminderDate.getDate() + this.reminderTimes.count * 7,
        );
      }

      this.application.reminder_date = reminderDate.toISOString().slice(0, 16);
    },
    async saveReminder() {
      this.loading = true;
      await this.$store.dispatch("updateSupabaseEntity", {
        table: "recruiting_job_candidates",
        id: this.application.id,
        payload: {
          modified_at: new Date().toISOString(),
          reminder_date: this.application.reminder_date,
          reminder_count: this.application.reminder_count + 1,
        },
      });
      var noteDate = await this.$store.dispatch(
        "formatDate",
        new Date(this.application.reminder_date),
      );
      await this.$store.dispatch("createSupabaseEntity", {
        table: "notes",
        payload: {
          job_candidate_id: this.application.id,
          owner_id: this.$store.state.userInfo.id,
          action: `Reminder auf ${noteDate} gesetzt!`,
        },
      });
      if (this.sendEmail) {
        var response = await this.$store.dispatch("getSupabaseEntity", {
          table: "recruiting_templates",
          where: [{ type: "eq", key: "id", value: 16 }],
        });
        var template = response.data[0];
        var subject = await this.$store.dispatch("replaceBody", {
          body: template.subject,
          info: this.candidateInfo,
        });
        var body = await this.$store.dispatch("replaceBody", {
          body: template.body,
          info: this.candidateInfo,
        });
        var emailObject = {
          from: "bewerbung@bellcaptain.team",
          to: this.candidateInfo.email,
          subject: subject,
          body: body,
        };
        this.$store.dispatch("sendGmail", emailObject);
      }
      this.loading = false;
      this.$store.state.candidateChangeCheck = true;
    },
  },
  async created() {
    this.reminderTimes = JSON.parse(
      JSON.stringify(this.$store.state.reminderTimes),
    );
    this.application.reminder_date = this.application.reminder_date.slice(
      0,
      16,
    );
    if (now() > new Date(this.application.reminder_date).getTime()) {
      var reminderDate = new Date();
      if (this.reminderTimes.unit == "days") {
        reminderDate.setDate(reminderDate.getDate() + this.reminderTimes.count);
      } else if (this.reminderTimes.unit == "hours") {
        reminderDate.setHours(
          reminderDate.getHours() + this.reminderTimes.count,
        );
      } else if (this.reminderTimes.unit == "weeks") {
        reminderDate.setDate(
          reminderDate.getDate() + this.reminderTimes.count * 7,
        );
      }
      reminderDate = await this.$store.dispatch("toISOLocal", reminderDate);
      this.application.reminder_date = reminderDate.slice(0, 16);
    }
  },
};
</script>
