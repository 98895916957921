<template>
  <v-card>
    <v-tabs v-model="tab" bg-color="primary">
      <v-tab value="profile">Profile</v-tab>
      <v-tab value="performanceRecord">Leistungsnachweis</v-tab>
      <v-tab value="shifts">Schichten und Mitarbeiter</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window>
        <v-tabs-window-item value="profile" v-if="tab == 'profile'">
          <v-row class="ma-2">
            <v-col cols="12" align="right" v-if="!hotelId">
              <v-btn
                color="grey-lighten-3"
                @click="$store.state.hotelView.drawer = false"
                flat
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" align="left">
              <div class="text-h6">Grunddaten</div>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="green" @click="saveHotel()" :loading="loading">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="hotelEditObject.name"
                label="Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="hotelEditObject.short_name"
                label="Kurzname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Region"
                :items="$store.state.cities"
                item-title="city"
                item-value="id"
                v-model="hotelEditObject.city_id"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="hotelEditObject.internal_id"
                label="Kundennummer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="hotelEditObject.software_id"
                label="Hotelsoftware"
                :items="hotelSoftwares"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                label="Senior Bonuspunkte"
                type="number"
                v-model="hotelEditObject.bonus_points_senior"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                label="Clerk Bonuspunkte"
                type="number"
                v-model="hotelEditObject.bonus_points_clerk"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="time"
                label="Start Dienstzeit"
                v-model="hotelEditObject.work_start"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="time"
                label="Ende Dienstzeit"
                v-model="hotelEditObject.work_end"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-select
                label="Dresscode"
                :items="[
                  'Bellcaptain Business',
                  'Bellcaptain Casual',
                  'Bellcaptain Business ohne Krawatte',
                  'Bellcaptain Business schwarz',
                ]"
                v-model="hotelEditObject.dress_code"
              ></v-select>
            </v-col>
            <v-col cols="12" align="center">
              <v-card variant="tonal" class="mx-12" color="blue-darken-4">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <div class="text-h6">Regelmäßige Schichttage</div>
                    </v-col>
                    <v-col cols="12">
                      <template v-for="day in shiftDays">
                        {{ day.short }}
                        <v-icon
                          :color="day.checked ? 'green-darken-2' : null"
                          size="40px"
                          class="mr-3"
                          @click="day.checked = !day.checked"
                          :icon="
                            day.checked
                              ? 'mdi-checkbox-marked'
                              : 'mdi-checkbox-blank-outline'
                          "
                        >
                        </v-icon>
                      </template>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="ma-2">
            <v-col cols="6">
              <v-row>
                <v-col cols="12">
                  <div class="text-h6">Adresse</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="hotelEditObject.street"
                    label="Straße"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-text-field
                    label="Postleitzahl"
                    v-model="hotelEditObject.zip_code"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-text-field
                    label="Stadt"
                    v-model="hotelEditObject.city"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="12">
                  <div class="text-h6">Rechnungsadresse</div>
                </v-col>
                <v-col cols="12" class="my-n4">
                  <v-textarea
                    v-model="hotelEditObject.invoice_name"
                    label="Name"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" sm="6" class="my-n4">
                  <v-text-field
                    v-model="hotelEditObject.invoice_street"
                    label="Straße"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" class="my-n4">
                  <v-text-field
                    label="Adresszusatz"
                    v-model="hotelEditObject.invoice_address_addition"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" class="my-n4">
                  <v-text-field
                    label="Postleitzahl"
                    v-model="hotelEditObject.invoice_zip_code"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" class="my-n4">
                  <v-text-field
                    label="Stadt"
                    v-model="hotelEditObject.invoice_city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="my-n4">
                  <v-text-field
                    label="Land"
                    v-model="hotelEditObject.invoice_country"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12">
              <v-switch
                color="success"
                label="Send Email"
                v-model="hotelEditObject.email"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="ma-2" v-if="false">
            <v-col cols="12">
              <div class="text-h6">Ansprechpartner</div>
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn-toggle
                v-model="hotelEditObject.person_gender"
                color="indigo"
              >
                <v-btn :value="true">
                  <v-icon>mdi-gender-male</v-icon>
                </v-btn>
                <v-btn :value="false">
                  <v-icon>mdi-gender-female</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="6" sm="5">
              <v-text-field
                label="Vorname"
                v-model="hotelEditObject.person_first_name"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="5">
              <v-text-field
                label="Nachname"
                v-model="hotelEditObject.person_last_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Telefonnummer"
                v-model="hotelEditObject.phone_number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-4">
            <v-col cols="12">
              <div class="text-h6">Ansprechpartner</div>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="hotelEditObject.hotel_partners.push({})"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>

            <v-col
              cols="6"
              xs="12"
              v-for="partner in hotelEditObject?.hotel_partners"
            >
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="2" sm="12">
                      <v-btn-toggle v-model="partner.gender">
                        <v-btn
                          size="x-small"
                          :value="false"
                          @click="partner.gender = false"
                          >Frau</v-btn
                        >
                        <v-btn
                          size="x-small"
                          :value="true"
                          @click="partner.gender = true"
                          >Herr</v-btn
                        >
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="5" sm="12">
                      <v-text-field
                        label="Vorname"
                        variant="outlined"
                        density="compact"
                        v-model="partner.first_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5" sm="12">
                      <v-text-field
                        label="Nachname"
                        variant="outlined"
                        density="compact"
                        v-model="partner.last_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5" sm="12">
                      <v-text-field
                        label="Position"
                        variant="outlined"
                        density="compact"
                        v-model="partner.position"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="12">
                      <v-text-field
                        label="Email"
                        variant="outlined"
                        density="compact"
                        v-model="partner.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="12">
                      <v-text-field
                        label="Nummer"
                        variant="outlined"
                        density="compact"
                        v-model="partner.phone"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="blue" @click="updatePartner(partner)">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="deletePartner(partner)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-tabs-window-item>

        <v-tabs-window-item
          value="performanceRecord"
          v-if="tab == 'performanceRecord'"
        >
          <PerformanceRecord :hotelObject="hotelObject"></PerformanceRecord>
        </v-tabs-window-item>
        <v-tabs-window-item value="shift" v-if="tab == 'shifts'">
          <Shifts :hotelObject="hotelObject"></Shifts>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PerformanceRecord from "../Hotels/PerformanceRecord";
import Shifts from "../Hotels/Shifts";
const props = defineProps({});
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      hotelId: null,
      hotelEditObject: {},
      hotelSoftwares: [],
      tab: null,
      shiftDays: [
        { value: 1, checked: false, short: "Mo" },
        { value: 2, checked: false, short: "Di" },
        { value: 3, checked: false, short: "Mi" },
        { value: 4, checked: false, short: "Do" },
        { value: 5, checked: false, short: "Fr" },
        { value: 6, checked: false, short: "Sa" },
        { value: 0, checked: false, short: "So" },
      ],
    };
  },
  methods: {
    async getHotelSoftware() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "hotel_software",
      });
      this.hotelSoftwares = response.data;
    },
    async getHotel() {
      var today = new Date();
      today.setDate(today.getDate() - 180);
      this.hotelId = this.$route.params.id;
      if (this.hotelId) {
        var response = await this.$store.dispatch("getSupabaseEntity", {
          select:
            "*,hotel_partners(*),shifts(*,employees(name,status)),employees_hotels(*,employees(status,name))",
          table: "hotels",
          where: [
            { type: "eq", key: "id", value: this.hotelId },
            { type: "gte", key: "shifts.date", value: today.toISOString() },
          ],
        });
        response.data[0].shift_days?.forEach((day) => {
          this.shiftDays.forEach((item) => {
            if (item.value == day) {
              item.checked = true;
            }
          });
        });
        this.hotelEditObject = response.data[0];
        this.hotelObject = response.data[0];
      }
    },
    openLink(name) {
      this.$router.push({ name: name });
    },
    async updatePartner(partner) {
      if (partner.id) {
        var queryObject = {
          table: "hotel_partners",
          payload: partner,
          id: partner.id,
        };
        await this.$store.dispatch("updateSupabaseEntity", queryObject);
      } else {
        partner.hotel_id = this.hotelEditObject.id;
        var queryObject = {
          table: "hotel_partners",
          payload: partner,
        };
        await this.$store.dispatch("createSupabaseEntity", queryObject);
      }
    },
    async deletePartner(partner) {
      var queryObject = {
        table: "hotel_partners",
        id: partner.id,
      };
      await this.$store.dispatch("deleteSupabaseEntity", queryObject);
      this.hotelEditObject.hotel_partners =
        this.hotelEditObject.hotel_partners.filter(
          (item) => item.id != partner.id,
        );
    },
    async saveHotel() {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(this.hotelEditObject));
      payload.shift_days = [];
      this.shiftDays.forEach((day) => {
        if (day.checked) {
          payload.shift_days.push(day.value);
        }
      });
      delete payload.cities;
      delete payload.employees_hotels;
      delete payload.hotel_partners;
      delete payload.shifts;
      if (payload.id) {
        var queryObject = {
          table: "hotels",
          payload: payload,
          id: payload.id,
        };
        await this.$store.dispatch("updateSupabaseEntity", queryObject);
      } else {
        var queryObject = {
          table: "hotels",
          payload: payload,
        };
        await this.$store.dispatch("createSupabaseEntity", queryObject);
      }
      this.loading = false;
      this.$store.state.hotelView.drawer = false;
    },
  },
  async created() {
    this.getHotelSoftware();
    this.getHotel();
  },
};
</script>
