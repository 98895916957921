<template>
  <v-row>
    <v-col cols="3" align="center">
      <v-btn class="ma-2" @click="createNewEvaluation()"
        >Neue Evaluations erstellen</v-btn
      >
      <v-list height="1000px">
        <v-list-item
          v-for="evaluation in evaluations"
          key="id"
          @click="openEvaluation(evaluation)"
          :class="evaluation.activated ? 'bg-blue-grey-lighten-2' : null"
          >{{ evaluation.name }}</v-list-item
        >
      </v-list>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="9">
      <v-row>
        <v-col cols="8">
          <v-text-field
            label="Name"
            v-model="chosenEvaluation.name"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            label="Status"
            :items="statusList"
            item-title="name"
            item-value="id"
            v-model="chosenEvaluation.status_id"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <draggable v-model="chosenEvaluation.content_json" item-key="id">
            <template #item="{ element, index }">
              <v-row class="my-n6">
                <v-col xl="1" md="2" align="center" class="mt-4">
                  <v-icon
                    size="x-large"
                    :style="iconStyle"
                    @mousedown="handleMouseDown"
                    @mouseup="handleMouseUp"
                    >mdi-drag</v-icon
                  >
                  <v-btn
                    color="green"
                    :variant="
                      element.is_in_candidate_profile ? 'elevated' : 'outlined'
                    "
                    size="x-small"
                    class="ml-1"
                    @click="
                      element.is_in_candidate_profile =
                        !element.is_in_candidate_profile
                    "
                  >
                    In Akte
                  </v-btn>
                </v-col>
                <v-col xl="7" md="6">
                  <v-text-field
                    label="Frage/Überschrift"
                    v-model="element.question"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    label="Typ"
                    :items="evaluationInputs.types"
                    v-model="element.type"
                  ></v-select>
                </v-col>
                <v-col cols="1" align="center">
                  <v-btn
                    color="red"
                    icon
                    @click="chosenEvaluation.content_json.splice(index, 1)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  align="center"
                  class="mt-n8 mb-6"
                  v-if="element.type == 'Dropdown'"
                >
                  <h4>Dropdown Elemente</h4>
                  <v-btn
                    size="x-small"
                    rounded="xl"
                    variant="outlined"
                    v-for="(dropdownElement, index) in element.items"
                    append-icon="mdi-close"
                    >{{ dropdownElement.content }}
                    <template v-slot:append>
                      <v-icon @click="element.items.splice(index, 1)"
                        >mdi-close</v-icon
                      >
                    </template></v-btn
                  >
                  <v-col cols="6">
                    <v-text-field
                      append-inner-icon="mdi-plus"
                      density="compact"
                      v-model="dropdownElementAdd"
                      @click:append-inner="
                        element.items.push({ content: dropdownElementAdd });
                        dropdownElementAdd = null;
                      "
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
            </template>
          </draggable>
        </v-col>
        <v-col
          v-if="false"
          cols="12"
          v-for="(element, elementIndex) in chosenEvaluation.content_json"
        >
          <v-row class="my-n6">
            <v-col cols="8">
              <v-text-field
                label="Frage"
                v-model="element.question"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                label="Typ"
                :items="evaluationInputs.types"
                v-model="element.type"
              ></v-select>
            </v-col>
            <v-col cols="1" align="center">
              <v-btn
                color="red"
                icon
                @click="chosenEvaluation.content_json.splice(elementIndex, 1)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              align="center"
              class="mt-n8 mb-6"
              v-if="element.type == 'Dropdown'"
            >
              <h4>Dropdown Elemente</h4>
              <v-chip
                size="small"
                closable
                @click:close="element.items.splice(index, 1)"
                v-for="(dropdownElement, index) in element.items"
                >{{ dropdownElement.content }}</v-chip
              >
              <v-col cols="6">
                <v-text-field
                  append-inner-icon="mdi-plus"
                  density="compact"
                  v-model="dropdownElementAdd"
                  @click:append-inner="
                    element.items.push({ content: dropdownElementAdd });
                    dropdownElementAdd = null;
                  "
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-2" align="center">
          <v-btn @click="addElement()">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-bottom-navigation>
    <v-btn
      class="bg-red"
      v-if="chosenEvaluation.id > 0"
      @click="deleteEvaluation()"
      :loading="loading"
      >Löschen</v-btn
    >
    <v-spacer></v-spacer>
    <v-btn
      class="bg-green"
      v-if="chosenEvaluation.id > 0"
      @click="updateEvaluation()"
      :loading="loading"
      >Aktualisiseren</v-btn
    >
    <v-btn
      class="bg-green"
      v-else-if="chosenEvaluation.name"
      @click="saveNewEvaluation()"
      :loading="loading"
      >Erstellen</v-btn
    >
  </v-bottom-navigation>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      statusList: [],
      loading: false,
      chosenEvaluation: {
        name: null,
        content_json: [],
      },
      isDragging: false,
      evaluationInputs: {
        types: [
          "Zeile",
          "Text",
          "Html",
          "Dropdown",
          "Ja/Nein",
          "Bewertung",
          "Überschrift",
        ],
      },
      evaluations: [],
      dropdownElementAdd: null,
      idCounter: 0,
    };
  },
  computed: {
    iconStyle() {
      return {
        cursor: this.isDragging ? "grabbing" : "grab",
      };
    },
  },
  methods: {
    handleMouseDown() {
      this.isDragging = true;
      // Additional logic for mouse down event if needed
    },
    handleMouseUp() {
      this.isDragging = false;
      // Additional logic for mouse up event if needed
    },
    addElement(index) {
      var element = {
        question: null,
        type: null,
        items: [],
        id: this.idCounter,
      };
      this.idCounter = this.idCounter + 1;
      this.chosenEvaluation.content_json.push(element);
    },
    async getStatusList() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_status",
      });
      this.statusList = response.data;
    },
    async getEvaluations() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_evaluations",
      });
      this.evaluations = response.data;
    },
    async openEvaluation(evaluation) {
      this.evaluations.forEach(function (item) {
        if (item.id == evaluation.id) {
          item.activated = true;
        } else {
          item.activated = false;
        }
      });
      this.chosenEvaluation = {};
      await this.$store.dispatch("sleep");
      this.chosenEvaluation = evaluation;
    },
    async updateEvaluation() {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(this.chosenEvaluation));
      delete payload.activated;
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "recruiting_evaluations",
        payload: payload,
      });
      this.loading = false;
    },
    async createNewEvaluation() {
      this.evaluations.forEach(function (item) {
        item.activated = false;
      });
      this.chosenEvaluation = {
        name: null,
        type: null,
        content_json: [],
      };
    },
    async saveNewEvaluation() {
      this.loading = true;
      var payload = {
        content_json: this.chosenEvaluation.content_json,
        name: this.chosenEvaluation.name,
        status_id: 2,
      };
      var id = await this.$store.dispatch("createSupabaseEntity", {
        table: "recruiting_evaluations",
        payload: payload,
      });
      await this.getEvaluations();
      var evaluation = this.evaluations.find((item) => item.id == id);
      this.chosenEvaluation = evaluation;
      this.loading = false;
    },
    async deleteEvaluation() {
      this.loading = true;
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "recruiting_evaluations",
        id: this.chosenEvaluation.id,
      });
      this.createNewEvaluation();
      await this.getEvaluations();
      this.loading = false;
    },
  },
  async created() {
    this.getEvaluations();
    this.getStatusList();
  },
};
</script>
