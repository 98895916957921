<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card v-bind="props" :elevation="isHovering ? '10' : '2'" rounded="xl">
        <v-card-title>Info</v-card-title>
        <v-row class="pa-6">
          <v-col cols="12">
            <v-text-field
              variant="outlined"
              density="compact"
              label="Job Name"
              v-model="jobInfo.title"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              variant="outlined"
              density="compact"
              label="Ort"
              v-model="jobInfo.city_id"
              :items="selections.cities"
              item-title="city"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              variant="outlined"
              density="compact"
              label="Kategorie"
              v-model="jobInfo.category_id"
              :items="selections.categories"
              item-title="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              variant="outlined"
              density="compact"
              label="Zeitmodell"
              v-model="jobInfo.model_id"
              :items="selections.models"
              item-title="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              variant="outlined"
              density="compact"
              label="Besitzer"
              v-model="jobInfo.owner_id"
              :items="selections.users"
              item-title="full_name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              variant="outlined"
              density="compact"
              label="Indeed Jobs"
              v-model="jobInfo.indeed_jobs"
              :items="selections.indeedJobs"
              item-title="title"
              item-value="id"
              multiple
              chips
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            size="small"
            @click="updateInfo()"
            v-if="jobInfo.title"
            :loading="loading"
          >
            <span v-if="jobInfo.id > 0">Update</span>
            <span v-else>Create</span>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup>
const props = defineProps({
  jobInfo: Object,
});
</script>

<script>
export default {
  data() {
    return {
      loading: false,
      selections: {
        categories: [],
        models: [],
        cities: [],
        users: [],
        indeedJobs: [],
      },
    };
  },
  methods: {
    async updateInfo() {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(this.jobInfo));
      delete payload.recruiting_job_candidates;
      if (this.jobInfo.id > 0) {
        var queryObject = {
          table: "recruiting_jobs",
          id: this.jobInfo.id,
          payload: payload,
        };
        await this.$store.dispatch("updateSupabaseEntity", queryObject);
      } else {
        delete payload.id;
        var queryObject = {
          table: "recruiting_jobs",
          payload: payload,
        };
        var id = await this.$store.dispatch(
          "createSupabaseEntity",
          queryObject,
        );
        this.jobInfo.id = id;
      }
      this.loading = false;
      var indeedJobArray = [];
      payload.indeed_jobs.forEach(function (item) {
        indeedJobArray.push({ id: item, chosen: true });
      });
      this.$store.dispatch("upsertSupabaseEntity", {
        table: "recruiting_indeed_jobs",
        payload: indeedJobArray,
      });
    },
    async getSelections() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_jobs_categories",
      });
      this.selections.categories = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_jobs_models",
      });
      this.selections.models = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "cities",
      });
      this.selections.cities = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "public_users",
      });
      this.selections.users = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_indeed_jobs",
      });
      this.selections.indeedJobs = response.data;
    },
    openPhoneLink(phone) {
      const phoneLink = `tel:${phone}`;
      window.open(phoneLink);
    },
    openEmailLink(email) {
      const mailtoLink = `mailto:${email}`;
      window.open(mailtoLink);
    },
  },
  async created() {
    await this.getSelections();
  },
};
</script>
