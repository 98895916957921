<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card
        v-bind="props"
        :elevation="isHovering ? '10' : '2'"
        class="mt-4"
        rounded="xl"
      >
        <v-card-title>Dateien</v-card-title>
        <v-progress-linear
          height="12"
          indeterminate
          v-if="filesLoading"
        ></v-progress-linear>
        <v-card-text v-else>
          <v-row v-for="file in documents" class="mt-n4">
            <v-col align="left" cols="1">
              <v-icon color="green-darken-3" icon="mdi-file"></v-icon>
            </v-col>
            <v-col
              @click="openFile(file)"
              class="text-decoration-underline"
              style="cursor: pointer"
            >
              {{ file.name }}
            </v-col>
            <v-col align="right">
              <v-icon
                @click="
                  file.fileEdit.edit = !file.fileEdit.edit;
                  file.fileEdit.newName = file.name.split('.pdf')[0];
                "
                color="green-darken-4"
                icon="mdi-pencil"
                class="mr-2"
              >
              </v-icon>
              <v-icon
                @click="removeFile(file)"
                color="red-darken-3"
                icon="mdi-delete"
              ></v-icon>
            </v-col>
            <v-col cols="12" v-if="file.fileEdit.edit">
              <v-text-field
                v-model="file.fileEdit.newName"
                suffix=".pdf"
                append-inner-icon="mdi-content-save"
                @click:append-inner="renameFile(file)"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="mt-2">
            <v-file-input
              density="compact"
              label="Upload Document"
              @change="uploadDocument"
            ></v-file-input>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup>
import { ref, watch } from "vue";
const props = defineProps(["bucket", "id"]);
</script>
<script>
export default {
  watch: {
    $route(to, from) {
      // Check if the route parameter has changed
      if (from.name === to.name && to.params.id !== from.params.id) {
        // Fetch data when the parameter changes
        this.getFiles();
      }
    },
  },
  data() {
    return {
      mobile: null,
      filesLoading: false,
      documents: [],
      entityId: null,
      fileEdit: {
        newName: null,
        edit: false,
      },
    };
  },
  async created() {
    this.mobile = this.$vuetify.display.mobile;
    this.getFiles();
  },
  methods: {
    async getFiles() {
      this.entityId = this.id;
      var queryObject = {
        bucket: this.bucket,
        folder: this.entityId,
      };
      var files = await this.$store.dispatch(
        "getStorageFolderFiles",
        queryObject,
      );
      files.data.forEach(function (item) {
        item.fileEdit = {};
      });
      this.documents = files.data;
      this.filesLoading = false;
    },
    async openFile(file) {
      var fileUrl = await this.downloadFile(file);
      window.open(fileUrl, "_blank");
    },
    async downloadFile(file) {
      var queryObject = {
        bucket: this.bucket,
        file: this.entityId + "/" + file.name,
      };
      var fileUrl = await this.$store.dispatch(
        "getStorageFileLink",
        queryObject,
      );
      return fileUrl;
    },
    async removeFile(file) {
      this.filesLoading = true;
      var queryObject = {
        bucket: this.bucket,
        file: this.entityId + "/" + file.name,
      };
      await this.$store.dispatch("removeStorageFile", queryObject);
      this.getFiles();
    },
    async uploadDocument(event) {
      this.filesLoading = true;
      var file = event.target.files[0];
      var queryObject = {
        bucket: this.bucket,
        filePath: this.entityId + "/" + file.name,
        file: file,
      };
      await this.$store.dispatch("uploadStorageFile", queryObject);
      this.getFiles();
    },
    async renameFile(file) {
      var oldPath = this.entityId + "/" + file.name;
      var newPath = this.entityId + "/" + file.fileEdit.newName + ".pdf";
      var queryObject = {
        oldPath: oldPath,
        newPath: newPath,
        bucket: "candidates",
      };
      await this.$store.dispatch("renameStorageFile", queryObject);
      file.fileEdit.newName = null;
      file.fileEdit = {
        newName: null,
        edit: false,
      };
      this.getFiles();
    },
  },
};
</script>

<style>
.mobile {
  font-size: 7pt;
}
</style>
