<template>
  <v-card class="ma-2">
    <v-tabs v-model="tab">
      <v-tab value="templates">Vorlagen</v-tab>
      <v-tab value="sources">Kanäle</v-tab>
      <v-tab value="evaluations">Evaluationen</v-tab>
      <v-tab value="statusList">Statusliste</v-tab>
      <v-tab value="indeedJobs">Indeed Jobs</v-tab>
      <v-tab value="denyReasons">Absagegründe</v-tab>
      <v-tab value="duplicates">Dubletten</v-tab>
      <v-tab value="checkLists">Checklisten</v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="templates">
          <Templates></Templates>
        </v-window-item>
        <v-window-item value="statusList">
          <StatusList></StatusList>
        </v-window-item>
        <v-window-item value="sources">
          <Sources></Sources>
        </v-window-item>

        <v-window-item value="evaluations">
          <Evaluations></Evaluations>
        </v-window-item>
        <v-window-item value="indeedJobs">
          <IndeedJobs></IndeedJobs>
        </v-window-item>
        <v-window-item value="denyReasons">
          <DenyReasons></DenyReasons>
        </v-window-item>
        <v-window-item value="duplicates">
          <Duplicates></Duplicates>
        </v-window-item>
        <v-window-item value="checkLists">
          <CheckLists></CheckLists>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import StatusList from "@/components/Settings/Recruiting/StatusList.vue";
import Sources from "@/components/Settings/Recruiting/Sources.vue";
import Templates from "@/components/Settings/Recruiting/Templates.vue";
import Evaluations from "@/components/Settings/Recruiting/Evaluations.vue";
import IndeedJobs from "@/components/Settings/Recruiting/IndeedJobs.vue";
import DenyReasons from "@/components/Settings/Recruiting/DenyReasons.vue";
import Duplicates from "./Recruiting/Duplicates.vue";
import CheckLists from "./Recruiting/Checklists.vue";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      tab: "templates",
    };
  },
  methods: {},
  async mounted() {},
};
</script>
