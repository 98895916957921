<template>
  <v-row>
    <v-col cols="6">
      <v-text-field v-model="search" label="Suche"></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-checkbox
        v-model="only_active"
        label="Nur aktive User"
        @update:model-value="getUsers"
      ></v-checkbox>
    </v-col>
  </v-row>
  <v-data-table
    :search="search"
    :items="users"
    :headers="headers"
    :loading="loading"
  >
    <template v-slot:item.active="{ item }">
      <v-switch
        @change="updateUser(item, 'active')"
        v-model="item.active"
        color="green-darken-1"
      ></v-switch>
    </template>
    <template v-slot:item.email="{ item }">
      <v-row class="my-2">
        <v-col cols="10" v-if="item.edit">
          <v-text-field
            :loading="loading"
            v-model="item.new_email"
            variant="outlined"
            density="compact"
          ></v-text-field>
        </v-col>
        <v-col cols="10" v-else class="mt-2">
          <span>{{ item.email }}</span>
        </v-col>
        <v-col cols="2">
          <v-btn
            icon
            size="small"
            flat
            v-if="item.edit"
            :disabled="item.new_email == item.email"
          >
            <v-icon size="small" @click="updateEmail(item)"
              >mdi-content-save</v-icon
            >
          </v-btn>
          <v-btn icon size="small" flat @click="item.edit = !item.edit">
            <v-icon
              size="small"
              :icon="item.edit ? 'mdi-pencil-off' : 'mdi-pencil'"
            ></v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.is_admin="{ item }">
      <v-switch
        @change="updateUserRights(item.public_user_rights[0])"
        v-model="item.public_user_rights[0].is_admin"
        color="green-darken-1"
      ></v-switch>
    </template>
    <template v-slot:item.is_recruiter="{ item }">
      <v-switch
        @change="updateUserRights(item.public_user_rights[0])"
        v-model="item.public_user_rights[0].is_recruiter"
        color="green-darken-1"
      ></v-switch>
    </template>
    <template v-slot:item.is_office="{ item }">
      <v-switch
        @change="updateUserRights(item.public_user_rights[0])"
        v-model="item.public_user_rights[0].is_office"
        color="green-darken-1"
      ></v-switch>
    </template>
    <template v-slot:item.password="{ item }">
      <div v-if="item.public_user_rights[0].initial_password">
        <strong>Erstes Passwort: </strong>
        {{ item.public_user_rights[0].initial_password }}
      </div>
      <v-btn
        size="small"
        rounded="xl"
        color="info"
        @click="item.editPassword = !item.editPassword"
      >
        Passwort zurücksetzen
      </v-btn>
      <v-text-field
        :loading="loading"
        append-inner-icon="mdi-content-save"
        @click:append-inner="resetPassword(item)"
        class="mt-2"
        variant="outlined"
        density="compact"
        v-if="item.editPassword"
        v-model="item.password"
        label="Neues Passwort"
      ></v-text-field>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      loading: false,
      only_active: true,
      headers: [
        { key: "id", title: "ID", value: "id" },
        { key: "full_name", title: "Name", value: "full_name" },
        { key: "email", title: "Email", value: "email" },
        { key: "active", title: "Aktiv", value: "active" },
        { key: "is_admin", title: "Admin", value: "is_admin" },
        { key: "is_recruiter", title: "Recruiter", value: "is_recruiter" },
        { key: "is_office", title: "Büro", value: "is_office" },
        { key: "last_login", title: "Letzter Login", value: "last_login" },
        { key: "password", title: "Passwort", value: "password" },
      ],
      users: [{ public_user_rights: [{}] }],
    };
  },
  methods: {
    async getUsers() {
      this.loading = true;
      var data = await this.$store.dispatch("getAdminSupabaseData");
      data.forEach(function (item) {
        item.full_name = item.first_name + " " + item.last_name;
      });
      data.sort((a, b) => a.first_name.localeCompare(b.first_name));
      data.sort((a, b) => b.active - a.active);
      data.sort(
        (a, b) =>
          b.public_user_rights[0].is_office - a.public_user_rights[0].is_office,
      );
      this.users = data;
      if (this.only_active) {
        this.users = this.users.filter((item) => item.active);
      }
      this.loading = false;
    },
    async updateEmail(item) {
      this.loading = true;
      var response = await this.$store.dispatch("changeEmailOfUser", item);
      alert("Email wurde geändert");
      item.edit = false;
      item.email = item.new_email;
      this.loading = false;
    },
    async updateUser(item, key) {
      var queryObject = {
        id: item.id,
        table: "public_users",
        payload: {},
      };
      queryObject.payload[key] = item[key];
      var response = await this.$store.dispatch(
        "updateSupabaseEntity",
        queryObject,
      );
    },
    async updateUserRights(userRights) {
      await this.$store.dispatch("updateUserRights", userRights);
    },
    async resetPassword(item) {
      this.loading = true;
      var queryObject = {
        id: item.id,
        email: item.email,
        password: item.password,
      };
      try {
        var response = await this.$store.dispatch(
          "changePasswordOfUser",
          queryObject,
        );
        alert("Password wurde geändert");
      } catch (err) {
        alert("Password konnte nicht geändert werden");
      }

      item.editPassword = false;
      this.loading = false;
    },
  },
  async created() {
    await this.getUsers();
  },
};
</script>
