<template>
  <v-row>
    <v-col
      cols="12"
      align="center"
      class="mx-4"
      v-if="toCheckDocuments.length > 0"
    >
      <div class="text-h6">Vorgeschlagene Dokumente</div>
      <v-data-table-virtual
        :items="toCheckDocuments"
        :headers="toCheckDocumentHeaders"
      >
        <template v-slot:item.name="{ item }">
          <v-btn
            color="blue-lighten-1"
            size="x-small"
            rounded="xl"
            @click="openFile(null, null, item)"
            >{{ item.name }}</v-btn
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            :loading="loading"
            icon
            size="x-small"
            color="success"
            @click="acceptDocument(item)"
          >
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
          <v-btn
            :loading="loading"
            icon
            size="x-small"
            color="error"
            @click="(deleteDialog.dialog = true), (deleteDialog.item = item)"
          >
            <v-icon>mdi-thumb-down</v-icon>
          </v-btn>
        </template>
      </v-data-table-virtual>
    </v-col>
    <v-col cols="3" align="center">
      <v-list height="1000px">
        <v-list-item
          @click="openCategory(category)"
          :class="category.activated ? 'bg-blue-grey-lighten-2' : null"
          v-for="category in categories"
          >{{ category.name }}</v-list-item
        >
      </v-list>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="9">
      <v-row class="mt-4">
        <v-col cols="12" class="mb-2" align="center">
          <v-card>
            <v-card-text>
              <v-select
                v-model="newFiles.category_id"
                :items="allCategories"
                item-value="id"
                item-title="name"
                label="Kategorie"
              ></v-select>
              <div class="mt-2" v-if="!loading">
                <v-file-input
                  multiple
                  chips
                  density="compact"
                  label="Upload Document"
                  @change="uploadDocument"
                  :disabled="!newFiles.category_id"
                  class="custom-file-input"
                ></v-file-input>
              </div>
              <v-progress-linear v-else indeterminate></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-col>
        <v-data-table-virtual
          class="mx-4"
          :headers="headers"
          :items="chosenCategory.documents"
          hover
        >
          <template v-slot:item.name="{ item }">
            <v-btn
              size="x-small"
              variant="text"
              color="blue-darken-4"
              class="text-decoration-underline"
              rounded="xl"
              @click="openFile(item)"
              >{{ item.name }}</v-btn
            ></template
          >
          <template v-slot:item.actions="{ item }"
            ><v-icon
              @click="(deleteDialog.dialog = true), (deleteDialog.item = item)"
              >mdi-delete</v-icon
            >
            <v-icon
              @click="
                editDialog.dialog = true;
                editDialog.item = item;
                editDialog.item.oldName = item.name;
              "
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table-virtual>
      </v-row>
    </v-col>
  </v-row>
  <v-dialog v-model="editDialog.dialog" width="600">
    <v-card>
      <v-card-title>Dokument editieren</v-card-title>
      <v-card-text>
        <v-text-field
          label="Name"
          variant="outlined"
          density="compact"
          v-model="editDialog.item.name"
        ></v-text-field>
        <v-text-field
          label="Datum"
          variant="outlined"
          density="compact"
          type="date"
          v-model="editDialog.item.created_at"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="updateFile(editDialog.item)"
          :loading="editDialog.loading"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="uploadSuccess" color="success">
    Dokument erfolgreich hochgeladen
  </v-snackbar>
  <warning
    :title="'Löschen bestätigen'"
    :item="deleteDialog.item"
    :message="'Dokument ablehnen und damit endgültig löschen?'"
    v-model="deleteDialog.dialog"
    @close="deleteDialog.dialog = false"
    @confirm="
      deleteFile(deleteDialog.item);
      deleteDialog.dialog = false;
    "
  ></warning>
</template>
<script setup>
import warning from "@/components/CommonComponents/Warning.vue";
const props = defineProps({
  employeeInfo: Object,
  users: Array,
  role: Object,
});
</script>

<script>
export default {
  data() {
    return {
      deleteDialog: {
        dialog: false,
        item: {},
      },
      uploadSuccess: false,
      headers: [
        { title: "Name", value: "name" },
        { title: "Datum", value: "showDate" },
        { title: "Aktionen", value: "actions" },
      ],
      editDialog: {
        dialog: false,
        item: {},
        loading: false,
        category: null,
      },
      loading: false,
      categories: [],
      toCheckDocuments: [],
      toCheckDocumentHeaders: [
        { title: "Name", value: "name" },
        { title: "Datum", value: "showDate" },
        { title: "Kategorie", value: "category" },
        { title: "Mitarbeiter", value: "employees.name" },
        { title: "Vorgeschlagen von", value: "public_users.full_name" },
        { title: "Aktionen", value: "actions" },
      ],
      allCategories: [],
      chosenCategory: {},
      newFiles: {},
    };
  },
  methods: {
    async resetNewFile() {
      this.newFiles = {
        employee_id: this.employeeInfo.id,
        name: null,
        category_id: null,
      };
    },
    async openCategory(category) {
      this.editDialog.category = category;
      this.categories.forEach(function (item) {
        if (item.id == category.id) {
          item.activated = true;
        } else {
          item.activated = false;
        }
      });
      this.chosenCategory = category;
    },
    async getFiles() {
      this.categories = [];
      this.toCheckDocuments = [];
      this.allCategories = [];
      var idArray = [];
      var employeeId = this.role.employeeId;
      var userEmployeeId = this.role.userEmployeeId;

      this.role.documentCategories.forEach((item) => {
        let checkRole = item.public_user_role_document_matching;
        for (var i = 0; i < checkRole.length; i++) {
          if (
            (checkRole[i].show_own_data && userEmployeeId == employeeId) ||
            checkRole[i].show_nightshift_data ||
            checkRole[i].show_all_data
          ) {
            idArray.push(item.id);
            var checkName = item.name;
            if (!this.allCategories.find((item) => item.name == checkName)) {
              this.allCategories.push(item);
            }
          }
          if (
            (checkRole[i].suggest_own_data && userEmployeeId == employeeId) ||
            checkRole[i].suggest_nightshift_data ||
            checkRole[i].suggest_all_data
          ) {
            var checkName = item.name;
            if (!this.allCategories.find((item) => item.name == checkName)) {
              this.allCategories.push(item);
            }
          }
        }
      });

      var queryObject = {
        select:
          "*,employee_documents!inner(*,employees(name),public_users!public_employee_documents_suggested_by_fkey(full_name))",
        table: "employee_document_categories",
        where: [
          {
            type: "eq",
            key: "employee_documents.employee_id",
            value: this.employeeInfo.id,
          },
          {
            type: "in",
            key: "id",
            value: idArray,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      for (var i = 0; i < response.data.length; i++) {
        var category = response.data[i];
        category.documents = [];
        for (var j = 0; j < category.employee_documents.length; j++) {
          var document = category.employee_documents[j];
          var date = new Date(document.created_at);
          document.created_at = date.toISOString().split("T")[0];
          var editable = false;
          var right = this.role.documentCategories.find(
            (item) => category.id == item.id,
          );
          for (
            var k = 0;
            k < right.public_user_role_document_matching.length;
            k++
          ) {
            var checkRight = right.public_user_role_document_matching[k];
            if (
              checkRight.edit_all_data ||
              checkRight.edit_nightshift_data ||
              (checkRight.edit_own_data &&
                this.role.employeeId == this.role.userEmployeeId)
            ) {
              var editable = true;
            }
          }
          document.showDate = await this.$store.dispatch("formatDate", date);
          if (document.suggested_by && !document.is_request_accepted) {
            document.category = category.name;
            if (editable) {
              this.toCheckDocuments.push(document);
            }
          } else {
            category.documents.push(document);
          }
        }
        category.documents.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        );
      }
      this.categories = response.data.filter(
        (item) => item.documents.length > 0,
      );
    },
    async updateFile(file) {
      this.editDialog.loading = true;
      var oldPath = `${this.employeeInfo.id}/${file.category_id}/${file.oldName}`;
      oldPath = oldPath
        .replace("ä", "ae")
        .replace("ö", "oe")
        .replace("ü", "ue")
        .replace("ß", "ss")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      var newPath = `${this.employeeInfo.id}/${file.category_id}/${file.name}`;
      newPath = newPath
        .replace("ä", "ae")
        .replace("ö", "oe")
        .replace("ü", "ue")
        .replace("ß", "ss")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      var queryObject = {
        oldPath: oldPath,
        newPath: newPath,
        bucket: "employees",
      };

      var response = await this.$store.dispatch(
        "renameStorageFile",
        queryObject,
      );
      if (!response.error) {
        var queryObject = {
          table: "employee_documents",
          id: file.id,
          payload: {
            name: file.name,
            created_at: file.created_at,
          },
        };

        await this.$store.dispatch("updateSupabaseEntity", queryObject);

        this.editDialog.loading = false;
        this.editDialog.dialog = false;
        this.editDialog.item = {};
        await this.getFiles();
        this.openCategory(this.categories[0]);
        this.openCategory(this.editDialog.category);
      } else {
        this.editDialog.loading = false;
        alert("Fehler beim Umbenennen des Dokuments");
      }
    },
    async openFile(file) {
      var queryObject = {
        bucket: "employees",
        file: `${file.employee_id}/${file.category_id}/${file.name}`,
      };
      var url = await this.$store.dispatch("getStorageFileLink", queryObject);
      window.open(url, "_blank");
    },
    async acceptDocument(item) {
      this.loading = true;
      var queryObject = {
        table: "employee_documents",
        id: item.id,
        payload: {
          is_request_accepted: true,
          created_by: item.suggested_by,
          accepted_by: this.$store.state.userInfo.id,
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.getFiles();
      this.loading = false;
    },
    async deleteFile(file) {
      this.loading = true;
      var fileName =
        file.employee_id + "/" + file.category_id + "/" + file.name;
      await this.$store.dispatch("removeStorageFile", {
        bucket: "employees",
        file: fileName,
      });
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "employee_documents",
        id: file.id,
      });
      await this.getFiles();
      if (this.categories.length > 0) {
        this.openCategory(this.categories[0]);
      } else {
        this.chosenCategory = {};
      }
      this.loading = false;
    },
    async uploadDocument(event) {
      this.loading = true;
      for (var i = 0; i < event.target.files.length; i++) {
        var file = event.target.files[i];
        var documentQueryObject = {
          bucket: "employees",
          filePath:
            this.employeeInfo.id +
            "/" +
            this.newFiles.category_id +
            "/" +
            file.name,
          file: file,
        };
        var response = await this.$store.dispatch(
          "uploadStorageFile",
          documentQueryObject,
        );

        var name = response.data.path.split("/")[2];
        var payload = JSON.parse(JSON.stringify(this.newFiles));
        payload.name = name;

        var right = this.role.documentCategories.find(
          (item) => this.newFiles.category_id == item.id,
        );

        var createdRight = false;
        for (
          var i = 0;
          i < right.public_user_role_document_matching.length;
          i++
        ) {
          var checkRight = right.public_user_role_document_matching[i];
          if (
            checkRight.edit_all_data ||
            checkRight.edit_nightshift_data ||
            (checkRight.edit_own_data &&
              this.role.employeeId == this.role.userEmployeeId)
          ) {
            createdRight = true;
          }
        }

        if (createdRight) {
          payload.created_by = this.$store.state.userInfo.id;
        } else {
          payload.suggested_by = this.$store.state.userInfo.id;
        }

        var queryObject = {
          table: "employee_documents",
          payload: payload,
        };
        let documentResponse = await this.$store.dispatch(
          "createSupabaseEntity",
          queryObject,
        );
        if (!documentResponse.error) {
          this.uploadSuccess = true;
          this.sendEmailToUser(file);
        }
      }
      this.resetNewFile();
      this.getFiles();
      this.loading = false;
    },
    async sendEmailToUser(file) {
      var category = this.newFiles.category_id;

      var role = await this.$store.dispatch("getSupabaseEntity", {
        select:
          "*,public_user_roles!inner(*,public_user_role_document_matching!inner(*))",
        table: "public_user_role_matching",
        where: [
          {
            type: "eq",
            key: "user_id",
            value: this.employeeInfo.user_id,
          },
          {
            type: "eq",
            key: "public_user_roles.public_user_role_document_matching.document_category_id",
            value: category,
          },
        ],
      });
      if (role.data.length > 0) {
        var emailObject = {
          to: this.employeeInfo.email,
          bcc: "cto@ta-management.de",
          subject: "Neues Dokument in Ihrem Bellcaptain-Portal",
          body: `
            <!DOCTYPE html>
            <html lang="de">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Neues Dokument</title>
            </head>
            <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; max-width: 600px; margin: 0 auto; padding: 20px;">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" style="background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 5px;">
                    <tr>
                        <td style="padding: 30px;">
                            <div style="margin-bottom: 20px;">
                                <h1 style="color: #2c3e50; margin: 0 0 20px 0;">Neues Dokument verfügbar</h1>
                            </div>
                            <div style="margin-bottom: 15px;">
                                Hallo ${this.employeeInfo.first_name} ${this.employeeInfo.last_name},
                            </div>
                            <div style="margin-bottom: 15px;">
                                Ein neues Dokument wurde für Sie hochgeladen:
                            </div>
                            <div style="margin-bottom: 20px; font-weight: bold; color: #3498db;">
                                ${file.name}
                            </div>
                            <div style="margin-bottom: 15px;">
                                Bitte loggen Sie sich in Ihr Konto ein, um das Dokument einzusehen.
                            </div>
                            <div style="margin-bottom: 15px;">
                                Sie können das Portal unter folgendem Link erreichen:
                            </div>
                            <div style="margin-bottom: 20px;">
                                <a href="https://portal.bellcaptain.de/" style="color: #3498db; text-decoration: underline;">https://portal.bellcaptain.de/</a>
                            </div>
                            <div style="margin-top: 30px;">
                                Mit freundlichen Grüßen,<br>
                                Ihr Bellcaptain Team
                            </div>
                        </td>
                    </tr>
                </table>
            </body>
            </html>
          `,
        };
        const response = await this.$store.dispatch(
          "sendEmailPerBackend",
          emailObject,
        );
      }
    },
  },
  async created() {
    this.resetNewFile();
    await this.getFiles();
  },
};
</script>
