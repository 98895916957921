<template>
  <v-card class="ma-2">
    <v-tabs v-model="tab">
      <v-tab value="users">Nutzer</v-tab>
      <v-tab value="roles">Rollen</v-tab>
      <v-tab value="device">Gerät</v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="users">
          <Users></Users>
        </v-window-item>
        <v-window-item value="roles">
          <Roles></Roles>
        </v-window-item>
        <v-window-item value="device">
          <Device></Device>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Users from "@/components/Settings/Admin/Users.vue";
import Roles from "@/components/Settings/Admin/Roles.vue";
import Device from "@/components/Settings/Admin/Device.vue";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      tab: "users",
    };
  },
  methods: {},
  async created() {},
};
</script>
