<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card
        v-bind="props"
        :elevation="isHovering ? '10' : '2'"
        rounded="xl"
        class="my-3"
      >
        <v-card-title>Arbeitsrelevante Infos</v-card-title>
        <v-row class="px-6">
          <v-col cols="12" align="center" class="">
            <v-btn-toggle
              variant="outlined"
              v-model="candidateInfo.experience_level_id"
              divided
            >
              <v-btn
                size="x-small"
                v-for="btn in selections.experienceLevel"
                :value="btn.id"
                class="mobile"
                >{{ btn.name }}</v-btn
              >
            </v-btn-toggle>
          </v-col>
          <v-col cols="12">
            <v-select
              variant="outlined"
              density="compact"
              :items="selections.timeModel"
              item-title="name"
              item-value="id"
              label="Zeitmodell"
              v-model="candidateInfo.time_model_id"
              prepend-icon="mdi-clock"
            ></v-select>
          </v-col>
          <v-col cols="12" class="mt-n6">
            <v-text-field
              density="compact"
              variant="outlined"
              label="Verfügbar ab"
              v-model="candidateInfo.available_at"
              prepend-icon="mdi-calendar"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" class="mt-n6" v-if="false">
            <v-text-field
              variant="outlined"
              density="compact"
              label="Aktuelle Position"
              v-model="candidateInfo.current_position"
              prepend-icon="mdi-briefcase"
              append-icon="mdi-briefcase-off"
              @click:append="candidateInfo.current_position = 'Arbeitssuchend'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" class="mt-n6" v-if="false">
            <v-text-field
              variant="outlined"
              density="compact"
              prepend-icon="mdi-domain"
              label="Aktuelles Unternehmen"
              v-model="candidateInfo.current_company"
            ></v-text-field>
          </v-col>
          <v-col cols="12" align="center">
            <v-row class="mt-n10">
              <v-col v-if="!$vuetify.display.mobile" cols="3" class="mt-3">
                <span>Deutsch</span>
              </v-col>
              <v-col cols="12" sm="9">
                <v-rating
                  color="yellow-darken-4"
                  :item-labels="
                    $vuetify.display.mobile ? ['', '', 'Deutsch', '', ''] : null
                  "
                  v-model="candidateInfo.german_language_level"
                ></v-rating>
              </v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col v-if="!$vuetify.display.mobile" cols="3" class="mt-3">
                <span>Englisch</span>
              </v-col>
              <v-col cols="12" sm="9">
                <v-rating
                  color="yellow-darken-4"
                  :item-labels="
                    $vuetify.display.mobile
                      ? ['', '', 'Englisch', '', '']
                      : null
                  "
                  v-model="candidateInfo.english_language_level"
                ></v-rating>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            size="small"
            @click="updateProfile()"
            v-if="candidateInfo.first_name && candidateInfo.last_name"
            :loading="loading"
          >
            <span v-if="candidateInfo.id">Update</span>
            <span v-else>Create</span>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup>
import { mapState } from "vuex";
const props = defineProps({
  candidateInfo: Object,
});
</script>

<script>
export default {
  data() {
    return {
      loading: false,
      selections: {
        timeModel: [],
        experienceLevel: [],
      },
      evaluationInfo: [],
    };
  },
  computed: {
    ...mapState({
      cvDataObject: (state) => state.cvDataObject,
    }),
  },
  watch: {
    cvDataObject(newValue, oldValue) {
      if (newValue) {
      }
    },
  },
  methods: {
    async getSelections() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_jobs_models",
      });
      this.selections.timeModel = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_experience_level",
      });
      this.selections.experienceLevel = response.data;
    },
    async updateProfile() {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(this.candidateInfo));
      delete payload.recruiting_job_candidates;
      delete payload.statusId;
      delete payload.events;
      delete payload.specialNotes;
      delete payload.employees;
      delete payload.sipgate_calls;
      var queryObject = {
        table: "recruiting_candidates",
        id: this.candidateInfo.id,
        payload: payload,
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      await this.$store.dispatch("sleep", 200);
      this.loading = false;
    },
    openPhoneLink(phone) {
      const phoneLink = `tel:${phone}`;
      window.open(phoneLink);
    },
    openEmailLink(email) {
      const mailtoLink = `mailto:${email}`;
      window.open(mailtoLink);
    },
  },
  async created() {
    this.getSelections();
  },
};
</script>
<style>
.mobile {
  font-size: 6pt;
}
</style>
