<template>
  <v-card>
    <v-card-text v-if="compCandidateInfo && compCandidateInfo.id">
      <v-expansion-panels>
        <v-expansion-panel title="Evaluationen"
          ><v-expansion-panel-text>
            <Evaluations :candidateInfo="compCandidateInfo"></Evaluations>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Dateien"
          ><v-expansion-panel-text>
            <FileComponent
              bucket="candidates"
              :id="compCandidateInfo.id"
            ></FileComponent>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Checkliste"
          ><v-expansion-panel-text>
            <Checklist :candidateInfo="compCandidateInfo"></Checklist>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-text v-else>
      Mitarbeiter ist mit keinem Kandidaten verknüpft
      <v-text-field
        append-icon="mdi-magnify"
        @click:append="getPotentialCandidates()"
        label="Suche nach Kandidaten"
        v-model="candidateName"
      ></v-text-field>
      <v-btn
        :loading="loading"
        rounded="lg"
        class="mx-1"
        color="indigo-darken-3"
        v-for="can in potentialCandidates"
        @click="updateCandidateId(can.id)"
      >
        {{ can.first_name + " " + can.last_name }}</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script setup>
import Evaluations from "@/components/Candidates/Evaluations.vue";
import Checklist from "@/components/Candidates/Checklist.vue";
import FileComponent from "@/components/CommonComponents/Files.vue";
const props = defineProps({
  candidateInfo: Object,
  employeeInfo: Object,
});
</script>

<script>
export default {
  data() {
    return {
      compCandidateInfo: {},
      candidateName: null,
      potentialCandidates: [],
      candidateId: null,
      loading: false,
    };
  },
  methods: {
    async getPotentialCandidates() {
      var queryObject = {
        table: "recruiting_candidates",
        where: [{ type: "ilike", key: "last_name", value: this.candidateName }],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.potentialCandidates = response.data;
    },
    async updateCandidateId(canId) {
      this.loading = true;
      var queryObject = {
        table: "employees",
        payload: {
          candidate_id: canId,
        },
        id: this.employeeInfo.id,
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);

      var queryObject = {
        table: "recruiting_candidates",
        select: "*,recruiting_job_candidates(*)",
        where: [{ type: "eq", key: "id", value: canId }],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.compCandidateInfo = response.data[0];
      this.$store.state.employeeChangeCheck = true;
      this.loading = false;
    },
  },

  async created() {
    this.compCandidateInfo = this.candidateInfo;
    this.candidateName = this.employeeInfo.last_name;
    await this.getPotentialCandidates();
  },
};
</script>
