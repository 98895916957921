<template>
  <v-row class="ma-4">
    <v-col v-for="item in checklist" sm="6" md="6" lg="6" xl="6">
      <v-checkbox
        @change="updateChecklist(item)"
        :label="item.name"
        v-model="item.checked"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script setup>
const props = defineProps({
  candidateInfo: Object,
});
</script>
<script>
export default {
  data() {
    return {
      checklist: [],
    };
  },
  watch: {},
  methods: {
    async getChecklist() {
      var queryObject = {
        select: "*,recruiting_candidate_checklist(*)",
        table: "recruiting_checklist",
        where: [
          {
            type: "eq",
            key: "recruiting_candidate_checklist.candidate_id",
            value: this.candidateInfo.id,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach(function (item) {
        if (item.recruiting_candidate_checklist[0]) {
          item.checked = item.recruiting_candidate_checklist[0].checked;
          item.exists = true;
        }
      });
      this.checklist = response.data;
    },
    async updateChecklist(item) {
      if (item.exists) {
        var queryObject = {
          table: "recruiting_candidate_checklist",
          id: item.id,
          payload: {
            checked: item.checked,
          },
        };
        this.$store.dispatch("updateSupabaseEntity", queryObject);
      } else {
        var queryObject = {
          table: "recruiting_candidate_checklist",
          payload: {
            candidate_id: this.candidateInfo.id,
            checklist_item_id: item.id,
            checked: item.checked,
          },
        };
        this.$store.dispatch("createSupabaseEntity", queryObject);
      }
    },
  },
  async created() {
    this.getChecklist();
  },
};
</script>
