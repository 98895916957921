<template>
  <v-row class="ma-4">
    <v-col cols="6">
      <v-text-field label="Suche" v-model="search" clearable></v-text-field>
    </v-col>
    <v-col cols="6" v-if="false">
      <v-checkbox label="Alle anzeigen" v-model="showAll"></v-checkbox>
    </v-col>
    <v-col cols="12" v-if="showAll">
      <div class="text-h5">{{ duplicates.length }} mögliche Dubletten</div>
    </v-col>
    <template v-for="(dup, index) in duplicates">
      <v-col
        cols="12"
        v-if="
          !search ||
          (showAll &&
            search &&
            dup.concat.toLowerCase().indexOf(search.toLowerCase()) > -1)
        "
      >
        <v-card>
          <v-card-title>{{ dup.concat }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4" v-for="can in dup.candidates">
                <v-card>
                  <v-card-title>
                    <v-chip class="mb-2">{{ can.status }}</v-chip>
                    <v-btn
                      class="mb-2"
                      @click="fillCandidateData(can, dup.mergedCandidate)"
                    >
                      <v-icon>mdi-arrow-collapse-right</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-btn class="ma-4" @click="goToCandidate(can)">Link</v-btn>
                    <v-text-field
                      label="Email"
                      density="compact"
                      variant="outlined"
                      readonly
                      v-model="can.email"
                      append-icon="mdi-arrow-collapse-right"
                      @click:append="dup.mergedCandidate.email = can.email"
                    ></v-text-field>
                    <v-text-field
                      label="Phone"
                      density="compact"
                      variant="outlined"
                      readonly
                      v-model="can.phone"
                      append-icon="mdi-arrow-collapse-right"
                      @click:append="dup.mergedCandidate.phone = can.phone"
                    ></v-text-field>
                    <v-text-field
                      label="Verfügbar ab"
                      density="compact"
                      variant="outlined"
                      readonly
                      v-model="can.available_at"
                      append-icon="mdi-arrow-collapse-right"
                      @click:append="
                        dup.mergedCandidate.available_at = can.available_at
                      "
                    ></v-text-field>
                    <v-text-field
                      label="Staatsangehörigkeit"
                      density="compact"
                      variant="outlined"
                      readonly
                      v-model="can.nationality"
                      append-icon="mdi-arrow-collapse-right"
                      @click:append="
                        dup.mergedCandidate.nationality = can.nationality
                      "
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card-title>
                  <v-chip v-if="dup.mergedCandidate.id" class="mb-2 mr-12">{{
                    dup.mergedCandidate.status
                  }}</v-chip>
                  <v-btn
                    :disabled="!dup.mergedCandidate.id"
                    color="blue-darken-1"
                    class="mb-2"
                    :loading="loading"
                    @click="mergeDuplicates(dup)"
                  >
                    <v-icon>mdi-table-merge-cells</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-btn class="ma-4" @click="goToCandidate(can)">Link</v-btn>
                  <v-text-field
                    label="Email"
                    density="compact"
                    variant="outlined"
                    v-model="dup.mergedCandidate.email"
                    readonly
                  ></v-text-field>
                  <v-text-field
                    label="Phone"
                    density="compact"
                    variant="outlined"
                    v-model="dup.mergedCandidate.phone"
                    readonly
                  ></v-text-field>
                  <v-text-field
                    label="Verfügbar ab"
                    density="compact"
                    variant="outlined"
                    v-model="dup.mergedCandidate.available_at"
                    readonly
                  ></v-text-field>
                  <v-text-field
                    label="Staatsangehörigkeit"
                    density="compact"
                    variant="outlined"
                    v-model="dup.mergedCandidate.nationality"
                    readonly
                  ></v-text-field>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script setup></script>
<script>
export default {
  data() {
    return {
      search: null,
      duplicates: [],
      loading: false,
      showAll: true,
    };
  },
  methods: {
    async getDuplicates() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "check_duplicates",
      });
      response.data.forEach(function (item) {
        item.mergedCandidate = {};
      });
      this.duplicates = response.data;
    },
    async fillCandidateData(sourceCan, targetCan) {
      targetCan.id = sourceCan.id;
      targetCan.status = sourceCan.status;
      targetCan.email = sourceCan.email;
      targetCan.phone = sourceCan.phone;
      targetCan.available_at = sourceCan.available_at;
      targetCan.nationality = sourceCan.nationality;
    },
    async goToCandidate(item) {
      const routeData = this.$router.resolve({
        path: "/Kandidaten/" + item.id,
      });
      window.open(routeData.href, "_blank");
    },
    async mergeDuplicates(dup) {
      this.loading = true;
      var mainId = dup.mergedCandidate.id;
      var mainApplicationId = null;
      var candidateArray = [];
      var jobCandidateArray = [];
      var upsertArray = [];

      // Update main candidate

      var queryObject = {
        table: "recruiting_candidates",
        id: mainId,
        payload: {
          email: dup.mergedCandidate.email,
          phone: dup.mergedCandidate.phone,
          available_at: dup.mergedCandidate.available_at,
          nationality: dup.mergedCandidate.nationality,
        },
      };

      await this.$store.dispatch("updateSupabaseEntity", queryObject);

      // Get all candidates who has to be merged
      dup.candidates.forEach(function (item) {
        if (item.id == mainId) {
          mainApplicationId = item.applicationId;
        }
        if (item.id != mainId) {
          candidateArray.push(item.id);
          if (item.applicationId) {
            jobCandidateArray.push(item.applicationId);
          }

          upsertArray.push({
            id: item.id,
            first_name: "MERGED",
            last_name: "MERGED",
            soft_delete: true,
            email: null,
          });
        }
      });

      // Get all notes which has to be updated and update them
      if (jobCandidateArray.length > 0) {
        var queryObject = {
          table: "notes",
          where: [
            { type: "in", key: "job_candidate_id", value: jobCandidateArray },
          ],
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        response.data.forEach(function (item) {
          item.job_candidate_id = mainApplicationId;
        });
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: "notes",
          payload: response.data,
        });
      }

      // Get all emails which has to be updated and update them
      var queryObject = {
        table: "emails",
        where: [{ type: "in", key: "entity_id", value: candidateArray }],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      if (response.data.length > 0) {
        response.data.forEach(function (item) {
          item.entity_id = mainId;
        });
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: "emails",
          payload: response.data,
        });
      }

      // Get all files which has to be updated and update them
      var queryObject = {
        bucket: this.bucket,
        folder: this.entityId,
      };
      for (var i = 0; i < candidateArray.length; i++) {
        var candidateId = candidateArray[i];
        var queryObject = {
          bucket: "candidates",
          folder: candidateId,
        };
        var files = await this.$store.dispatch(
          "getStorageFolderFiles",
          queryObject,
        );
        if (files.data) {
          for (var j = 0; j < files.data.length; j++) {
            var queryObject = {
              oldPath: candidateId + "/" + files.data[j].name,
              newPath: mainId + "/" + files.data[j].name,
              bucket: "candidates",
            };
            await this.$store.dispatch("renameStorageFile", queryObject);
          }
        }
      }

      // Update the candidate to MERGED

      var response = await this.$store.dispatch("upsertSupabaseEntity", {
        table: "recruiting_candidates",
        payload: upsertArray,
      });

      await this.getDuplicates();
      this.loading = false;
    },
  },
  async created() {
    this.getDuplicates();
  },
};
</script>
