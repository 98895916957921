<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-form @submit.prevent="saveCandidate(candidateObject)">
        <v-card
          v-bind="props"
          :elevation="isHovering ? '12' : '2'"
          rounded="xl"
          class="ma-2"
        >
          <v-card-title>Grunddaten</v-card-title>
          <v-card-text class="pa-3">
            <v-row>
              <v-col cols="12" sm="2">
                {{ jobId }}
                <v-btn-toggle v-model="candidateObject.gender">
                  <v-btn :value="true">
                    <v-icon>mdi-gender-male</v-icon>
                  </v-btn>
                  <v-btn :value="false">
                    <v-icon>mdi-gender-female</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="Vorname"
                  v-model="candidateObject.first_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="Nachname"
                  v-model="candidateObject.last_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="Email"
                  prepend-icon="mdi-at"
                  v-model="candidateObject.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="Telefon"
                  prepend-icon="mdi-phone"
                  v-model="candidateObject.phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  type="date"
                  label="Geburtsdatum"
                  prepend-icon="mdi-cake"
                  v-model="candidateObject.birth_date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  label="Ort"
                  prepend-icon="mdi-map-marker"
                  v-model="candidateObject.city"
                ></v-text-field>
              </v-col>
              <v-col cols="12" align="center">
                <v-rating
                  length="3"
                  v-model="rating"
                  color="indigo-darken-2"
                ></v-rating>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </v-hover>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
const props = defineProps({
  jobId: String,
});
</script>
<script>
export default {
  data() {
    return {
      rating: null,
      valid: false,
      candidateObject: {},
      rules: [
        (value) => {
          if (value) return true;
          return "Dieses Feld muss ausgefüllt werden";
        },
      ],
      users: [],
    };
  },
  methods: {
    async saveCandidate(candidateObject) {
      var queryObject = {
        table: "recruiting_candidates",
        payload: candidateObject,
      };
      var candidateId = await this.$store.dispatch(
        "createSupabaseEntity",
        queryObject,
      );
      var queryObject = {
        payload: {
          candidate_id: candidateId,
          job_id: this.jobId,
          status_id: 1,
          rating: this.rating,
        },
        table: "recruiting_job_candidates",
      };
      await this.$store.dispatch("createSupabaseEntity", queryObject);
    },
  },
  async created() {
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "public_users",
    });
    this.users = response.data;
  },
};
</script>
<style>
.noteHeight {
  height: 38vh;
  overflow: auto;
  overflow-y: auto;
}
</style>
