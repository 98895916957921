<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card class="ma-2 pa-2" elevation="10">
          <v-card-title>Schichten der letzten 6 Monate</v-card-title>
          <v-card-text>
            <v-col cols="12" v-for="emp in employeeRecord" class="my-n2">
              <div>
                <router-link :to="'/Mitarbeiter/' + emp.employee_id"
                  >{{ emp.employee_name }} ({{ emp.status }})
                </router-link>
              </div>
              <v-progress-linear
                :model-value="emp.count"
                :max="employeeRecord[0].count"
                color="blue-darken-4"
                height="15"
              >
                <template v-slot:default="{ value }">
                  <strong :class="value > 50 ? 'text-white' : null">{{
                    Math.ceil(emp.count) + " (Gesamt)"
                  }}</strong>
                </template>
              </v-progress-linear>
              <v-progress-linear
                v-if="emp.senior_count > 0"
                :model-value="emp.senior_count"
                :max="employeeRecord[0].count"
                color="blue-darken-2"
                height="15"
              >
                <template v-slot:default="{ value }">
                  <strong :class="value > 50 ? 'text-white' : null">{{
                    Math.ceil(emp.senior_count) + " (Senior)"
                  }}</strong>
                </template>
              </v-progress-linear>
              <v-progress-linear
                v-if="emp.clerk_count > 0"
                :model-value="emp.clerk_count"
                :max="employeeRecord[0].count"
                color="blue-darken-1"
                height="15"
              >
                <template v-slot:default="{ value }">
                  <strong :class="value > 50 ? 'text-white' : null">{{
                    Math.ceil(emp.clerk_count) + " (Clerk)"
                  }}</strong>
                </template>
              </v-progress-linear>
              <v-progress-linear
                v-if="emp.training_count > 0"
                :model-value="emp.training_count"
                :max="employeeRecord[0].count"
                color="blue-darken-1"
                height="15"
              >
                <template v-slot:default="{ value }">
                  <strong :class="value > 50 ? 'text-white' : null">{{
                    Math.ceil(emp.training_count) + " (Einarbeitung)"
                  }}</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="ma-2 pa-2" elevation="10">
          <v-card-title>Mögliche Mitarbeiter</v-card-title>
          <v-card-text>
            <v-list>
              <template
                v-for="emp in hotelObject.employees_hotels"
                :key="emp.employee_id"
              >
                <v-list-item v-if="emp.employees.status == 'Aktiv'">
                  <v-list-item-content>
                    <v-list-item-title>
                      <router-link
                        class="mr-2"
                        :to="'/Mitarbeiter/' + emp.employee_id"
                        >{{ emp.employees.name }}
                      </router-link>
                      <v-chip size="small" v-if="emp.is_senior">Senior</v-chip>
                      <v-chip size="small" v-if="emp.is_clerk">Clerk</v-chip>
                      <v-chip
                        variant="elevated"
                        color="red"
                        v-if="emp.is_blocked"
                        >Gesperrt!</v-chip
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="ma-8" elevation="10">
          <v-card-text>
            <v-row>
              <v-col cols="3" align="left">
                <v-btn @click="changeMonth('minus')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" align="center">
                <div class="text-h5 my-2">
                  Schichtplan für {{ shiftPlan.month }}.{{ shiftPlan.year }}
                </div>
              </v-col>
              <v-col cols="3" align="right">
                <v-btn @click="changeMonth('plus')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-data-table-virtual
              density="compact"
              :headers="shiftPlan.headers"
              :items="shiftPlan.shifts"
            >
              <template #item.employee="{ item }">
                <v-autocomplete
                  clearable
                  :prepend-icon="
                    item.hotel_booking && !item.employee ? 'mdi-alert' : null
                  "
                  :disabled="!item.hotel_booking"
                  class="mt-2"
                  v-model="item.employee"
                  :items="employeeRecord"
                  item-title="employee_name"
                  item-value="employee_id"
                  variant="outlined"
                  density="compact"
                ></v-autocomplete>
              </template>
              <template #item.hotel_booking="{ item }">
                <v-checkbox
                  label="Hotelbuchung"
                  v-model="item.hotel_booking"
                ></v-checkbox>
              </template>
            </v-data-table-virtual>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Your template code goes here -->
  </div>
</template>

<script setup>
const props = defineProps({ hotelObject: { type: Object, required: true } });
</script>
<script>
export default {
  name: "Shifts",
  data() {
    return {
      shifts: [],
      employeeRecord: [],
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      shiftPlan: {
        month: null,
        year: null,
        days: null,
        headers: [
          {
            title: "Datum",
            align: "start",
            sortable: false,
            value: "date",
          },
          {
            title: "Reguläre Hotelbuchung",
            align: "center",
            sortable: false,
            value: "hotel_booking",
          },
          {
            title: "Mitarbeiter",
            align: "start",
            sortable: false,
            value: "employee",
          },
        ],
        shifts: [],
      },
      // Your data properties go here
    };
  },
  methods: {
    async changeMonth(type) {
      if (type === "plus") {
        this.shiftPlan.month++;
        if (this.shiftPlan.month > 12) {
          this.shiftPlan.month = 1;
          this.shiftPlan.year++;
        }
      } else {
        this.shiftPlan.month--;
        if (this.shiftPlan.month < 1) {
          this.shiftPlan.month = 12;
          this.shiftPlan.year--;
        }
      }
      this.shiftPlan.shifts = [];
      this.createShiftPlan();
    },
    async createShiftPlan() {
      if (!this.shiftPlan.month) {
        var date = new Date();
      } else {
        var date = new Date(this.shiftPlan.year, this.shiftPlan.month - 1, 1);
      }
      this.shiftPlan.month = date.getMonth() + 1;
      this.shiftPlan.year = date.getFullYear();
      this.shiftPlan.days = new Date(
        this.shiftPlan.year,
        this.shiftPlan.month,
        0,
      ).getDate();
      var start = new Date(this.shiftPlan.year, this.shiftPlan.month - 1, 2);
      var end = new Date(this.shiftPlan.year, this.shiftPlan.month, 0);
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "shifts",
        select: "*,employees(name)",
        where: [
          { type: "gte", key: "date", value: start.toISOString() },
          { type: "lte", key: "date", value: end.toISOString() },
          { type: "eq", key: "hotel_id", value: this.hotelObject.id },
        ],
      });
      var shifts = response.data;
      var shiftDateObject = {};
      shifts.forEach((shift) => {
        var date = new Date(shift.date);
        date.setDate(date.getDate() - 1);
        shiftDateObject[date.toISOString().slice(0, 10)] = shift;
      });
      for (let i = 1; i <= this.shiftPlan.days; i++) {
        var date = new Date(this.shiftPlan.year, this.shiftPlan.month - 1, i);
        var dateISO = date.toISOString().slice(0, 10);
        var weekday = this.weekdays[date.getDay()];

        var hotelBooking = this.hotelObject.shift_days?.find(
          (day) => day === date.getDay(),
        );
        this.shiftPlan.shifts.push({
          date: date.toLocaleDateString() + ", " + weekday,
          hotel_booking: hotelBooking >= 0 ? true : false,
          employee: shiftDateObject[dateISO]
            ? shiftDateObject[dateISO].employees.name
            : null,
        });
      }
    },
    // Your methods go here
    createEmployeeShifts() {
      var employeeCheck = {};
      this.shifts.forEach((shift) => {
        if (!employeeCheck[shift.employee_id]) {
          employeeCheck[shift.employee_id] = {
            employee_id: shift.employee_id,
            status: shift.employees.status,
            employee_name: shift.employees
              ? shift.employees.name
              : "Nicht gematched",
            count: 1,
          };
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count = 1;
            employeeCheck[shift.employee_id].clerk_count = 0;
            employeeCheck[shift.employee_id].training_count = 0;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].senior_count = 0;
            employeeCheck[shift.employee_id].clerk_count = 1;
            employeeCheck[shift.employee_id].training_count = 0;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].senior_count = 0;
            employeeCheck[shift.employee_id].clerk_count = 0;
            employeeCheck[shift.employee_id].training_count = 1;
          }
        } else {
          employeeCheck[shift.employee_id].count++;
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count++;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].clerk_count++;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].training_count++;
          }
        }
      });
      this.employeeRecord = Object.values(employeeCheck);
      this.employeeRecord.sort((a, b) => b.count - a.count);
      console.log(this.employeeRecord);
    },
  },
  mounted() {
    this.shifts = this.hotelObject.shifts;
    console.log(this.shifts);
    this.createEmployeeShifts();
    this.createShiftPlan();
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>
