<template>
  <v-card>
    <v-card-title> Einstellungen für Hoteltablets </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="chosenHotel.id">
          <v-alert type="info"
            >Gerät ist für das Hotel
            <strong>{{ chosenHotel.name + ` (${chosenHotel.level})` }}</strong>
            registriert!</v-alert
          >
        </v-col>
        <v-col cols="8">
          <v-autocomplete
            label="Hotel für das Tablet"
            :items="hotels"
            item-title="name"
            item-value="id"
            v-model="hotelId"
            @update:model-value="chosenHotel.level = 'senior'"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-btn-toggle v-model="chosenHotel.level">
            <v-btn value="senior">Senior</v-btn>
            <v-btn value="clerk">Clerk</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="8">
          <v-btn :loading="loading" class="mr-2" @click="saveHotelCookie()"
            >Speichern</v-btn
          >
          <v-btn :loading="loading" @click="cleanHotelCookie()"
            >Zurücksetzen</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      hotels: [],
      chosenHotel: {},
      hotelId: null,
      storageCheck: null,
    };
  },
  methods: {
    saveHotelCookie() {
      this.loading = true;
      var level = this.chosenHotel.level;
      this.chosenHotel = this.hotels.find((item) => item.id == this.hotelId);
      this.chosenHotel.level = level;
      var date = new Date();
      date.setFullYear(date.getFullYear() + 100); // Setzt das Ablaufdatum auf 100 Jahre in der Zukunft
      var expires = "; expires=" + date.toUTCString();
      document.cookie = `hotel_bellcaptain=${JSON.stringify(
        this.chosenHotel,
      )}${expires}; path=/; domain=.bellcaptain.de`;
      this.loading = false;
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0)
          return JSON.parse(c.substring(nameEQ.length, c.length));
      }
      return null;
    },
    cleanHotelCookie() {
      document.cookie =
        "hotel_bellcaptain=; Max-Age=-99999999; path=/; domain=.bellcaptain.de";
      this.chosenHotel = {};
      this.hotelId = null;
    },
  },
  async created() {
    this.chosenHotel = this.getCookie("hotel_bellcaptain")
      ? this.getCookie("hotel_bellcaptain")
      : {};
    this.hotels = (
      await this.$store.dispatch("getSupabaseEntity", {
        table: "hotels",
        select: "id,name,short_name",
      })
    ).data;
    this.hotelId = this.chosenHotel ? this.chosenHotel.id : null;
  },
};
</script>
