<template>
  <v-row class="ma-4 fixed-top justify-center" justify="center">
    <v-col cols="6">
      <v-progress-linear
        class="my-4"
        v-if="loading"
        indeterminate
      ></v-progress-linear>
      <v-btn @click="closeDialog" class="mt-1">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück
      </v-btn>
      <div class="my-4 text-h6">
        {{
          "Mitarbeiter-Check, " +
          new Date(employeeCheck.date).toLocaleDateString()
        }}
      </div>

      <v-select
        v-if="!loading"
        :loading="loading"
        v-model="employeeCheck.inspector"
        variant="outlined"
        density="compact"
        label="Prüfer"
        :items="employeeList"
        item-title="name"
        item-value="id"
        disabled
      ></v-select>
      <v-autocomplete
        v-if="!loading"
        :loading="loading"
        v-model="employeeCheck.employee"
        variant="outlined"
        density="compact"
        label="Mitarbeiter"
        :items="employeeList"
        item-title="lastNameFirst"
        item-value="id"
        disabled
      ></v-autocomplete>
      <v-autocomplete
        v-model="employeeCheck.hotel"
        variant="outlined"
        density="compact"
        label="Hotel"
        :items="hotels"
        item-title="name"
        item-value="id"
        disabled
      ></v-autocomplete>
    </v-col>
    <v-col cols="6" align="center" class="pa-10 mt-9">
      <v-progress-circular
        class="my-2"
        size="100"
        width="12"
        color="indigo-darken-2"
        :model-value="
          (passResult.itemsFilled / employeeCheck.content.length) * 100
        "
      >
        {{ passResult.itemsFilled + " / " + employeeCheck.content.length }}
      </v-progress-circular>
      <v-progress-linear
        v-if="passResult.hasPassed !== true"
        height="30"
        :color="passResult.percentagePass >= 100 ? 'success' : 'warning'"
        v-model="passResult.percentagePass"
        >{{ passResult.points }} Punkte erreicht</v-progress-linear
      >
      <v-alert class="my-2" v-if="passResult.hasPassed" type="success"
        >Mitarbeitercheck mit {{ passResult.points }} Punkten
        bestanden!</v-alert
      >
      <v-alert class="my-2" v-if="passResult.hasPassed === false" type="error">
        Mitarbeitercheck nicht bestanden!
        <div v-if="passResult.failedQuestion">Elementarer Fehler</div>
        <div
          v-if="
            passResult.itemsFilled === employeeCheck.content.length &&
            passResult.points < employeeCheck.min_correct_questions
          "
        >
          Punkteziel von
          <strong>{{ employeeCheck.min_correct_questions }}</strong> nicht
          erreicht
        </div>
      </v-alert>
    </v-col>
  </v-row>
  <v-row align="center" class="mx-4 bottom-row" justify="center">
    <v-col cols="12" class="mx-10">
      <v-card variant="outlined">
        <v-card-title class="bg-indigo">Nachkontrolle</v-card-title>
        <v-card-text class="ma-4">
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Kommentar"
                v-model="employeeCheck.control_comment"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                label="Vom Büromitarbeiter geprüft"
                color="success"
                v-model="employeeCheck.is_checked"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" align="left">
              <v-btn-toggle
                v-model="employeeCheck.is_passed"
                :disabled="!employeeCheck.control_comment"
              >
                <v-btn :value="true" color="success">Bestanden</v-btn>
                <v-btn :value="false" color="error">Nicht bestanden</v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loading"
                color="primary"
                @click="updateForm(employeeCheck)"
                >Speichern</v-btn
              >
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="error" @click="deleteCheck">Löschen</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" xxl="8" xl="8" lg="8" class="pa-4">
      <v-row v-for="(item, index) in employeeCheck.items" :key="index">
        <v-col cols="12">
          <v-card elevation="3">
            <v-card-title>{{ item.category }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="8">Titel</v-col>
                <v-col cols="2" align="center">Erfüllt</v-col>
                <v-col cols="2" align="center">Nicht erfüllt</v-col>
              </v-row>
              <v-row
                v-for="(subItem, subIndex) in item.items"
                :key="subIndex"
                align="center"
              >
                <v-divider></v-divider>
                <v-col cols="8">{{ subItem.title }}</v-col>
                <v-divider vertical></v-divider>
                <v-col cols="2" align="center">
                  <v-icon
                    size="35"
                    :icon="
                      subItem.check === true
                        ? 'mdi-checkbox-outline'
                        : 'mdi-checkbox-blank-outline'
                    "
                  ></v-icon>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="2" align="center">
                  <v-icon
                    size="35"
                    :class="subItem.is_failed ? 'ml-5' : ''"
                    @click="tickCheckbox(subItem, false)"
                    :icon="
                      subItem.check === false
                        ? 'mdi-checkbox-outline'
                        : 'mdi-checkbox-blank-outline'
                    "
                  ></v-icon>
                  <v-icon v-if="subItem.is_failed" color="red"
                    >mdi-alert-circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="mx-8">
      <v-alert type="warning" color="grey-lighten-3" class="text-caption">
        <div>Hinweise:</div>
        <ul>
          <li>
            Der Mitarbeiter-Check gilt mit 90%, mindestens 9 von 10 möglichen
            Punkten, als bestanden.
          </li>
          <li>
            Elementare Fehler führen unabhängig von der Punktzahl zum
            Nichtbestehen!
          </li>
          <li>
            Anmerkungen und Einwände müssen direkt und ausschließlich
            schriftlich unter Bemerkung notiert werden!
          </li>
          <li>
            Festgestellte Defizite während eines Mitarbeiter-Checks führen zu
            Maluspunkten im Bonussystem!
          </li>
          <li>
            Das mehrmalige Nichtbestehen eines Mitarbeiter-Checks kann zudem zu
            arbeitsrechtlichen Konsequenzen führen!
          </li>
        </ul>
      </v-alert>
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="employeeCheck.comments"
        label="Bemerkungen"
        variant="outlined"
        rows="3"
        disabled
      ></v-textarea>
    </v-col>
    <v-col cols="6" align="left">
      <v-card variant="outlined">
        <v-card-title>Unterschrift Mitarbeiter</v-card-title>
        <v-img
          v-if="employeeCheck.is_signed"
          :src="employeeCheck.employee_signature"
          class="ma-5"
          height="100"
        ></v-img>
        <ClientOnly v-else>
          <Vue3Signature
            ref="employee_signature"
            :sigOption="state1.option"
            :w="'1280px'"
            :h="'200px'"
            :disabled="state1.disabled"
          ></Vue3Signature>
        </ClientOnly>
      </v-card>
    </v-col>
    <v-col cols="6" align="left">
      <v-card variant="outlined">
        <v-card-title>Unterschrift Prüfer</v-card-title>
        <v-img
          v-if="employeeCheck.is_signed"
          :src="employeeCheck.inspector_signature"
          class="ma-5"
          height="100"
        ></v-img>
        <ClientOnly v-else>
          <Vue3Signature
            ref="inspector_signature"
            :sigOption="state2.option"
            :w="'1280px'"
            :h="'200px'"
            :disabled="state2.disabled"
          ></Vue3Signature>
        </ClientOnly>
      </v-card>
    </v-col>
  </v-row>
  <v-row> </v-row>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useStore } from "vuex"; // Import useStore
import { defineComponent } from "vue";

// Define props
const props = defineProps<{
  employeeCheckProp: object; // Add more props as needed
}>();

const emit = defineEmits(["closeDialog"]);

interface EmployeeCheckItem {
  title: string | null;
  category: string | null;
  is_failed: boolean;
  check?: boolean | null;
}

interface EmployeeCheck {
  id?: number;
  min_correct_questions: number;
  content: EmployeeCheckItem[];
  is_template?: boolean;
}

interface CheckItem {
  index: number;
  category: string;
  items: EmployeeCheckItem[];
}

const employeeCheck = ref<EmployeeCheck>({
  min_correct_questions: 0,
  content: [],
});
const check = ref({});
const store = useStore(); // Use the store
let loading = ref(false);
const employeeList = ref([]);
let userEmployeeId = ref(null);
let passResult = ref({
  percentagePass: 0,
  percentageAll: 0,
  failedQuestion: false,
  points: 0,
  itemsFilled: 0,
  hasPassed: null,
});

const state1 = {};
const state2 = {};

const employee_signature = ref(null);
const inspector_signature = ref(null);

const closeDialog = () => {
  emit("closeDialog"); // This will notify the parent component to close the dialog
};

const calculatePercentage = () => {
  passResult.value.points = 0;
  passResult.value.itemsFilled = 0;
  passResult.value.failedQuestion = null;
  passResult.value.hasPassed = null;
  for (var i = 0; i < employeeCheck.value.content.length; i++) {
    var item = employeeCheck.value.content[i];
    if (item.check !== null) {
      passResult.value.itemsFilled++;
      if (item.check === true && !item.is_failed) {
        passResult.value.points++;
      }
      if (item.check === false && item.is_failed) {
        passResult.value.failedQuestion = true;
      }
    }
  }

  if (passResult.value.itemsFilled === employeeCheck.value.content.length) {
    if (passResult.value.points >= employeeCheck.value.min_correct_questions) {
      passResult.value.hasPassed = true;
    } else {
      passResult.value.hasPassed = false;
    }
  }
  if (passResult.value.failedQuestion) {
    passResult.value.hasPassed = false;
  }

  passResult.value.percentageAll =
    (passResult.value.points / employeeCheck.value.content.length) * 100;
  passResult.value.percentagePass =
    (passResult.value.points / employeeCheck.value.min_correct_questions) * 100;
};

const updateForm = async (form) => {
  loading.value = true;
  await store.dispatch("updateSupabaseEntity", {
    table: "employee_check_form",
    id: form.id,
    payload: {
      is_checked: form.is_checked,
      control_comment: form.control_comment,
      is_passed: form.is_passed,
      checked_by_id: store.state.userInfo.id,
    },
  });
  loading.value = false;
};

const deleteCheck = async () => {
  loading.value = true;
  await store.dispatch("deleteSupabaseEntity", {
    table: "employee_check_form",
    id: employeeCheck.value.id,
  });
  loading.value = false;
  closeDialog();
};

const createCheck = () => {
  const groupCheck: { [key: string]: boolean } = {};
  check.value = [];
  let index = -1;
  let items = [];
  employeeCheck.value.content?.forEach((item) => {
    if (item.category && !groupCheck[item.category]) {
      index++;
      groupCheck[item.category] = true;
      items.push({
        index: index,
        category: item.category,
        items: [],
      });
    }
    item.check = item.check !== null ? item.check : null;
    if (item.category) {
      items[index].items.push(item);
    }
  });

  employeeCheck.value.items = items;
  calculatePercentage();
};

onMounted(async () => {
  let response = {};
  employeeCheck.value = props.employeeCheckProp;
  createCheck();
});
</script>

<style scoped></style>
