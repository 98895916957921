<template>
  <View :hotelId="$route.params.id"></View>
</template>

<script setup>
import View from "@/components/Hotels/Edit.vue";
</script>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  async created() {},
};
</script>
