<template>
  <v-card class="ma-12">
    <v-card-title> </v-card-title>
    <v-card-text>
      <v-btn @click="startTest">Speedtest starten</v-btn>

      <v-row class="mt-10">
        <v-col v-for="test in tests" lg="4" sm="12">
          <v-card elevation="3">
            <v-card-title>{{ test.name }}</v-card-title>
            <v-card-text>
              <v-progress-circular
                indeterminate
                v-if="test.loading"
              ></v-progress-circular>
              <template v-else>
                <div class="text-h5 ma-2">
                  {{ test.duration + " Sekunden" }}
                </div>
                <v-alert type="info">{{ test.response }}</v-alert>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup></script>

<script>
export default {
  data() {
    return {
      loading: false,
      tab: "SpeedTest",
      loading: false,
      tests: {},
      result: {},
      ipAddress: null,
    };
  },
  methods: {
    async resetLoadingValues() {
      this.tests = [
        {
          name: "Via Frontend",
          key: "frontend",
          duration: null,
          loading: true,
          response: null,
        },
        {
          name: "Via Backend",
          key: "backend",
          duration: null,
          loading: true,
          response: null,
        },
        {
          name: "Via SQL",
          key: "sql",
          duration: null,
          loading: true,
          response: null,
        },
      ];
    },
    async startTest() {
      this.result.id = await this.$store.dispatch("createSupabaseEntity", {
        table: "speed_test",
        payload: {
          user_id: this.$store.state.userInfo.id,
          ip_address: this.ipAddress,
          triggered_manually: true,
        },
      });
      this.resetLoadingValues();
      for (var i = 0; i < this.tests.length; i++) {
        var test = this.tests[i];
        if (test.key === "frontend") {
          this.getSpeed(test);
        } else if (test.key === "backend") {
          this.getSpeed(test);
        } else if (test.key === "sql") {
          this.getSpeed(test);
        }
      }
    },
    async getSpeed(test) {
      var start = new Date().getTime();
      if (test.key === "frontend") {
        var response = await this.$store.dispatch("getSupabaseEntity", {
          table: "all_german_cities",
        });
        test.response = response.data.length + " Zeilen geladen!";
        var end = new Date().getTime();
        test.duration = (end - start) / 1000;
        var queryObject = {
          id: this.result.id,
          payload: {
            frontend_duration: test.duration * 1000,
          },
          table: "speed_test",
        };
      } else if (test.key === "backend") {
        var response = await this.$store.dispatch("speedTest");
        test.response = response.data.response.data.length + " Zeilen geladen!";
        var end = new Date().getTime();
        test.duration = (end - start) / 1000;
        var queryObject = {
          id: this.result.id,
          payload: {
            backend_duration: test.duration * 1000,
          },
          table: "speed_test",
        };
      } else if (test.key === "sql") {
        var response = await this.$store.dispatch("speedTest", { sql: true });
        test.response = response.data.response.length + " Zeilen geladen!";
        var end = new Date().getTime();
        test.duration = (end - start) / 1000;
        var queryObject = {
          id: this.result.id,
          payload: {
            sql_duration: test.duration * 1000,
          },
          table: "speed_test",
        };
      }
      this.$store.dispatch("updateSupabaseEntity", queryObject);
      test.loading = false;
    },
  },
  async mounted() {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    this.ipAddress = data.ip;
    this.resetLoadingValues();
    this.startTest();
  },
};
</script>
