<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="close">Abbrechen</v-btn>
        <v-btn color="red darken-1" text @click="confirm">Bestätigen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    close() {
      console.log("close");
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm", this.item);
    },
  },
};
</script>
