<template>
  <h3 class="ma-2">Hotel Software</h3>
  <v-card>
    <v-card-text class="mt-3">
      <v-row v-for="(software, index) in hotelSoftwares" class="my-n10">
        <v-col cols="12">
          <v-text-field
            variant="outlined"
            density="compact"
            append-icon="mdi-delete"
            @click:append="deleteSoftware(software, index)"
            v-model="software.name"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-col align="center">
      <v-btn @click="hotelSoftwares.push({ name: null })">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn @click="saveSoftwares()" class="ml-2" :loading="loading">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-col>
  </v-card>
</template>

<script setup>
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      hotelSoftwares: [],
    };
  },
  methods: {
    async getHotelSoftwares() {
      var queryObject = {
        table: "hotel_software",
        select: "*",
        order: {
          key: "id",
          ascending: true,
        },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.hotelSoftwares = response.data;
    },
    async deleteSoftware(software, index) {
      if (software.id) {
        await this.$store.dispatch("deleteSupabaseEntity", {
          id: software.id,
          table: "hotel_software",
        });
      }
      this.hotelSoftwares.splice(index, 1);
    },
    async saveSoftwares() {
      var table = "hotel_software";
      var payload = this.hotelSoftwares;
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      await this.getHotelSoftwares();
      this.loading = false;
    },
  },
  async mounted() {
    this.getHotelSoftwares();
  },
};
</script>
