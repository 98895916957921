<template>
  <v-card>
    <v-card-title>
      <h2>Jahresplan</h2>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-calendar
            :attributes="calendarEvents"
            :initial-page="{ month: 1, year: new Date().getFullYear() }"
            columns="6"
            rows="2"
          ></v-calendar>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="indigo"
            @click="events.unshift({ date: new Date(), type: 'holiday_block' })"
            >Neuer Eintrag</v-btn
          >
        </v-col>

        <v-col cols="12">
          <v-data-table
            :loading="loading"
            :items="events"
            :headers="headers"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:item.date="{ item }">
              <v-text-field
                type="date"
                class="mt-2"
                v-model="item.date"
                density="compact"
                variant="outlined"
              ></v-text-field>
            </template>
            <template v-slot:item.type="{ item }">
              <v-select
                :items="eventTypes"
                class="mt-2"
                v-model="item.type"
                density="compact"
                variant="outlined"
                item-title="name"
                item-value="type"
              ></v-select>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon @click="saveEvent(item)">mdi-content-save</v-icon>
              <v-icon @click="deleteEvent(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { setupCalendar } from "v-calendar";
</script>

<script>
export default {
  data() {
    return {
      eventTypes: [
        { type: "holiday_block", name: "Urlaubssperre", color: "red" },
        { type: "company_event", name: "Firmenevent", color: "teal-darken-2" },
        {
          type: "company_holiday",
          name: "Firmenfeiertag",
          color: "green-darken-2",
        },
      ],
      loading: false,
      events: [],
      calendarEvents: [],
      headers: [
        { title: "Datum", value: "date" },
        { title: "Wert", value: "type" },
        { title: "Aktionen", value: "actions" },
      ],
    };
  },
  methods: {
    async getDates() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "company_dates",
        order: { key: "date", ascending: false },
      });
      this.events = response.data;
      this.calendarEvents = [];
      this.events.forEach((event) => {
        var object = {
          key: event.type,
          dates: new Date(event.date),
          highlight: this.eventTypes.find((type) => type.type == event.type)
            .color,
          popover: {
            label: event.type,
          },
        };
        this.calendarEvents.push(object);
      });
    },
    async saveEvent(event) {
      this.loading = true;
      if (event.id) {
        var response = await this.$store.dispatch("updateSupabaseEntity", {
          table: "company_dates",
          id: event.id,
          payload: event,
        });
      } else {
        var response = await this.$store.dispatch("createSupabaseEntity", {
          table: "company_dates",
          payload: event,
        });
      }
      this.loading = false;
      this.getDates();
    },
    async deleteEvent(event) {
      this.loading = true;
      var response = await this.$store.dispatch("deleteSupabaseEntity", {
        table: "company_dates",
        id: event.id,
      });
      this.loading = false;
      this.getDates();
    },
  },
  async mounted() {
    var today = new Date();
    this.getDates();
  },
};
</script>
