<template>
  <v-tabs v-model="$store.state.recruitingView.tab">
    <v-tab value="jobs" @click="saveTabInStorage('jobs')">Jobs</v-tab>
    <v-tab value="applications" @click="saveTabInStorage('applications')"
      >Bewerbungen</v-tab
    >
    <v-tab value="calendar" @click="saveTabInStorage('calendar')"
      >Kalender</v-tab
    >
  </v-tabs>

  <v-window v-model="$store.state.recruitingView.tab">
    <v-window-item value="jobs">
      <Jobs></Jobs>
    </v-window-item>
    <v-window-item value="applications">
      <Applications></Applications>
    </v-window-item>
    <v-window-item value="calendar">
      <Calendar></Calendar>
    </v-window-item>
  </v-window>
</template>

<script setup>
import Jobs from "@/components/Recruiting/Jobs.vue";
import Applications from "@/components/Recruiting/Applications";
import Calendar from "@/components/Recruiting/Calendar";
</script>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    saveTabInStorage(tab) {
      localStorage.setItem("recruitingTab", tab);
    },
  },
  async created() {},
};
</script>
