<template>
  <v-row class="ma-4">
    <v-col cols="3">
      <v-text-field
        v-model="$store.state.hotelView.search"
        label="Suche"
      ></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-autocomplete
        clearable
        label="Mitarbeiter"
        :items="employees"
        item-title="name"
        item-value="id"
        multiple
        chips
        v-model="$store.state.hotelView.employees"
      ></v-autocomplete>
    </v-col>
    <v-col cols="3">
      <v-select
        clearable
        label="Orte"
        :items="$store.state.cities"
        item-title="city"
        item-value="id"
        multiple
        chips
        v-model="$store.state.hotelView.locations"
      ></v-select>
    </v-col>
    <v-col cols="2">
      <v-switch
        @input="getHotels"
        color="green"
        v-model="$store.state.hotelView.email"
        label="Email"
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script setup>
import { createHydrationRenderer } from "vue";
</script>
<script>
export default {
  data() {
    return {
      employees: [],
    };
  },
  methods: {},
  async created() {
    var response = await this.$store.dispatch("getSupabaseEntity", {
      select: "id,name",
      table: "employees",
      where: [{ type: "eq", key: "status", value: "Aktiv" }],
    });
    this.employees = response.data;
  },
};
</script>
