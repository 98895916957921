<template>
  <v-row class="ma-12">
    <v-progress-linear
      height="30"
      v-model="progress"
      color="blue"
      v-if="loading"
    ></v-progress-linear>
    <v-btn @click="setDate(-1)">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <span class="mx-10 mt-2">{{ newObject.month + " " + newObject.year }}</span>
    <v-btn
      @click="setDate(1)"
      :disabled="
        newObject.month == currentDates.month &&
        newObject.year == currentDates.year
      "
    >
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
    <v-col cols="12"> </v-col>
    <v-col cols="3">
      <v-text-field label="Monat" v-model="newObject.month"></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field label="Jahr" v-model="newObject.year"></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-btn
        size="x-large"
        color="blue"
        @click="endMonth()"
        :loading="saveLoading"
        v-if="
          newObject.month == currentDates.month &&
          newObject.year == currentDates.year
        "
        >Abrechnung freigeben</v-btn
      >
    </v-col>
    <v-data-table
      :loading="loading"
      :items="changeData"
      :headers="headers"
      items-per-page="10"
    >
      <template v-slot:item.name="{ item }">
        <router-link :to="item.link">{{ item.name }}</router-link>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dates: {},
      startData: {},
      currentDates: {},
      currentData: [],
      changeData: [],
      progress: 0,
      keys: [],
      headers: [
        { title: "Name", value: "name" },
        { title: "Attribut", value: "key" },
        { title: "Alter Wert", value: "oldValue" },
        { title: "Neuer Wert", value: "newValue" },
      ],
      newObject: {
        json: [],
        month: null,
        year: null,
      },
      saveLoading: false,
      selections: {
        employees: [],
      },
    };
  },
  methods: {
    async getSelections() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_data_keys",
      });
      response.data.forEach((item) => {
        this.keys[item.id] = item;
      });
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "departments",
      });
      this.selections.departments = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employment_types",
      });
      this.selections.employment_types = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "organisations",
      });
      this.selections.organisations = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_teams",
      });
      this.selections.employee_teams = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "cities",
      });
      this.selections.cities = response.data;
    },
    async setDate(month) {
      this.loading = true;
      var date = new Date(this.dates.year, this.dates.month);
      date.setMonth(date.getMonth() + month);
      var currentDate = date;
      var timeStampDate = new Date(date);
      timeStampDate.setMonth(timeStampDate.getMonth() - 1);
      currentDate = {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
      };
      timeStampDate = {
        year: timeStampDate.getFullYear(),
        month: timeStampDate.getMonth() + 1,
      };
      this.setNewObject(timeStampDate);
      if (
        currentDate.month == this.currentDates.month &&
        currentDate.year == this.currentDates.year
      ) {
        this.currentData = await this.getCurrentData();
      } else {
        this.currentData = await this.getTimeStampData(currentDate);
      }
      this.timeStampData = await this.getTimeStampData(timeStampDate);
      await this.compareData();
    },
    async getLatestStampData() {
      var queryObject = {
        table: "employee_month_data",
        limit: 1,
        order: { key: "created_at", ascending: false },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      var data = response.data;
      data[0].json.forEach((item) => {
        this.startData[item.id] = item;
      });
      return data[0];
    },
    async getTimeStampData(dates) {
      var queryObject = {
        table: "employee_month_data",
        where: [],
      };
      queryObject.where.push({
        type: "eq",
        key: "year",
        value: dates.year,
      });
      queryObject.where.push({
        type: "eq",
        key: "month",
        value: dates.month,
      });
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      var data = response.data;
      data[0].json.forEach((item) => {
        this.startData[item.id] = item;
      });
      this.loading = false;
      return data[0].json;
    },
    async setNewObject(dates) {
      var currentMonth = new Date(dates.year, dates.month);
      this.newObject.year = currentMonth.getFullYear();
      this.newObject.month = currentMonth.getMonth() + 1;
      this.dates = {
        month: this.newObject.month - 1,
        year: currentMonth.getFullYear(),
      };
      return this.newObject;
    },
    async getCurrentData() {
      var currenDataQueryObject = {
        select: "*,employee_data_matching(*)",
        table: "employees",
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        currenDataQueryObject,
      );
      this.currentData = response.data;
      this.newObject.json = this.currentData;
      return this.currentData;
    },
    async endMonth() {
      this.saveLoading = true;
      var response = await this.$store.dispatch("createSupabaseEntity", {
        table: "employee_month_data",
        payload: this.newObject,
      });

      this.saveLoading = false;
    },
    async compareData() {
      this.changeData = [];
      for (var i = 0; i < this.currentData.length; i++) {
        var employee = this.currentData[i];
        var checkEmployee = this.startData[employee.id];
        if (checkEmployee) {
          for (var j = 0; j < employee.employee_data_matching.length; j++) {
            var dataPoint = employee.employee_data_matching[j];
            var keyInfo = this.keys[dataPoint.key_id];
            for (
              var k = 0;
              k < checkEmployee.employee_data_matching.length;
              k++
            ) {
              var checkDataPoint = checkEmployee.employee_data_matching[k];
              if (dataPoint.key_id == checkDataPoint.key_id) {
                var keyName = keyInfo.key_name_ger;
                if (keyInfo.type == "text" || keyInfo.type == "selection") {
                  if (dataPoint.value_text != checkDataPoint.value_text) {
                    var object = {
                      link: "/Mitarbeiter/" + employee.id,
                      name: employee.name,
                      key: keyName,
                      oldValue: checkDataPoint.value_text,
                      newValue: dataPoint.value_text,
                    };
                    this.changeData.push(object);
                  }
                }
                if (keyInfo.type == "boolean") {
                  if (dataPoint.value_bool != checkDataPoint.value_bool) {
                    var object = {
                      link: "/Mitarbeiter/" + employee.id,
                      name: employee.name,
                      key: keyName,
                      oldValue: checkDataPoint.value_bool,
                      newValue: dataPoint.value_bool,
                    };
                    this.changeData.push(object);
                  }
                }
                if (keyInfo.type == "id") {
                  if (dataPoint.value_id != checkDataPoint.value_id) {
                    var table = this.selections[keyInfo.selection_table];
                    var oldValue = table.find((item) => {
                      return item.id == checkDataPoint.value_id;
                    })
                      ? table.find((item) => {
                          return item.id == checkDataPoint.value_id;
                        }).name
                      : null;
                    var newValue = table.find((item) => {
                      return item.id == dataPoint.value_id;
                    }).name;
                    var object = {
                      link: "/Mitarbeiter/" + employee.id,
                      name: employee.name,
                      key: keyName,
                      oldValue: oldValue,
                      newValue: newValue,
                    };
                    this.changeData.push(object);
                  }
                }
                if (keyInfo.type == "date") {
                  if (dataPoint.value_date != checkDataPoint.value_date) {
                    var object = {
                      link: "/Mitarbeiter/" + employee.id,
                      name: employee.name,
                      key: keyName,
                      oldValue: checkDataPoint.value_date,
                      newValue: dataPoint.value_date,
                    };
                    this.changeData.push(object);
                  }
                }
                if (keyInfo.type == "number") {
                  if (dataPoint.value_number != checkDataPoint.value_number) {
                    var object = {
                      link: "/Mitarbeiter/" + employee.id,
                      name: employee.name,
                      key: keyName,
                      oldValue: checkDataPoint.value_number,
                      newValue: dataPoint.value_number,
                    };
                    this.changeData.push(object);
                  }
                }
              }
            }
          }
        } else {
          var object = {
            name: employee.name,
            link: "/Mitarbeiter/" + employee.id,
            key: "Neuer Mitarbeiter erstellt",
          };
          this.changeData.push(object);
        }
      }
      this.changeData.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  },
  async created() {
    this.loading = true;
    await this.getSelections();
    this.progress = 10;
    var latestTimeStamp = await this.getLatestStampData();
    this.currentDates = JSON.parse(
      JSON.stringify(await this.setNewObject(latestTimeStamp)),
    );
    this.progress = 30;
    await this.getCurrentData();
    this.progress = 60;
    await this.compareData();
    this.progress = 100;
    this.loading = false;
  },
};
</script>
