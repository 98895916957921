<template>
  <v-dialog v-model="$store.state.emailDialog" v-if="entityInfo">
    <v-card>
      <v-toolbar class="pl-4" fixed color="indigo-darken-3"
        >{{ chosenEmail.subject }}
        <v-spacer></v-spacer>
        <v-btn @click="sendEmail(chosenEmail)" :loading="loadingSendMails">
          <v-icon>mdi-email-fast</v-icon>
        </v-btn>
        <v-btn icon @click="$store.state.emailDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card style='max-height="400px";overflow-y:auto'>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="chosenEmail.from"
                label="FROM"
                v-if="entity == 'recruiting_candidates'"
              ></v-text-field>
              <v-select
                v-else
                :items="portalEmails"
                item-title="email"
                item-value="email"
                v-model="chosenEmail.from"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="toItems"
                chips
                multiple
                v-model="chosenEmail.to"
                label="TO"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="chosenEmail.cc"
                label="CC"
                hint="Mehrere Adressen mit Komma trennen"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="chosenEmail.bcc"
                label="BCC"
                hint="Mehrere Adressen mit Komma trennen"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="statusId == 10">
              <v-autocomplete
                label="Absagegrund"
                :items="denyReasons"
                item-title="reason"
                item-value="id"
                v-model="denyReasonId"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" v-if="!chosenEmail.portal">
              <v-btn-toggle v-model="entityInfo.gender">
                <v-btn :value="false" @click="updateCandidate()">
                  <v-icon>mdi-gender-female</v-icon>
                </v-btn>
                <v-btn :value="true" @click="updateCandidate()">
                  <v-icon>mdi-gender-male</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="10" v-if="entityInfo.gender != undefined">
              <v-autocomplete
                :items="templates"
                v-model="templateChosen"
                item-title="name"
                label="Vorlagen"
                return-object
                @update:modelValue="fillMail(templateChosen)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col cols="12">
              <v-file-input
                v-model="files"
                density="compact"
                label="Dateien anhängen"
                @change="addFiles"
                multiple
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              v-if="chosenEmail.files && chosenEmail.files.length > 0"
            >
              <v-row>
                <v-col v-for="(file, fileIndex) in chosenEmail.files" cols="4">
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col align="left">
                          <v-icon>mdi-file-document</v-icon>
                          {{ file.fileName }}
                        </v-col>
                        <v-col align="right">
                          <v-icon
                            align="right"
                            @click="chosenEmail.files.splice(fileIndex, 1)"
                            >mdi-delete</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Subject"
                v-model="chosenEmail.subject"
              ></v-text-field>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="2" align="right" v-if="!chosenEmail.portal">
              <v-checkbox
                label="Späteres Sendedatum"
                v-model="chosenEmail.scheduledSend"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" v-if="chosenEmail.scheduledSend">
              <v-text-field
                label="Sendedatum"
                v-model="chosenEmail.deliveryDate"
                type="datetime-local"
              ></v-text-field>
            </v-col>
          </v-row>
          <QuillEditor
            theme="snow"
            toolbar="essential"
            v-model:content="chosenEmail.body"
            contentType="html"
            placeholder="Body"
          />
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  props: {
    users: Array,
    entityInfo: Object,
    entity: String,
    toAddress: String,
    chosenEmail: Object,
    statusId: Number,
    portalEmails: Array,
  },
  data() {
    return {
      dataObject: {
        emails: [],
      },
      templates: [],
      userImages: {},
      attachments: [],
      gmailId: null,
      files: [],
      userInfo: {},
      mobile: false,
      toItems: [],
      loadingSendMails: false,
      templateChosen: { name: null },
      denyReasons: [],
      denyReasonId: null,
    };
  },
  computed: {},
  methods: {
    async getTemplates() {
      var queryObject = {
        table: "recruiting_templates",
        where: [
          {
            type: "or",
            value: `status_id.eq.${this.statusId},status_id.is.null`,
          },
        ],
        order: {
          key: "name",
          ascending: true,
        },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data?.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      );
      this.templates = response.data;
    },
    async getDenyReasons() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_deny_reasons",
      });
      this.denyReasons = response.data;
    },
    async fillMail() {
      var subject = await this.replaceBody(
        this.templateChosen.subject,
        this.entityInfo,
      );
      this.chosenEmail.subject = subject;
      var body = await this.replaceBody(
        this.templateChosen.body,
        this.entityInfo,
      );
      this.chosenEmail.body = body;
      var response = await this.$store.dispatch("getStorageFolderFiles", {
        bucket: "attachments",
        folder: this.templateChosen.id,
      });
      if (response.data.length > 0) {
        this.chosenEmail.files = [];
        for (var i = 0; i < response.data.length; i++) {
          var blob = await this.$store.dispatch("downloadStorageBlob", {
            bucket: "attachments",
            file: `${this.templateChosen.id}/${response.data[i].name}`,
          });
          var file = {
            mimeType: blob.data.type,
            fileName: response.data[i].name,
            data: blob.data,
          };
          this.chosenEmail.files.push(file);
        }
      } else {
        this.chosenEmail.files = [];
      }
    },
    addFiles(event) {
      var files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        this.chosenEmail.files.push({
          mimeType: file.type,
          fileName: file.name,
          data: file,
        });
      }
      this.files = [];
    },
    updateCandidate() {
      var queryObject = {
        table: "recruiting_candidates",
        id: this.entityInfo.id,
        payload: {
          gender: this.entityInfo.gender,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
      if (this.templateChosen.name) {
        this.fillMail(this.templateChosen);
      }
    },
    async replaceBody(body, info) {
      var body = await this.$store.dispatch("replaceBody", {
        body: body,
        info: info,
      });
      return body;
    },
    saveProcessStatus() {
      var queryObject = {
        table: "emails",
        id: this.chosenEmail.id,
        payload: {
          processed: this.chosenEmail.processed,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
    },
    async sendEmail(email) {
      this.loadingSendMails = true;
      if (!this.chosenEmail.scheduledSend) {
        var emailObject = {
          from: this.chosenEmail.from,
          friendlyName: this.chosenEmail.friendlyName,
          to: this.chosenEmail.to,
          subject: this.chosenEmail.subject,
          body: this.chosenEmail.body,
          cc: this.chosenEmail.cc,
          bcc: this.chosenEmail.bcc,
          files: this.chosenEmail.files,
          threadId: this.chosenEmail.thread_id,
          oldSubject: this.chosenEmail.oldSubject,
          oldBody: this.chosenEmail.oldBody,
        };
        if (this.chosenEmail.portal) {
          emailObject.friendlyName = this.portalEmails.find(
            (email) => email.email == this.chosenEmail.from,
          ).friendly_name;
          await this.$store.dispatch("sendEmailPerBackend", emailObject);
        } else {
          await this.$store.dispatch("sendGmail", emailObject);
        }
      } else {
        var body = this.chosenEmail.thread_id
          ? +`<br>---------------------<br><br>${this.chosenEmail.oldBody}`
          : this.chosenEmail.body;
        var deliveryDate = await this.$store.dispatch(
          "toISOLocal",
          new Date(this.chosenEmail.deliveryDate),
        );
        var gmailId =
          (await this.$store.dispatch("toISOLocal", new Date())) +
          this.chosenEmail.from +
          this.chosenEmail.to +
          this.chosenEmail.subject;
        var emailObject = {
          entity: "recruiting_candidates",
          from: this.chosenEmail.from,
          to: this.chosenEmail.to,
          subject: this.chosenEmail.subject,
          date: deliveryDate,
          body: body,
          entity_id: this.entityInfo.id,
          type: "outgoing",
          cc: this.chosenEmail.cc,
          bcc: this.chosenEmail.bcc,
          thread_id: this.chosenEmail.thread_id,
          gmail_id: gmailId,
        };
        await this.$store.dispatch("createSupabaseEntity", {
          table: "emails",
          payload: emailObject,
        });
        var formatedDate = await this.$store.dispatch(
          "formatDate",
          new Date(deliveryDate),
        );
        var queryObject = {
          table: "notes",
          payload: {
            job_candidate_id: this.entityInfo.recruiting_job_candidates[0].id,
            owner_id: this.$store.state.userInfo.id,
            action: `Mail wurde für ${formatedDate} terminiert!`,
            text: `Betreff: ${this.chosenEmail.subject}`,
          },
        };
        await this.$store.dispatch("createSupabaseEntity", queryObject);
      }

      this.loadingSendMails = false;
      this.$store.state.emailDialog = false;
      if (this.statusId == 10) {
        var queryObject = {
          table: "recruiting_job_candidates",
          id: this.entityInfo.recruiting_job_candidates[0].id,
          payload: {
            status_id: this.statusId,
            deny_reason_id: this.denyReasonId,
            modified_at: await this.$store.dispatch("toISOLocal", new Date()),
          },
        };
        //this.$store.dispatch("updateSupabaseEntity", queryObject);
      }
    },
  },
  async created() {
    if (this.entityInfo) {
      await this.getDenyReasons();

      if (!this.gmailId && this.chosenEmail) {
        this.gmailId = this.chosenEmail.gmail_id;
      }
      var deliveryDate = await this.$store.dispatch("toISOLocal", new Date());

      if (!this.chosenEmail.portal) {
        await this.getTemplates();
      }

      this.chosenEmail.deliveryDate = deliveryDate.slice(0, 16);
      this.chosenEmail.files = [];
      this.chosenEmail.body = "<br>" + this.$store.state.userInfo.signature;

      this.$store.dispatch("googleTokenCheck");
    }
  },
  components: { QuillEditor },
};
</script>
<style>
.full-height-card {
  height: 90vh;
  overflow: auto;
  overflow-y: auto;
}
.fixed-element {
  position: fixed;
}
</style>
